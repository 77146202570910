export enum ETinyFileType {
  TOPIC_CONTENT = 1,
  TOPIC_COMMENT = 2,
  GROUP_INFORMATION = 3
}

export interface ITinyEditorData {
  html: string,
  text: string,
  fileIds: number[]
}
