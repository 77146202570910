export default {
  lang: {
    en: "English",
    ja: "Japanese",
    other: "other",
    choose_your_language: "choose your language"
  },
  search: {
    title: "Filtering",
    target_group: "Target group",
    keyword_search: "keyword search",
    target_search: {
      label: "Search target (multiple selections can be made)",
      topic: "topic",
      calendar: "My calendar",
      chat: "chat",
      shared_folder: "shared folder"
    },
    target_data: {
      label: "Target data (multiple selections can be made)",
      title: "title",
      content: "Contents",
      comment: "comment",
      uploader: "uploader",
      sen_to: "sen_to"
    },
    time_search_range: {
      label: "Search period",
      one_week: "1 week",
      one_month: "1 month",
      three_month: "3 months",
      six_month: "6 months",
      one_year: "1 year",
      select_ranger: 'Specify the period'
    }
  },
  groups: {
    deleted_comment: "Comment has been deleted",
    common_topic: "Common topic",
    group: "group",
    qr_scan: "Join the group Read the QR code",
    un_read_count: "Unread {count}",
    bin: "Trash",
    attach_file: "Attach file",
    draft: "Before delivery",
    edit_mode: "Edit mode",
    mode_text: "text",
    mode_html: "Format editing",
    comment: "comment",
    unclassified: "Uncategorized",
    delete: "delete",
    all: "all",
    preview_remind: "If you have an account, please log in and then load the QR.",
    nav_button: {
      be_member: "Become a member",
      comment: "To comment",
      reply: "Send back",
      reply_to: "send back",
      move_to_bin: "Put in the trash",
      move_to_trash: "Move to the trash",
      free_up_bin: "Empty the trash",
      like: "Like!",
      file_overwrite: "File overwrite",
      update_information: "Update information",
      download: "download",
      set_post: "Delivery settings",
      create_topic: "Topic created",
      summary_display: "Summary display",
      move_folder: "Move to folder",
      add_folder: "Add folder",
      add_file: "Add file",
      update_folder_name: "Folder name change",
      change_display_order: "Change display order",
      return_unclassified: "Return to unclassified"
    },
    register: {
      welcome: "welcome! To SMILE LINE!",
      remind: "Please enter the following items and press the send button.  n A temporary registration email will be sent to your email address.",
      mail_no_reply: 'If you are taking measures against junk mail, change the settings so that you can receive "no-reply@smile-line.net" mail.',
      remind_2: "Please use at least 8 single-byte alphanumeric characters and at least 2 types of uppercase / lowercase letters / numbers / symbols.",
      remind_qr: "Registration with QR code is only once.",
      remind_qr_2: "If you do not receive it, please check your junk e-mail settings and ask the group administrator to invite you to your e-mail address."
    },
    search_result: {
      title: "「<span class='text-danger'>{keyWords}</span>」Search results in",
      result_count: "{count}　hit.",
      re_search: "re-search"
    },
    top_page: {
      new_feed: "topic",
      to_member: "To members",
      sort_by_new: "newest order",
      sort_by_cls: "category",
      un_read: "Unread",
      update_info: "update information",
      shared_folder: "shared folder"
    },
    new_feed: {
      title: "topic",
      create_topic_title: "Topic - create topic",
      edit_topic_title: "Topic - update topic",
      move_to_folder_msg: "Select the destination folder for the selected file",
      topic_title: "topic  - {title}",
      modal_title: "Uncheck any files you do not want to attach",
      list_liker: "Viewer list",
      emergency_contact: "Emergency contact",
      is_multiple_edit: "Collaborative editing",
      notify_by_email: "Notify by email when writing body / comment",
      like: "Like",
      hide_liked_member: "Hide the member names you like",
      able_like: "To be able to like",
      change_like_char: "Like character change",
      allow_comment: "Make comments available.",
      allow_anonymous_comment: "Allow anonymous comments",
      post_date_time: "Delivery date and time",
      instant_post: "Prompt delivery",
      select_site: "Destination selection",
      select_group: "Group selection",
      multi_group_tag: "Multiple group tags",
      hide_to_selected_site: "Hide destination group",
      like_count: "{count}case",
      subscriber: "register: {dateTime}",
      updater: "update: {dateTime}",
      sort_new_to_old: "Sort in newest order",
      sort_old_to_new: "Sort by oldest",
      anonymous: "Anonymous (provisional)",
      summary_display_title: "topic --Summary display",
      no_class: "Uncategorized",
      bin_remind: "It will be deleted after the set period.",
      post_site: "delivered",
      group: "group",
      common_topic: "Common topic",
      hide_commented_member: "Hide commented member names",
      no_attach_file: "There is no attach file",
      some_attach_file_delete: "There are {count} deleted files",
    },
    shared_folder: {
      title: "shared folder",
      throw_away: "throw away",
      file_create: "Add file",
      file_detail: "File details",
      file_detail_overwrite: "File overwrite",
      file_detail_overwrite_msg: "The maximum file size is 25MB. Updated files can be restored up to 3 generations",
      overwrite_error_msg: "Files up to {max} MB can be uploaded",
      file_detail_update_information: "update file information",
      file_no_title: "Unregistered filename",
      file_name: "File name：{fileName}",
      file_size: "file size：{fileSize}",
      save_folder: "Storage folder：{folder}",
      store_folder: "Storage folder",
      attach_source: "Attachment source：",
      memo: "Note",
      history_log_remind: "It is recorded in the update history.",
      notify_this_update: "Notify this update",
      update_history: "Update History",
      subscriber: "Registered person: {dateTime} {name}",
      updater: "Updated By: {dateTime} {name}",
      tb_ver: "Ver.",
      tb_date_time: "Date and time",
      tb_updater: "Updated By",
      tb_file_name: "file name",
      tb_restore: "Restore",
      tb_delete: "delete",
      attach_file: "Attachment",
      update: "update",
      title_unregister_file_name: "Title / File name when not registered",
      file_choose_remind: "The maximum file size is 25MB. Updated files can be restored up to 3 generations."
    },
    member_list: {
      title: "Member list",
      name_suffix: "",
      role_admin: "Administrator",
      role_member: "member",
      role_member_plus: "Member +",
      admin: "(Administrator)",
      guest_count: "{count}users",
      memberCount: "Number of members : {count} users",
      member: {
        title: "member",
        joined_member: "participant",
        leave_group: "Leave the group"
      },
      qr_code: {
        title: "QR / Invitation",
        message: "Existing users should log in before scanning.",
        invite_by_link: "Invite from connection",
        invite_by_mail: "Invite by email",
        title_full: "QR code / invitation",
        link_label: "Invitation link",
        qr_remind: "Existing users should log in before scanning.",
        qr_remind_2: "If you reissue it, the previous QR code will be invalid.",
        qr_remind_3: "To invite someone who does not have an email address",
        copy_url: "Copy the URL",
        re_render_qr: "Re-issued QR URL",
        register_new_member: "Register instead",
        grant_invite_auth: "Grant invitation permissions",
        grant_together: "Granted together",
        invite_auth: "invite privileges",
        invite_auth_transfer: "Granting and delegating authority",
        invite_by_link_remind: "Enter/Select Members to Invite to Group.",
        invite_by_mail_remind: "Enter/Select people you know to invite to the group.\n If you are entering multiple email addresses, you must enter a new line of email addresses, one per line.",
        invite_by_mail_no_reply: 'If you are taking measures against junk mail, change the settings so that you can receive "no-reply@smile-line.net" mail.',
        invite_confirm_remind: "Invite selected acquaintances. Expiration date is 7 days.",
        group_join: "Participating group",
        invite_member: "Members to invite",
        member_of_select_group: "Members of the selection group",
        member_of_select_group_remind: "Select the members you want to invite to the group. \n Multiple selections are possible",
        mail_subject_place_holder: "Invitation to SMILE LINE group {group_name}",
        mail_content_place_holder: "An invitation to the SMILE LINE group {group_name} has arrived. \n Expiration date is 7 days.",
        search_member: "Search by member name",
        remind_qr: "Registration with QR code is only once.",
        remind_qr_2: "If you do not receive it, please check your junk e-mail settings and have the group administrator invite you to your e-mail address."
      },
      invited: {
        title: "Inviting",
        expired: "Expired",
        date_invite: "{date} invitation",
        cancel: "cancel",
        delete: "delete",
        re_invite: "Reinvite",
        no_invited_member: "There is no invitation."
      },
      join_request: {
        title: "Approval pending",
        date_request: "{date} application",
        approval: "Approval",
        reject: "rejection",
        approval_all: "Bulk approval",
        no_request_join_member: "There is no new application for participation."
      },
      role: {
        title: "permission",
        title_full: "Granting permission",
        set_admin_role: "Set as administrator",
        grant_auth: "Granting permission",
        grant_auth_selected_mem: "Granting permission"
      },
      auth: {
        admin: "Group administrator",
        invite: "invite privileges",
        edit_shared_folder: "Edit shared folder",
        edit_topic: "Edit/Delete topic"
      }
    },
    setting: {
      title: "Setting",
      basic_info: {
        title: "Basic information",
        modified_date: "Created date",
        group_tags: "Group tag",
        group_storage: "Group storage capacity",
        move_all_to_bin: "Empty all trash",
        notice: "Notice to members",
        quotes: "Participating members will not be notified of changes in group information.",
        group_name: "group name",
        group_icon: "Group icon",
        up_file: "Upload the file",
        select_default: "Select from standard icons",
        delete_icon: "Delete the group icon",
        group_cover: "Group header image",
        text_color: "Text color",
        auto_clear_bin: "Automatically delete Trash",
        invite_auth: "invite privileges",
        all_can_invite: "All members can be invited",
        admin_can_invite: "Only group administrators can invite",
        destination: "Address"
      },
      equipment: {
        title: "Equipment setting",
        equip_register: "Equipment registration",
        edit: "edit",
        delete: "delete",
        remind: "Participating members will not be notified of changes in group information"
      },
      export: {
        title: "export",
        new_feed: "topic",
        start_Date: "Start date and time",
        end_date: "End date and time",
        member: "member",
        shared_folder: "shared folder"
      },
      mail: {
        title: "E-mail notification",
        display_ques: "Content display in new mail",
        display: "display",
        non_display: "Hide"
      }
    }
  },
  chat: {
    comma: ', ',
    chat_group_name_with: " and {other_user_count} other",
    chat_with: " chat",
    scroll_to_bottom: 'scroll bottom',
    list: {
      title: "chat",
      chat_title: "chat title",
      chat_title_2: "chat title",
      placeholder_input_chat: "Type a message",
      icon_like: "Like！",
      icon_favorite: "favorite",
      reply: "reply",
      gallery: "Gallery",
      dropdown_btn: {
        edit: "Edit",
        reply: "Reply",
        copy: "Copy link",
        delete: "Delete"
      },
      msg: {
        no_group_chat: "No group chat",
        no_message: "No message",
        msg_deleted: "(Message deleted)",
        file_sent: "File has been sent!",
        msg_special: "send a new message",
        index: "No.",
        unread_msg: "Unread messages",
        message_link: "Fixed link",
        leave_group_chat: "{name} has left the group chat.",
        leave_system: "{name} has withdrawn from the system.",
        users_not_in_general_group: "Group chat is not possible because someone are in different groups",
      },
      detail: {
        chat_with: "Chat with people",
        other_people: "other"
      }
    },
    text_editor: {
      title: "Text editor"
    },
    manage_member: {
      placeholder_input_search: "Search by member name",
      no_user_found: "There are no users.",
    },
    gallery: {
      title: "Gallery",
      dropdown_btn: {
        download: "Download",
        delete: "Delete"
      }
    }
  },
  calendar: {
    display: {
      title: "My calendar",
      btn_add_appointment: "Register an appointment",
      no_event_to_display: "No events to display",
      allday_content: "all day",
      tab: {
        list_display: "list",
        day_display: "Day",
        week_display: "week",
        month_display: "Month"
      },
      dropdown: {
        all: "all",
        my_calendar: "My calendar"
      },
      pre_week: "<< last week",
      pre_day: "< the previous day",
      next_day: "the next day >",
      now_day: "today",
      next_week: "next week >>",
      now_week: "this week",
      next_month: "next month >>",
      pre_month: "<< last month",
      all_calendar: "all",
      my_calendar: "My calendar",
      schedule: "Schedule"
    },
    create: {
      error_date_msg: "Please specify the correct period",
      error_end_deadline: "Deadline must be greater than the current date",
      subject: "Register or update an appointment",
      title: "title",
      note: "Note",
      color: "Scheduled color",
      datetime_start: "Start date and time",
      date_start: "Start date",
      time_start: "Start time",
      datetime_end: "End date and time",
      date_end: "End date",
      time_end: "End time",
      hour: "(hour)",
      minute: "(min)",
      setting_adv: "Advanced Setting",
      scheduled_normal: "regular scheduled",
      calendar: "Period schedule",
      event_not_exist: "This calendar does not exist.",
      cannot_find_member_msg: "There are no members",
      cannot_find_device_msg: "There are no devices",
      scheduled_repeat: "recurring event",
      setting_member_share: "Select a sharer",
      setting_device: "Equipment setting",
      share_with_member_with_fix_tag: "Share to members with the specified group",
      choose_member: "Select and share members",
      btn_add_group_tag: "Add more",
      placeholder_choose_group_tag: "Select a group",
      placeholder_choose_member: "Search by member name",
      placeholder_choose_setting_device: "Search by group name",
      condition_repeat: {
        title: "Repeat condition",
        every_day: "every day",
        every_day_without_weekend: "Every day (excluding weekends)",
        every_week_day: "every week",
        every_month_day: "every month"
      },
      deadline: "Deadline",
      indefinite: "No deadline",
      util: "Until",
      day_of_week: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday"
      },
      day: "Day",
      accept_member_change_plan: "Allow non-registrants to change appointments",
      device_retweet: "This device cannot be set. It seems that it is specified by another schedule."
    },
    status: {
      register: "register",
      update: "update",
      delete: "delete"
    },
    preview: {
      edit: "edit",
      delete: "delete",
      repeat_condiction: "Describe the repeat condition",
      plan_to_repeat: "If you plan to repeat",
      sharer: "Sharer",
      setting_device: "Facility",
      regular_event: "My calendar detail - Normal schedule",
      period_event: "My calendar detail - All day / period schedule",
      repeat_event: "My calendar detail - Repeat schedule",
      repeat_detete_today: "This schedule",
      repeat_detete_future: "All future of this schedule",
      repeat_detete_all: "All schedule",
      deadline: "deadline",
      condiction: "condiction",
      repeat_detail: "Detailed display of recurring schedule",
    }
  },
  common: {
    suffix: {
      san: "",
      other: "other",
      name: "name",
      case: "case",
      anonymous: "anonymous",
      comma: ",",
      leave_group: "(group leave)",
      leave_system: "(Service withdrawal)",
      system: "System",
    },
    btn: {
      btn_create_new: "Create New",
      btn_create: "create",
      btn_register: "register",
      btn_update: "update",
      btn_edit: "edit",
      btn_delete: "delete",
      btn_cancel: "Cancel",
      btn_back: "Back",
      btn_see_more: "See more",
      btn_login: "Login",
      btn_line_login: "Log in with your LINE account",
      btn_send_mail: "send",
      btn_send_to_email: "sending mail",
      btn_back_login: "back to login screen",
      btn_to_login: "To login screen",
      btn_back_home: "back to home screen",
      btn_smile_line: "To SMILE LINE",
      btn_next: "Next",
      btn_move: "Move",
      btn_add: "Add",
      btn_choose_file: "Select files",
      btn_mark_as_read: "Mark as read",
      btn_send: "send",
      btn_confirm: "invite",
      btn_move_to_folder: "Move to folder",
      btn_add_folder: "Add folder",
      btn_change_order: "Change display order",
      btn_overwrite: "Overwrite",
      btn_search: "search",
      btn_yes: "Yes",
      btn_no: "No",
      btn_withdraw: "Withdraw",
      btn_manage_member: "Member management",
      btn_filter: "Filter",
      btn_add_member_group_chat: "Create a new chat",
      btn_delete_member: "delete",
      btn_chat: "Chat",
      btn_favorite: "favorite",
      btn_not_favorite: "Remove a favorite",
      btn_registers: "register",
      btn_invite_member: "Member editing",
      btn_change: "update",
      btn_choose: "select",
      btn_leave: "Exit",
      btn_download: "Download",
      btn_delivery: "Reservation delivery",
      btn_ok: "OK"
    },
    message: {
      copied: "copied.",
      deleted: "deleted.",
      updated: "updated.",
      registered: "registered.",
      created: "created.",
      invited: "invited.",
      del_stoped: "Deleted / canceled.",
      cleaned: "emptied.",
      moved_to_bin: "I put it in the trash.",
      deleted_attach_file: "I deleted the attachment with {from}",
      update_fail: "Could not update. If you try again and it still fails, please contact the administrator.",
      register_fail: "Could not register. If you try again and it still fails, please contact the administrator.",
      create_fail: "Could not create. If you try again and it still fails, please contact the administrator.",
      invite_fail: "Could not invite you. If you try again and it still fails, please contact your system administrator.",
      delete_fail_attach_file: "The attachment could not be deleted with {from}. Please contact your system administrator.",
      delete_fail: "Could not delete. Please contact your system administrator.",
      del_stop_fail: "Could not delete / cancel. Please contact your system administrator.",
      clean_fail: "Could not empty it. Please contact the administrator.",
      move_to_bin_fail: "Could not moved to the Trash. Contact your system administrator.",
      unsave_edit: "Your edits are not saved. Do you want to go back?",
      unsave_delete: "The information you are editing will be deleted. May I?",
      leave_group: "Do you want to leave the group?",
      del_stop_invite: "Do you want to delete / cancel?",
      clean_bin: "Do you want to empty the trash?",
      move_to_bin: "Would you like to put it in the trash?",
      delete: "delete ?",
      delete_all: "Delete All?",
      cant_leave_one_mem: "You cannot leave if there is only one member. Please contact the administrator",
      set_admin: "Please appoint a group administrator from the members.",
      choose_file: "Please choose a file.",
      mail_empty: "Please enter your e-mail address.",
      mail_wrong: "The email address is not a valid email address.",
      mail_oversize: "You can enter up to 100 single-byte characters in your email address.",
      cant_find_user: "No such user.",
      mail_duplicate: "The email address is duplicated.",
      mail_exist: "It looks like an invited email address or a member already registered in the group. Please check your email address.",
      already_in_group: "I have already joined the group.",
      sent_request_join: "You have sent a request to join. Please wait for approval.",
      unread_post: "There are {count} unread posts.",
      something_went_wrong: "Something went wrong.",
      error_type_and_size: "Select jpeg, png, gif files up to 25MB.",
      error_dimension_file: "Select a file with a width of 500px or more and a height of 100px or more.",
      reset_password_expired: "The password reset page has expired. Please try again.",
      error_confirm_password: "Passwords do not match. Please try again.",
      CURRENT_PASSWORD_AND_NEW_PASSWORD_CAN_NOT_SAME: "The new password and the current password cannot be the same.",
      CURRENT_PASSWORD_NOT_MATCH: "The current password is incorrect.",
      password_confirm_not_match: "The password confirmation does not match the password.",
      check_email: "The participation request has been accepted. \n Please check your email.",
      not_allowed_error: "I'm asking for access to the camera.",
      not_found_error: "The camera cannot be found on this device.",
      not_supported_error: "This site does not support the use of cameras.",
      not_readable_error: "The camera is being used elsewhere.",
      overconstrained_error: "The camera is not suitable.",
      stream_api_not_supported_error: "Stream API does not support this browser",
      no_data: "there is no data.",
      no_user: "There are no users.",
      no_unread: "No unread item",
      no_like: "There are no likes",
      no_favorite: "There are no favorites",
      group_tag_duplicate: "Duplicate group.",
      total_group_chat_unread: "There are {totalGroupChat} unread posts.",
      error_birthday_msg: "Please enter the correct date of birth.",
      mail_already_used: "Email address already in used.",
      eid_already_used: "It looks like a registered employee number. please confirm.",
      user_already_actived: "The email address already exists. Please log in before reading the QR code and entering the link.",
      not_have_email_to_login: "Your Line account does not have an email registered.",
      not_found_email_in_system: "The email address does not exist on the system. Please contact the administrator.",
      wait_for_approval: "I have already applied for participation. Once approved by the administrator, the group information can be viewed. You will receive an email once approved by the administrator.",
      invalid_member_in_invite_list: "The invitation list contains an invalid user. If you try again and it still fails, please contact your system administrator.",
      update_from_invite_link_fail: "Information cannot be registered because the user's status is invalid. Please contact the administrator.",
      invalid_member_status_invite: "Members cannot be invited because the user's status is invalid. Please contact the administrator.",
      group_not_found_or_deleted: "This group has been deleted or deactivated. \n Please wait a while and try again.",
      expired_token: "The link is invalid. Please contact the administrator.",
      over_500_mb: "The total size of the downloaded file exceeds 500MB. Please download in several batches.",
      topic_lock: "{name} is editing this topic",
      topic_edit_sesson_end: "The session has expired. {name} is editing.",
      topic_edited_by_other: "Another user is editing. Please try again later.",
      topic_not_exist: "This topic does not exist.",
      comment_not_exist: "This comment does not exist.",
      folder_not_exist: "The folder does not exist.",
      file_not_exist: "The file does not exist.",
      file_history_not_exist: "History does not exist.",
      calendar_not_exist: "The schedule does not exist.",
      group_over_size: "The group usage capacity cannot exceed the maximum capacity of the group.",
      upload_over_size: "The upload capacity must be less than or equal to {max} MB.",
      over_30_file: "Up to 30 files can be uploaded.",
      not_found_404: "404 Page not found.",
      group_qr_expried: "This QR code / URL cannot be used at this time. \nPlease contact the group administrator",
      update_permission_denied: "You do not have permission to modify this calendar event",
      delete_permission_denied: "You do not have permission to delete this calendar event",
      access_permission_denied: "You do not have permission to access this calendar event",
      uncatch_error: "There is an error. Please contact the administrator.",
      error_user_max_chat_size: "There is no more free storage space. Delete unnecessary files.",
      group_chat_not_found: "This group chat cannot be found.",
      can_not_access_group_chat: "You do not have permission to access this group chat.",
      confirm_leave_chat: "Do you want to leave?",
      leave_chat_success: "left group chat successfully",
      topic_no_content: "A topic without body text.",
      topic_comment_no_content: "A comment without comment text.",
      chat_no_content: "A reply without text.",
      file_no_content: "A file memo without text.",
      member_not_in_group: "Members are not included in the group.",
      no_comment_parent: "The corresponding reply source has been deleted.",
      upload_image_only: "jpeg, png, gif files can be uploaded",
      change_mail_success: "You have successfully changed your email address. \n Please log in with your new email address.",
      change_mail_fail: "Failed to change your email address.",
      group_chat_change_group: "Group chats involving people in different groups are not allowed.\nIf you select a different group, the selected user will be deleted.",
      attach_folder_dont_move: "I can't move the Attach folder's file to another folder.",
      company_storage_exceed: "Corporate capacity cannot exceed the group's maximum capacity.",
      message_not_found: 'message not found.'
    },
    dropdown_sort: {
      sort_date_time: "Update date and time",
      sort_user_name: "username",
      sort_title_name: "Title name",
      desc: "descending order",
      asc: "ascending order"
    },
    form: {
      require: "(Required)",
      require_one: "(One required)",
      title: "title",
      folder: "folder",
      folder_name: "Folder name",
      employee_id: "employee number",
      mail_address: "email address",
      password: "password",
      temp_password: "temporary password",
      password_confirm: "Password confirmation",
      full_name: "Full name",
      first_name: "last name",
      last_name: "first name",
      document: "Text",
      subject: "subject",
      full_name_alphabet: "name pronounce",
      first_name_alphabet: "last name",
      last_name_alphabet: "first name",
      please_select: "Please select",
      file_modified: "Up to 25MB jpeg, png, gif files can be uploaded",
      file_modified_all: "One maximum file size is {max} MB. Up to 30 files.",
      img_modified_with_max: "Up to {max}MB of jpeg, png, and gif files can be uploaded",
      file_modified_with_max: "Up to {max} MB can be uploaded",
      file_recommend: "Select a file with a width of 500px or more and a height of 100px or more. (Recommended size: width 000px, height 000px)",
      not_selected: "Not selected",
      multi_selected: "{count}files were selected",
      needed_item: "{name} is a required item.",
      max_length: "{name} exceeds the allowable length.",
      max_length_2: "You can enter up to {length} characters in {name}.",
      no_script_tag: "{name} contains invalid characters.",
      some_file_over_size: "The selected files may exceed {max} MB. \n The file will be removed from the list.",
      no_emoji: "{name} should not use emojis",
    },
    title: {
      common_topic: "Common topic",
      create_common_topic: "Create a common topic",
      list_participating_group: "List of participating groups"
    },
    menu: {
      group: "group",
      calendar: "My calendar",
      chat: "chat",
      setting: "Setting",
      help_center: "Help Center",
      terms_of_use: "terms of service",
      privacy_policy: "privacy policy",
      logout: "Logout"
    },
    date_pick: {
      next_month: "next month",
      prev_month: "last month",
      set_time: "time",
      mon: "mon",
      tue: "tue",
      wed: "wed",
      thu: "thu",
      fri: "fri",
      sat: "sat",
      sun: "sun",
      day: "day",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December"
    },
    date_format: {
      dd_mm_yyyy: 'DD/MM/YYYY',
      yyyy_mm_dd: 'YYYY/MM/DD',
      yyyy_mm: 'YYYY/MM',
      mm_dd: 'MM/DD',
      mm_dd_compact: 'MM/DD'
    },
    select: {
      private: "private",
      public: "Public",
      notify: "Notifications",
      no_notify: "Non-notifications",
      not_set: "Not set"
    }
  },
  auth: {
    login: {
      company_id: "Company ID",
      user_id: "Login ID",
      user_password: "password",
      login_with_email: "Login with your email address",
      failed_login_msg: "login failed. Please try again",
      invalid_user_status: "I can't confirm my account information. Please enter your login ID and password correctly.",
      login_authorized_email: "The email address and password do not match. Please try again.",
      login_authorized_employee_id: "The company ID, login ID, and password do not match. Please try again.",
      login_with_employeeID: "Login with employee number",
      password_message: "Passwords must contain at least 8 characters, at least 2 of the following characters: Upper case, lower case, numbers, and symbols",
      forgot_password: "Forgot your password?",
      notify_title: "Pre-login notification",
      recommend_browser: "Recommended browser: Google chrome, Edge Chronium version",
      user_has_been_locked: "You account has been lock because you failed to login for 10 times.<br/><a href='/forgot-password' target='_self' class='text-primary'>Click here</a> to reset your password.",
      user_must_reset_password: "Please restart your password to use the system.",
      exceed_api_limit: 'Exceed API rate limit. Please try again later.'
    },
    forgot_password: {
      email: "email address",
      error_email_msg: "The email address you entered is not registered. Please enter it correctly.",
      message: "A URL for resetting the password will be sent to the email address that will be the login ID. Please enter your email address and press the send button.",
      send_to_mail: "We have sent a password reset URL email to the email address you entered. Please check."
    },
    reset_password: {
      screen_title: "Password reset screen",
      message: "Please enter your password and press the submit button. Please use at least 8 single-byte alphanumeric characters and at least 2 types of uppercase / lowercase letters / numbers / symbols.",
      new_password: "New Password",
      confirm_new_password: "New password (confirmation)",
      confirm_new_password_placeholer: "Password (confirmation)"
    },
    completed_reset_password: {
      message: "I have reset my password. Please return to the login screen and log in."
    },
    employee_forgot_password: {
      message: "Ask your group administrator to change your password for assistance.Please re-login and change your password."
    },
    employee_forgot_password_line: {
      message: "I logged in with my LINE account."
    },
    employee_without_group: {
      only_for_employee: "SMILE LINE is an employee-only system of Checker Support Co., Ltd.",
      not_allow_login: "You cannot log in because you do not belong to a group",
      contact_to_admin: "Please contact the group administrator you want to belong to.",
      un_subscribe: "If you are leaving, click here",
      failed_un_subcribe_msg: "Could not delete. If you try again and it still fails, please contact your system administrator.",
      checker_support_company: "Checker Support Co., Ltd.",
      regicum_website: "{Regicum} cash register part-time job site"
    },
    login_line_fail: "To use SMILE LINE, registration by the group administrator is required in advance. \n\nThe registered email address could not be confirmed. \nPlease contact the group administrator."
  },
  setting: {
    help_center: "Help Center",
    terms_of_use: "terms of service",
    privacy_policy: "privacy policy",
    log_out: "Logout",
    email: "email address",
    setting_menu: {
      account_info: "Change account information",
      setting_language: "Language setting",
      display_order_groups: "Display order setting of participating groups",
      setting_display: "Display edit screen",
      setting_notify_news: "New mail notification settings",
      setting_theme: "Theme design settings",
      change_profile: "Profile update"
    },
    account_info: {
      title: "account information",
      password: "Password confirmation",
      btn_change_email: "Change your email address",
      btn_change_password: "change the password"
    },
    change_email: {
      title: "Account information Email address change",
      current_email: "Current email address",
      notify_msg: "If you want to change from the above email address, please enter the changed email address below. When you receive the email, click on the approval URL in this text.",
      new_email: "New email address",
      exist_email: "It looks like a registered {email}. please confirm.",
      send_to_mail: "Your email has been sent. Please click the email URL to complete.",
      mail_duplicate: "The new email address and the existing email address cannot be the same.",
      change_email_successfully: "it changed. Please log in again"
    },
    change_password: {
      title: "Account information password change",
      message: "Enter your password and press the button to change it. Please use at least 8 single-byte alphanumeric characters and at least 2 types of uppercase / lowercase letters / numbers / symbols.",
      current_password: "Current Password",
      new_password: "password",
      confirm_new_password: "Password confirmation"
    },
    setting_language: {
      title: "Language setting",
      current_language: "Current language",
      choose_language: "Language to set"
    },
    display_order_groups: {
      title: "Display order setting of participating groups",
      non_folder: "No folders"
    },
    setting_display: {
      title: "Display settings",
      number_items_on_list: "Number of items to be displayed in the list",
      number_items_on_comment_list: "Number of items to be displayed in the comment list",
      cases: "case",
      display_images_and_videos: "Display of images / videos",
      display: "Display thumbnails in the text",
      no_display: "Do not display thumbnails in the text",
      first_day_of_week: "Start day of the week",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday"
    },
    setting_notify_news: {
      title: "New mail notification",
      general: "common",
      stop_notify_time: "Email notification stop time zone",
      regular_email_notify: "Regular email notification",
      notify_email_address: "Notification email address",
      notify_interval: "Notification interval",
      email_notify_time: "Email notification time",
      emergency_email_notify: "Emergency email notification",
      schedule_email_notify: "Scheduled email notification",
      notify: "notification",
      no_notify: "Unannounced",
      minutes: "minutes",
      hours: "time"
    },
    setting_theme: {
      title: "Theme design settings",
      no_theme: "No theme design",
      theme_custom: "custom theme",
      upload_theme: "Upload the header image",
      title_background: "The background color of the title",
      text_color: "Text color",
      choose_theme: "Choose a prescriptive theme design"
    },
    change_profile: {
      title: "Profile",
      profile_avatar: "profile image",
      radio_upload_file: "Upload the file",
      radio_delete_image: "Delete image",
      last_name: "first name",
      first_name: "last name",
      furigana_lastname: "first name",
      furigana_firstname: "last name",
      self_introduction: "self-introduction",
      company_info: "business information",
      show_company_info: "Work information display",
      company: "Company / Affiliation",
      department: "Department name",
      position: "Job title",
      street_address: "address",
      btn_postcode_search: "Search Zip Code",
      prefectures: "prefectures",
      district: "municipalities",
      address: "Other addresses",
      building_name: "Building name",
      phone_number: "telephone number",
      add_phone: "＋Add phone number",
      add_url: "＋Add URL",
      personal_info: "Personal information",
      gender: "gender",
      male: "male",
      female: "female",
      birthday: "Birthday",
      nearest_station: "Nearest station",
      recent_situation: "Recent situation",
      url: "URL"
    }
  },
  with_draw: {
    contact_option_msg: "After withdrawal, we may contact you again. If you do not want us to contact you, please check the checkboxes below.",
    do_not_contact: "Please do not contact me.",
    confirm_delete_msg: " If you check the check box, all data will be deleted when you unsubscribe and you will not be able to use SMILE LINE again. Do you want to unsubscribe?",
    btn_back_without_withdraw: "Return to SMILE LINE without withdrawing",
    confirmed_withdraw: " I was withdrawn. <br/> Thank you for using this service."
  },
  modal_show_profile: {
    online: "online",
    japanese_name: "Kyoko Fukada",
    chinese_name: "Kyoko Fukada",
    non_public_email_message: "(Email address private)",
    last_login: "Last login",
    last_logout: "Last logout",
    hours_ago: "{hours} hours ago",
    more_than_seven: "More than 7 days ago",
    less_than_seven: "{day} days ago",
    not_login: "Unable to determine login time",
    logged_out: "Within 1 hour",
    start_chat: "Start a chat",
    company_info: "business information",
    personal_info: "Personal information",
    date_of_birth: "date of birth",
    company: "company name",
    company_name: "Checker Support Co., Ltd.",
    department: "Department",
    department_name: "System department",
    position: "Job title",
    street_address: "address",
    street_address_name: "2-9-11 Tomioka, Koto-ku, Tokyo",
    building_name: "Keifuku Building 4F",
    url: "URL",
    phone: "TEL",
    can_not_withdraw: "If you unsubscribe, some groups will no longer have a group administrator. \nGive another member group administrator privileges before unsubscribing."
  },
  modal_add_folder: {
    add_folder: "Add folder",
    message_setting: "Please enter the folder name. \n Group folders are not visible to other members.",
    message_group: "Please enter the folder name. \n Group shared folders are visible to other members as well.",
    folder_name: "Folder name"
  },
  modal_move_to_folder: {
    move_to_folder: "Move folder",
    message: "Select the destination folder for the selected group.",
    select_file_message: "Select the destination folder for the selected file.",
    destination_folder: "Destination folder"
  },
  modal_edit_folder: {
    current_folder: "Folder editing",
    current_folder_name: "East Japan Headquarters",
    edit_message: "Edit the folder name.",
    new_folder: "Folder name update",
    change_display_order: "To change the folder display order",
    here: "Here",
    delete_message_setting: "If you delete it, the folders will not be classified, including the groups in the folders under it (groups will not be deleted).",
    delete_message_not_setting: "If you delete it, the files in the folder will be unclassified."
  },
  modal_change_order: {
    title: "Change display order",
    message: "Select the folder name / group name whose order you want to change, and press the button to change the order.",
    unsave_edit: "Your changes will not be saved. Would you like to come back?"
  },
  modal_multi_select: {
    title_1: "Members of the selection group",
    message_1: "Select the members you want to invite to the group. \n Multiple selections are possible.",
    title_2: "Select group",
    message_2: "Please select a group. \n Multiple selections are possible."
  },
  modal_register_equipment: {
    name: "Equipment name",
    memo: "Note"
  },
  nav_header: {
    not_seen: "Unread:",
    like: "Like:",
    favorite: "Favorite:",
    item: "item"
  },
  nav_menu: {
    home: "HOME",
    group: "Group",
    calendar: "Calendar",
    chat: "Chat",
    setting: "Setting"
  },
  home: {
    news: {
      infomation: "Information",
      new: "new"
    },
    arrival_favourite: "Favorite new arrival list",
    new_arrival: {
      new_arrival_list: "New arrival list",
      common_topic: "Common topic",
      updater_name: "Updater name"
    }
  },
  notification: {
    unread: "Unread notification list",
    favourite: "Favorite list",
    like: "Like list",
    all_group: "All group",
    just_liked: "just liked!"
  },
  route_title: {
    home: 'Home',
    todo: 'Todo',
    unread_notification: "Unread notification",
    like_notification: "Like a notification",
    favourite_notification: "Favorite notification",
    login: "Login",
    help_center: 'Help Center',
    help_center_detail: 'Help Center Detail',
    page_not_found: 'Page Not Found',
    forgot_password: "Forgot Password",
    reset_passsword: "Reset Password",
    employee_without_group: "Employee Without Group",
    group_not_found: "Group not found",
    employee_forgot_password: "Employee Forgot Password",
    employee_forgot_password_line: "Employee Forgot Password LINE",
    line_login: "Login With Line",
    account_info: "Account Info",
    withdraw: "WithDraw",
    change_email: "Change Email Address",
    change_password: "Change Password",
    setting_language: "Setting Language",
    change_profile: "Change Profile",
    setting_display: "Setting Display",
    setting_notify_news: "Setting Notify News",
    display_order_groups: "Setting Display Order Group",
    setting_theme: "Setting Theme",
    search_result: "search results",
    group_preview: "Group preview",
    register: "register",
    group_top: "Group TOP",
    new_feed_top: "topic TOP",
    topic_create: "Topic creation",
    topic_update: "Topic editing",
    topic_detail: "Topic details",
    shared_folder_top: "Shared folder TOP",
    file_create: "File registration",
    file_update: "File editing",
    file_detail: "File details",
    member_list: "Member list",
    qr_code_invite: "QR code invitation link",
    qr_code_invite_auth: "QR code invitation link / granting invitation authority",
    invited: "Inviting a group",
    join_request: "Waiting for group approval",
    group_role: "Group permission and Delegation",
    group_infomation: "Group basic information",
    group_infomation_edit: "Group basic information editing",
    group_equipment: "Group equipment setting",
    calendar: "My calendar",
    create_event: "Register an appointment",
    edit_event: "update",
    event_preview: "Event preview",
    token_expired: "Link is invalid",
    chat_list: "Chat list",
    chat_detail: "Chat detail",
    policy: "privacy policy",
    terms: "Terms of user",
    verify: "Verification",
  },
  permission: {
    full: {
      permissions: "Group administrator"
    },
    shared: {
      folder: "Edit shared folder"
    },
    bulletin: {
      board: "Edit/Delete topic"
    },
    invite: "invite privileges"
  },
  information_msg: {
    msg_1: "You have been granted group administrator privileges for {group_name}.",
    msg_2: "You have been granted {permission_name} permissions for {group_name}.",
    msg_3: "The {permission_name} permission of {group_name} has been deprived.",
    msg_4: "{user_name} has withdrawn from {group_name}.",
    msg_5: "{User_name} joined {group_name}.",
    msg_6: "You have been invited by {group_name} (please check your e-mail invitation).",
    msg_7: "{user_name} is applying to join {group_name}. (QR code invitation)",
    msg_8: "The storage space of {group_name} is low (80% usage or more).",
    msg_9: "There is no more free storage space for {group_name}. Delete unnecessary files.",
    msg_10: "The storage space of {group_name} is low (80% usage or more).",
    msg_11: "There is no more free storage space for {group_name}. Delete unnecessary files.",
    msg_12: "There is little free storage space left in the Common Topic Area (80% usage or more).",
    msg_13: "There is no more free storage space in the [Common Topic Area]. Delete unnecessary files.",
    msg_14: "Chat space is low (80% usage or more).",
    msg_15: "There is no more space in the chat area. Delete unnecessary files.",
    msg_16: "The file {file_name} has been overwritten by {group_name}.",
    msg_17: "{Calendar_title} has been registered in the calendar.",
    msg_18: "{Calendar_title} has been removed from the calendar.",
    msg_22: "{group_name}でファイル{file_name}が上書きされました。"
  },
  favourite_notification: {
    favourite_type_1: "{topic_title}",
    favourite_type_2: "{file_name}",
    favourite_type_3: "{common_topic_name}",
    favourite_type_4: "{file_name}",
    favourite_type_5: "{calendar_title}",
    favourite_type_6: "{chat_name}"
  },
  like_notification: {
    like_type_1: "{user_name}{like_count} liked <span class='text-primary'>「{like_target}」</span>",
    like_type_2: "{user_name}{like_count} liked <span class='text-primary'>「{comment_content}」</span>",
    like_type_3: "{user_name}{like_count} liked <span class='text-primary'>「{like_target}」</span>",
    like_type_4: "{user_name}{like_count} liked <span class='text-primary'>「{comment_content}」</span>",
    like_type_5: "{user_name}{like_count} liked <span class='text-primary'>「{comment_content}」</span>",
    comment: "your comment",
    like_text: "Like!",
  },
  table_calendar: {
    last_week: "Last week",
    last_day: "The day before",
    next_week: "The next day",
    next_day: "The following week",
    today: "Today"
  },
  setting_group: "Set up join groups",
  nav_menu_bar: {
    help_center: "Help Center",
    terms_of_service: "Terms of Use",
    privacy_policy: "Privacy Policy",
    switch_to_pc_display: "Switch to PC display",
    switch_to_mobile_display: "Switch to Mobile display",
  }
}
