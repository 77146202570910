


























































































import Const from '@/constants'
import GroupService from '@/services/GroupService'
import InfomationService from '@/services/InfomationService'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component
export default class NewsComponent extends Vue {
  @Prop() loadingLine!: number
  private isLoading: boolean = true
  private news: News[] = []
  private eNewsType: any = NewsType
  private eNewsTargetType: any = NewsTargetType

  created() {
    this.$route.params.groupId ? this.getGroupNews() : this.getHomeNews()
  }

  /**
   * Call API get Home news
   */
  getHomeNews() {
    InfomationService.getInformation()
      .then((res: any) => {
        if (res.status === 200) {
          this.news = res.data
          if (!res.data.length) {
            this.$emit('noNews', true)
          } else {
            this.markAsReadAll()
          }
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  /**
   * Call API get group news
   */
  @Watch('$route.params.groupId')
  getGroupNews() {
    GroupService.getGroupNews(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.news = res.data
          if (!res.data.length) {
            this.$emit('noNews', true)
          } else {
            this.markAsReadAll()
          }
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }


  /**
   * go to detail from news
   */
  goToDetailRoute(index: number) {
    let informationClick = this.news[index]

    let target_type = informationClick.target_type
    let target_id = informationClick.target_id

    switch (target_type) {
      case NewsTargetType.NO_LINK:
        if (['msg_10', 'msg_11'].includes(informationClick.title)) {
          return {
            name: 'group-setting',
            params: { groupId: String(target_id) }
          }
        }
        return {};
      case NewsTargetType.GROUP_TOP:
        return { name: 'group', params: { groupId: String(target_id) } }
      case NewsTargetType.GROUP_APPROVE:
        return {
          name: 'group-members-join-request',
          params: { groupId: `${target_id}` }
        }
      case NewsTargetType.GROUP_SETTING:
        //open c3-401 setting group CMS
        // window.open(Const.cms_url + 'admin/group-setting', 'blank')
        // break
        return Const.cms_url + 'admin/group-setting';
      case NewsTargetType.GROUP_SHARED_FOLDER:
        return {
          name: 'group-shared-folder',
          params: { groupId: `${target_id}` }
        }
      case NewsTargetType.MY_CALENDAR:
        return { name: 'calendar' }
      default:
        return {};
    }
  }

  /**
   * go to detail from news
   */
  goToDetail(index: number) {
    let informationClick = this.news[index]

    if (informationClick.display_type === DisplayType.ONE_TIME) {
      this.markAsRead()
    } else {
      this.markAsRead(index)
    }
  }

  /**
   * Mark news read
   */
  markAsRead(index?: number) {
    console.log("mark read")
    let informationSelect: number[] =
      index !== undefined
        ? [this.news[index].id]
        : this.news
            .filter(
              item =>
                item.type === NewsType.SYSTEM &&
                item.display_type === DisplayType.ONE_TIME
            )
            .map(item => item.id)

    InfomationService.markReadInformation(informationSelect)
      .then(res => {
        if (res.status === 200 && index !== undefined) {
          this.news[index].is_readed = true
        }
      })
      .catch(err => {
        // err.response.status === 400 && err.response.data.message === 'ITEM_EXISTS_THAT_IS_NOT_INFORMATION'
        console.log(err)
      })
  }

  /**
   * Mark all news read
   */
  markAsReadAll() {
    console.log("markAsReadAll")
    let informationSelect: number[] = this.news.map(item => item.id)
    InfomationService.markReadInformation(informationSelect)
  }
}

interface News {
  id: number
  title: string
  content: string
  public_date: string
  type: NewsType
  target_id: number
  target_type: NewsTargetType
  is_readed: boolean
  display_place: DisplayPlace
  display_type: DisplayType
}

enum NewsType {
  CMS = 1,
  SYSTEM = 2
}

enum DisplayPlace {
  HOME = 1,
  GROUP = 2,
  BOTH = 3
}

enum DisplayType {
  ONE_TIME = 1,
  CONTINUES = 2
}

enum NewsTargetType {
  NO_LINK = 1,
  GROUP_TOP = 2,
  GROUP_APPROVE = 3,
  GROUP_SETTING = 4,
  GROUP_SHARED_FOLDER = 5,
  MY_CALENDAR = 6
}
