










import { Component, Vue } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
import Footer from '@/components/Footer.vue'

@Component({ components: { HeaderCompact, Footer } })
export default class LayoutCompact extends Vue {}
