























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import FormatDate from '@/helpers/FormatDate'
import {
  EFrom,
  NotificationSelected,
  IUnreadNotification,
  EUnreadType
} from '@/models/Notification'
import PreviewText from '@/helpers/PreviewText'
import { EUserStatus } from '@/models'
//@ts-ignore
import _ from 'lodash'

@Component
export default class UnreadNotifycation extends Vue {
  @Prop() private notifications!: IUnreadNotification[]
  @Prop() private unreadFrom!: EFrom
  @Prop() private groupId!: number
  private eFrom = EFrom
  private eUnreadType = EUnreadType

  private checkboxNotifications: any = []
  private isCheckAll: boolean = false
  private disableMarkReadAll: boolean = true
  private avatarCommonTopic = require('@/assets/images/logo-cs.png')
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')

  formatDate(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  getUpdaterName(noti: IUnreadNotification) {
    let text = this.hanldeUserStatus(
      noti.update_by_user,
      noti.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text, 20)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  getUnreadNotificationIcon(unread_type: EUnreadType) {
    switch (unread_type) {
      case EUnreadType.GROUP_TOPIC:
        return 'fa-clipboard-list-check'
      case EUnreadType.GROUP_TOPIC_COMMENT:
        return 'fa-comment'
      case EUnreadType.GROUP_SHARED_FOLDER:
        return 'fas fa-folder'
      // case EUnreadType.GROUP_JOIN_REQUEST:
      //   return 'fa-users'
      // case EUnreadType.GROUP_INVITED:
      //   return 'fa-users'
      case EUnreadType.COMMON_TOPIC:
        return 'fa-clipboard-list-check'
      case EUnreadType.COMMON_TOPIC_COMMENT:
        return 'fa-comment'
      case EUnreadType.COMMON_SHARED_FOLDER:
        return 'fas fa-folder'
      case EUnreadType.CHAT_CONTENT:
        return ''
      default:
        break
    }
  }

  getUnreadNotificationAvatar(
    unread_type: EUnreadType,
    imgArray: string[],
    index: number
  ) {
    const img_path = imgArray[index]
    let unreadNotificationGroup = [
      EUnreadType.GROUP_TOPIC_COMMENT,
      EUnreadType.GROUP_SHARED_FOLDER,
      EUnreadType.GROUP_TOPIC
    ]
    let unreadNotificationCommonTopic = [
      EUnreadType.COMMON_TOPIC,
      EUnreadType.COMMON_TOPIC_COMMENT,
      EUnreadType.COMMON_SHARED_FOLDER
    ]
    let unreadNotificationChat = [EUnreadType.CHAT_CONTENT]

    if (unreadNotificationGroup.includes(unread_type)) {
      return img_path ? img_path : this.avatarGroupDefault
    } else if (unreadNotificationChat.includes(unread_type)) {
      return img_path ? img_path : this.avatarDefault
    } else {
      return this.avatarCommonTopic
    }
  }

  markReadedNotification(index: number) {
    let selectedNotification = this.notifications[index]
    let notificationsSelected: NotificationSelected = {
      notifications: [selectedNotification.id]
    }

    this.$emit('notificationIds', notificationsSelected)
    this.checkboxNotifications = this.checkboxNotifications.filter(
      (item: any) => item !== selectedNotification.id
    )
  }

  markReadedNotifications() {
    let notificationsSelected: NotificationSelected = {
      notifications: this.checkboxNotifications
    }
    this.$emit('notificationIds', notificationsSelected)

    this.notifications.filter(item => item.selected)
    this.isCheckAll = false
    this.disableMarkReadAll = true
  }

  selectAllNotifications() {
    if (this.isCheckAll) {
      this.notifications.forEach(item => (item.selected = true))
      this.disableMarkReadAll = false
    } else {
      this.notifications.forEach(item => (item.selected = false))
      this.disableMarkReadAll = true
    }

    this.checkboxNotifications = this.notifications
      .filter(item => item.selected === true)
      .map(item => item.id)
  }

  /**
   * select all unread
   */
  updateSelectAllNotifications() {
    this.checkboxNotifications = this.notifications
      .filter(item => item.selected === true)
      .map(item => item.id)

    if (this.notifications.every(item => item.selected)) {
      this.isCheckAll = true
    } else {
      this.isCheckAll = false
    }

    this.disableMarkReadAll = !this.notifications.some(item => item.selected)
  }

  /**
   * Handle chat title for favortie
   *
   * If this chat is private chat (2 user) => name will NULL and title with be the other user
   *
   * IF this chat is group chat (more than 2 user) => name will bi the group chat's name
   */
  handleChatTitle(chat: IUnreadNotification) {
    if (!chat.only_user_chat) return chat.name

    if (!chat.only_user_chat && !chat.unread_icon.length) return chat.name

    let names = []

    if (chat.only_user_chat && chat.user_leave_chat) {
      names = [
        this.hanldeUserStatus(
          chat.user_leave_chat.name,
          chat.user_leave_chat.leaveType
        )
      ]
    } else {
      names = chat.title ? chat.title.split(',') : []
    }
    if (names.length > 2) {
      return (
        names.join(`${this.$t('common.suffix.san')}${this.$t('chat.comma')}`) +
        this.$t('chat.chat_group_name_with', {
          other_user_count: names.length - 2
        })
      )
    } else {
      return (
        names
          .map(name => `${name}${this.$t('common.suffix.san')}`)
          .join(`${this.$t('chat.comma')}`) + this.$t('chat.chat_with')
      )
    }
  }

  /**
   * Handle null content text
   */
  handleContentTextNull(unread: IUnreadNotification) {
    if (
      unread.content &&
      unread.content !== 'ATTACH_FILE' &&
      unread.content !== 'MESSAGE_DELETED'
    ) {
      return unread.content
    }
    switch (unread.unread_type) {
      case EUnreadType.COMMON_TOPIC:
      case EUnreadType.GROUP_TOPIC:
        return this.$t('common.message.topic_no_content')
      case EUnreadType.COMMON_TOPIC_COMMENT:
      case EUnreadType.GROUP_TOPIC_COMMENT:
        return this.$t('common.message.topic_comment_no_content')
      case EUnreadType.COMMON_SHARED_FOLDER:
      case EUnreadType.GROUP_SHARED_FOLDER:
        return this.$t('common.message.file_no_content')
      case EUnreadType.CHAT_CONTENT:
        switch (unread.content) {
          case 'ATTACH_FILE':
            return this.$t('common.message.chat_no_content')
          case 'MESSAGE_DELETED':
            return this.$t('chat.list.msg.msg_deleted')
        }
        return this.$t('common.message.chat_no_content')
      default:
        return ''
    }
  }

  handleLongText(text: string) {
    return _.truncate(text, {
      length: 50,
      separator: ' '
    })
  }

  hideDropdownUnread(event: any) {
   if (!event.ctrlKey) {
      this.$emit('hideDropdownUnread')
    } 
  }

  goToDetail(name: string, params: any, query?: any) {
    return { name: name, params: params, query: query ? query : {} }
    // if (this.$route.name === name) {
    //   this.$router.replace({ name: name, params: params, query: query ? query : {} }).catch(() => {})
    // } else {
    //   this.$router.push({ name: name, params: params, query: query ? query : {} }).catch(() => {})
    // }
  }

  goToDetailNotification(index: number) {
    let notification = this.notifications[index]
    let unread_type = notification.unread_type
    let target_id = notification.unread_target_id
    let group_id = notification.group_id
    let topic_comment_id = notification.topic_comment_id

    switch (unread_type) {
      case EUnreadType.GROUP_TOPIC:
      case EUnreadType.GROUP_TOPIC_COMMENT:
        return this.goToDetail(
          'group-new-feed-topic-detail', {
          groupId: `${group_id}`,
          topicId: `${target_id}`,
          from: this.$route.path
        }, topic_comment_id ? { commentSearchId: topic_comment_id } : {})
      case EUnreadType.GROUP_SHARED_FOLDER:
        return this.goToDetail('group-shared-folder-file-detail', {
          groupId: `${group_id}`,
          fileId: `${target_id}`,
          from: this.$route.path
        })
      // case EUnreadType.GROUP_JOIN_REQUEST:
      //   return this.goToDetail('group-members-join-request', {
      //     groupId: `${group_id}`
      //   })
      // case EUnreadType.GROUP_INVITED:
      //   // return this.goToDetail('group', { groupId: target_id })
      case EUnreadType.COMMON_TOPIC:
      case EUnreadType.COMMON_TOPIC_COMMENT:
        return this.goToDetail('common-topic-new-feed-topic-detail', {
          topicId: `${target_id}`,
          from: this.$route.path
        }, topic_comment_id ? { commentSearchId: topic_comment_id } : {})
      case EUnreadType.COMMON_SHARED_FOLDER:
        return this.goToDetail('common-topic-shared-folder-file-detail', {
          fileId: `${target_id}`,
          from: this.$route.path
        })
      case EUnreadType.CHAT_CONTENT:
        return this.goToDetail('chat-detail', {
          groupChatId: target_id,
          from: this.$route.path
        })
      default:
        return {}
    }
  }
}
