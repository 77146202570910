

















































































import { Prop, Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import PickMenu from '@/store/modules/PickMenu'
import { PickMenuItem } from '@/models'

// define global event emit
export const EventBus = new Vue()

const CommonModule = getModule(PickMenu, store)

@Component
export default class PickMenuList extends Vue {
  @Prop() private listPickMenu!: PickMenuItem[]
  private isLoadingGroups: boolean = false

  created() {
    EventBus.$on(
      'loadingGroups',
      (value: boolean) => (this.isLoadingGroups = value)
    )
  }

  beforeDestroy() {
    EventBus.$off(
      'loadingGroups',
      (value: boolean) => (this.isLoadingGroups = value)
    )
  }

  clickEvent(func: any) {
    if (func) {
      func()
      CommonModule.CLOSE_ALL()
    } else {
      CommonModule.CLOSE_ALL()
    }
  }

  fetchMoreGroups() {
    if (!this.isLoadingGroups) {
      EventBus.$emit('fetchMoreGroups')
    }
  }
}
