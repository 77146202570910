




























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class LayoutAuth extends Vue {
  private isShowBell: boolean = true

  created() {
    if (window.Notification.permission === 'granted') {
      this.isShowBell = false
    }
  }

  async handleRequest() {
    if (!window.Notification) return
    if (window.Notification.permission === 'granted') {
      this.isShowBell = false
    } else {
      window.Notification.requestPermission().then((permission: any) => {
        if (window.Notification.permission === 'granted') {
          this.isShowBell = false
        }
        console.log('Have permission to send notification')
        console.log('permission check', permission);
      })
    }
  }
  goToLogin() {
    this.$router.push({ name: 'login' })
  }
}
