import Const from '../constants'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import axiosApiInstance from '@/interceptors'
import authHeader from './AuthHeader'
import Auth from '@/store/modules/Auth'
import { getModule } from 'vuex-module-decorators'

const AuthModule = getModule(Auth, store)

class AuthService {
  login(accountLoginWithEmail: any) {
    return axiosApiInstance.post(Const.api_url + 'auth/login', accountLoginWithEmail)
  }

  loginWithLine(data: any) {
    return axios.post(Const.api_url + 'auth/line-login', data)
  }

  resetTotalNotification(params: any) {
    return axiosApiInstance.post(Const.api_url + 'auth/reset-notification', params)
  }

  logout(params: any) {
    return axiosApiInstance.post(Const.api_url + 'auth/logout', params)
  }

  refreshAccessToken() {
    return axios.get(Const.api_url + 'auth/refresh', {
      headers: authHeader()
    })
      .then((res: any) => {
        if ((res.status === 200)) {
          let access_token = res.data.items.access_token
          AuthModule.SET_NEW_TOKEN(access_token)

          return access_token
        }
      })
      .catch((err: any) => {
        if (err) {
          router.push({ name: 'login' })
        }
      })
  }

  forgotPassword(accountForgotPassword: any) {
    return axios.post(Const.api_url + 'auth/forgot-password', accountForgotPassword)
  }

  resetPassword(accountResetPassword: any) {
    return axios.post(Const.api_url + 'auth/reset-password', accountResetPassword)
  }

  checkUserBelongToGroup() {
    return axiosApiInstance.get(Const.api_url + 'auth/check-group-user-login')
  }

  checkTokenExpired(token: any) {
    return axios.post(Const.api_url + 'auth/check-token-expired', token)
  }

  getPolicy() {
    return axios.get(`${Const.api_url}privacy-policy`)
  }

  getTerms() {
    return axios.get(`${Const.api_url}term-of-use`)
  }

  updateDeviceToken(params: any) {
    return axiosApiInstance.patch(`${Const.api_url}auth/update-device-token`, params);
  }

  resetTotalNotificationBeforeLogin(params: any) {
    return axios.post(Const.api_url + 'auth/reset-notification-before-login', params)
  }

  deleteDeviceToken(params: any) {
    return axios.post(`${Const.api_url}auth/delete-device-token`, params)
  }
}

export default new AuthService
