


























































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import UnreadNotification from '@/components/NavHeaderNotification/UnreadNotification.vue'
import LikeNotification from '@/components/NavHeaderNotification/LikeNotification.vue'
import FavouriteNotification from '@/components/FavouriteNotification.vue'
import ModalSearchHeader from '@/components/Modal/ModalSearchHeader.vue'
import store from '@/store'
import {
  getUnreadCount,
  getLikeCount,
  getFavouriteCount
} from '@/helpers/GetNotificationCount'
import { EFrom } from '@/models'
import PreviewText from '@/helpers/PreviewText'
import CommonTopic from '@/store/modules/CommonTopic'
import NotificationService from '@/services/NotificationService'

const CommonTopicModule = getModule(CommonTopic, store)

@Component({
  components: {
    UnreadNotification,
    LikeNotification,
    FavouriteNotification,
    ModalSearchHeader
  }
})
export default class NavHeader extends Vue {
  @Prop({ default: 'modal-search-header' }) private modalSearchHeaderId!: string
  public dropdownPositionRight?: number
  private unread_notifiction_count: number = 0
  private like_notifiction_count: number = 0
  private favourite_notifiction_count: number = 0
  private toggleUnread: boolean = false
  private triggerUnread: boolean = false
  private toggleLike: boolean = false
  private triggerLike: boolean = false
  private toggleFavorite: boolean = false
  private triggerFavorite: boolean = false
  private noAvatar = require('@/assets/images/avatar-default.png')
  private eFrom = EFrom

  $refs!: {
    navHeader: HTMLElement
    dropdownUnread: any
    dropdownLike: any
    dropdownFavourite: any
    profile: any
  }

  @Watch('$store.state.notification.unreadCount')
  watchUnreadCountChange() {
    this.updateNotificationCount()
  }

  @Watch('$store.state.notification.likeCount')
  watchLikeCountChange() {
    this.updateNotificationCount()
  }

  @Watch('$store.state.notification.favouriteCount')
  watchFavouriteCountChange() {
    this.updateNotificationCount()
  }

  created() {
    this.init()
  }

  // @Watch('$store.state.commonTopic.reloadUnread')
  async init() {
    // await getUnreadCount()
    // await getLikeCount()
    // await getFavouriteCount()
    this.updateNotificationCount()
    this.positionNotifycationDropdownPc()
    //handle rebind unred count
    // CommonTopicModule.SET_RELOAD_UNREAD(false)
  }

  hideDropdownUnread() {
    this.$refs.dropdownUnread.hide(true)
  }

  hideDropdownLike() {
    this.$refs.dropdownLike.hide(true)
  }

  hideDropdownFavourite() {
    this.$refs.dropdownFavourite.hide(true)
  }

  handleToggleUnread() {
    this.toggleUnread = !this.toggleUnread
    this.triggerUnread = !this.$refs.dropdownUnread.visible
  }

  handleToggleLike() {
    this.toggleLike = !this.toggleLike
    this.triggerLike = !this.$refs.dropdownLike.visible
    if (this.triggerLike) {
      NotificationService.markReadAllLike().then(() => getLikeCount())
    }
  }

  handleToggleFavorite() {
    this.toggleFavorite = !this.toggleFavorite
    this.triggerFavorite = !this.$refs.dropdownFavourite.visible
  }

  positionNotifycationDropdownPc() {
    this.$nextTick(() => {
      // container padding x: 40px
      this.dropdownPositionRight = this.$refs.profile.clientWidth + 40
    })
  }

  updateNotificationCount() {
    this.unread_notifiction_count = this.$store.state.notification.unreadCount
    this.like_notifiction_count = this.$store.state.notification.likeCount
    this.favourite_notifiction_count = this.$store.state.notification.favouriteCount
  }

  handleLongText() {
    const loginUserName =
      this.$store.state.userInfo.user.info.name !== ' '
        ? this.$store.state.userInfo.user.info.name
        : 'User'
    const previewText = PreviewText.covertToPreviewText(loginUserName, 20)
    return {
      name: loginUserName,
      text: previewText.text,
      isLong: previewText.isLong
    }
  }

  openModalSearch() {
    this.$bvModal.show(this.modalSearchHeaderId)
  }
}
