var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"tabs list-unstyled d-flex bg-secondary-deep mb-0 position-relative z-10"},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id,staticClass:"flex-1 flex-xl-none w-xl--50 border-end border-secondary"},[_c('router-link',{staticClass:"flex-center text-centerd h-100 py-3",class:{
        'bg-secondary-less transition-fast': _vm.$route.name
          ? _vm.$route.name.includes(tab.routerName)
          : false,
        'text-white hover-bg-secondary-less-3': _vm.$route.name
          ? !_vm.$route.name.includes(tab.routerName)
          : false
      },attrs:{"to":tab.routerLink}},[_vm._v(" "+_vm._s(tab.displayName)+" ")])],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }