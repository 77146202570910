


















































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import PickMenu from '@/store/modules/PickMenu'
import GroupMenu from '@/views/Group/GroupMenu.vue'
import PickSettingMenu from '@/views/Setting/PickSettingMenu.vue'
import { EThemeType } from '@/models/Setting/Enum'

const CommonModule = getModule(PickMenu, store)

@Component({
  components: {
    GroupMenu,
    PickSettingMenu
  }
})
export default class NavMenu extends Vue {
  @Prop() noneFixed?: boolean
  private nav_menu_background: Object = {}
  private searchKeyWords: string = ''
  private titleColor: any

  @Watch('$store.state.setting.setting.setting_themes.theme.title_background')
  @Watch('$store.state.setting.setting.setting_themes.theme.title_color')
  @Watch('$store.state.setting.setting.setting_themes.theme.nav_menu_bg_img')
  @Watch('$store.state.setting.setting.setting_themes.theme.nav_menu_bg_color')
  watchNavBgColorChange() {
    this.setTheme()
  }

  created() {
    this.setTheme()
  }

  setTheme() {
    const themeType = this.$store.state.setting.setting.setting_themes
      .theme_type
    const navBackGroup = this.$store.state.setting.setting.setting_themes.theme
      .nav_menu_bg_color
      ? {
          backgroundColor:
            this.$store.state.setting.setting.setting_themes.theme
              .nav_menu_bg_color + ' !important'
        }
      : {
          backgroundImage:
            'url(' +
            this.$store.state.setting.setting.setting_themes.theme
              .nav_menu_bg_img +
            ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }

    const bgTitleColor = {
      backgroundColor:
        this.$store.state.setting.setting.setting_themes.theme
          .title_background + ' !important'
    }

    this.nav_menu_background = navBackGroup
    this.titleColor = {
      color: '#fff'
    }
    if (themeType === EThemeType.CUSTOM_THEME) {
      this.titleColor = {
        color:
          this.$store.state.setting.setting.setting_themes.theme.title_color +
          ' !important'
      }
      this.nav_menu_background = bgTitleColor
    }
  }

  handleShowGroupMenu() {
    CommonModule.REVERT_GROUP_MENU()
  }

  handleShowSettingMenu() {
    CommonModule.REVERT_SETTING_MENU()
  }

  handleShowPickMenu() {
    if (
      this.$store.state.pickMenu.showGroupMenu ||
      this.$store.state.pickMenu.showSettingMenu
    ) {
      CommonModule.CLOSE_ALL()
    }
  }

  // openModalSearchHeader() {
  //   this.$router
  //     .push({
  //       query: Object.assign({}, this.$route.query, {
  //         keyword_search: this.searchKeyWords
  //       })
  //     })
  //     .catch(() => {})
  //   this.$bvModal.show('modal-search-header')
  // }
}
