import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { TopicCommentGetParams, TopicGetParams } from '@/models';

class CommonTopicService {

  getAllCommonTopic(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/common-topics?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}&unread_only=${params.unread_only}`)
  }

  getCommonTopicById(topicId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/common-topics/${topicId}`)
  }

  getAllCommentByCommonTopicId(params: TopicCommentGetParams) {

    let url = `${Const.api_url}members/me/common-topics/get-all-comment-by-topic-id/${params.topic_id}?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&is_commenter_hidden=${params.is_commenter_hidden}&limit=${params.limit_comment}`

    url = params.comment_id ? url + `&find_page_by_comment_id=${params.comment_id}` : url + `&page=${params.page}`

    return axiosApiInstance.get(url)
  }

  getCommonTopicCommentById(commentId: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/common-topics/comment-detail/${commentId}`)
  }

  markAsCommonTopicRead(topicId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/mark-read/${topicId}`)
  }

  markReadAllCommonTopic() {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/mark-read-all`)
  }

  markAsCommonTopicFavorite(topicId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/new-topic-mark/${topicId}`)
  }

  markAsCommonTopicLike(topicId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/like-or-dislike-common-topic/${topicId}`)
  }

  markAsLikeCommonTopicComment(topicId: string, commentId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/${topicId}/like-or-dislike-comment/${commentId}`)
  }

  createCommonTopic(formData: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics`, formData)
  }

  updateCommonTopic(topicId: string, formData: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/${topicId}`, formData)
  }

  addNewCommonTopicComment(params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/post-comment`, params)
  }

  deleteCommonTopic(topicId: string) {
    return axiosApiInstance.delete(`${Const.api_url}members/me/common-topics/${topicId}`)
  }

  deleteCommonComment(commentId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/common-topics/delete-comment-topic/${commentId}`)
  }

  getCommonTopicUnreadCount() {
    return axiosApiInstance.get(`${Const.api_url}members/me/unread/count/common-topic`)
  }

  getAllGroupAdmin() {
    return axiosApiInstance.get(`${Const.api_url}members/me/group-tag-admin`)
  }

  checkRoleCreateCommonTopic() {
    return axiosApiInstance.get(`${Const.api_url}permission-user-in-groups`)
  }

  getCommonTopicHome(limit: number, page: number) {
    return axiosApiInstance.get(`${Const.api_url}get-data-common-topic-top?limit=${limit}&page=${page}`)
  }
}

export default new CommonTopicService
