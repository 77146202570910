
import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class ChatService {
  getGroupChats(data: any) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/group-chat-user?orderBy=' + data.orderBy + '&sortedBy=' + data.sortedBy + '&limit=' + data.limit + '&page=' + data.page + '&isGetUnRead=' + data.isGetUnRead)
  }

  markReadGroupChat(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/update-check-user-unread', data)
  }

  markReadAllGroupChat() {
    return axiosApiInstance.post(Const.api_url + 'group-chat/read-all-group-chat')
  }

  getMessageById(messageId: string) {
    return axiosApiInstance.get(`${Const.api_url}message/${messageId}`)
  }
}

export default new ChatService
