var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-light border-top border-bottom border-start-xl border-end-xl py-2 pt-6 pb-3 mb-xl-5"},[_c('NavCommonTopicCount'),_c('div',{staticClass:"flex align-items-center"},[_c('div',{staticClass:"fs-15 text-xl-center fwb mb-xl-2 py-2 mb-xl-0 px-2"},[_vm._v(" "+_vm._s(_vm.$t('common.title.list_participating_group'))+" ")]),_c('div',{staticClass:"flex-center w-100 px-2 mb-2 position-relative"},[_c('input',{staticClass:"form-control w-100 h--9 rounded-3 pe-10",attrs:{"type":"text"},domProps:{"value":_vm.filterGroup},on:{"input":function($event){return _vm.debouncedUpdateSearch($event)}}}),_c('i',{staticClass:"fal fa-search fs-25 position-absolute end-0 me-5"})])]),_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"border-bottom border-secondary"},[_c('ul',{staticClass:"list-unstyled mb-0 mh--154 overflow-auto minh--18"},_vm._l((_vm.groupsOrder),function(group,index){return _c('li',{key:index,staticClass:"loop-group"},[(
              group.folder &&
                ((_vm.groupsOrder[index - 1] &&
                  _vm.groupsOrder[index - 1].folder !== group.folder) ||
                  !index)
            )?_c('div',{staticClass:"flex-center px-2 py-2 text-primary"},[_vm._m(0,true),_c('div',{staticClass:"flex-1 fs-15 text-line-clamp-1"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(group.folder),expression:"group.folder",modifiers:{"hover":true,"top":true}}]},[_vm._v(" "+_vm._s(group.folder)+" ")])])]):_vm._e(),_c('div',{staticClass:"group-area d-flex"},[(group.folder)?_c('div',{staticClass:"border-start border-secondary-less-2 h-auto ms-5"}):_vm._e(),_c('router-link',{staticClass:"d-flex flex-1 align-items-start cursor-pointer px-2 py-2 hover-bg-secondary-deep-less",class:{ 'ps-3': group.folder },attrs:{"to":{
                name: 'group-toppage',
                params: { groupId: group.id }
              }}},[_c('div',{staticClass:"w--10 h--10 me-2 position-relative me-5"},[_c('img',{staticClass:"img-contain rounded-pill overflow-hidden",attrs:{"src":group.icon_image_small_path
                      ? group.icon_image_small_path
                      : _vm.avatarGroupDefault,"alt":group.name + 'image'}}),(group.unread_count > 0 || !group.can_notify)?_c('div',{staticClass:"dot-20 ff-ms fwb fs-10 flex-center text-light position-absolute top-0 start-100 translate-middle-x",class:group.can_notify ? 'bg-danger' : 'bg-secondary'},[(group.can_notify)?_c('span',[_vm._v(" "+_vm._s(group.unread_count > 99 ? '+99' : group.unread_count)+" ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"flex-1 fs-15 text-line-clamp-1"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(group.name),expression:"group.name",modifiers:{"hover":true,"top":true}}]},[_vm._v(_vm._s(group.name))])])])],1)])}),0),_c('div',{staticClass:"d-flex justify-content-center my-4"},[(!this.isLastPage)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light text-nowrap",class:{ 'loading-btn': _vm.isLoadingGroups },on:{"click":_vm.fetchMoreGroups}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")]):_vm._e()])]),_c('div',{staticClass:"pt-3 px-2"},[_c('router-link',{staticClass:"flex-xl-center hover-o-75",attrs:{"to":"/setting/setting-order"}},[_c('i',{staticClass:"fal fa-cog fs-22 me-3"}),_c('span',{staticClass:"fs-15"},[_vm._v(_vm._s(_vm.$t('setting_group')))])])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"me-2 position-relative me-5"},[_c('i',{staticClass:"fas fa-folder fs-30 fs-xl-30"})])}]

export { render, staticRenderFns }