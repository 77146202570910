var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column me-xl-5"},[_c('ul',{staticClass:"tabs list-unstyled d-flex bg-secondary-less mb-0 \n  flex-xl-column bg-xl-light p-xl-6 my-xl-5 border"},[_c('div',{staticClass:"d-none d-xl-flex fs-17 fwb"},[_c('i',{staticClass:"fal fa-user-friends fs-25 me-2 mb-2"}),_vm._v(" "+_vm._s(_vm.$t('groups.member_list.title'))+" ")]),_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.id,staticClass:"flex-1 border-start border-top border-light flex-xl-none",class:{ 'border-start-0': index === 0 }},[_c('router-link',{staticClass:"flex-center h-100 py-3 justify-content-xl-start hover-bg-xl-none",class:{
          'bg-secondary-tint-less text-white transition-fast bg-xl-light fw-xl-b': _vm.$route.name
            ? _vm.$route.name.includes(tab.routerName)
            : false,
          'hover-bg-secondary-less-2 hover-o-75': _vm.$route.name
            ? !_vm.$route.name.includes(tab.routerName) && !tab.disable
            : true,
          'cursor-default': tab.disable
        },attrs:{"to":tab.disable ? '' : tab.routerLink}},[(_vm.$route.name && _vm.$route.name.includes(tab.routerName))?_c('div',{staticClass:"text-xl-dark"},[_vm._v(" "+_vm._s(tab.displayName)+" ")]):_c('div',{class:{
            'text-secondary': tab.disable,
            'text-xl-primary ': !tab.disable
          }},[_vm._v(" "+_vm._s(tab.displayName)+" ")])])],1)})],2),_c('NavMenuBar',{staticClass:"d-none d-xl-block"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }