





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import NotificationService from '@/services/NotificationService'
import { UserModel } from '@/models'
import { likeNotificationRef } from '@/firebase'
import { getLikeCount } from '@/helpers/GetNotificationCount'
import LikeNotificationCommon from '@/components/LikeNotification.vue'

@Component({ components: { LikeNotificationCommon } })
export default class LikeNotification extends Vue {
  @Prop() private toggleLike!: boolean
  @Prop() private triggerLike!: boolean
  private startLoading: boolean = true
  private notifications: any[] = []
  private page: number = 1
  private unsubscribe: any

  @Watch('toggleLike')
  watchChangeNotificationLike() {
    if (this.triggerLike) this.getNotificationLike()
  }

  async created() {
    await getLikeCount()

    // firebase get change unread notification
    this.handleRealtime()
  }

  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = likeNotificationRef
      .where('userIds', 'array-contains', userId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          // this.getNotificationLike()
          getLikeCount()
        }
      })
  }

  async getNotificationLike() {
    let limit = this.$store.state.setting.setting.setting_display
      .number_item_lists

    await NotificationService.getNotificationLike(limit, this.page)
      .then(res => {
        if (res.status === 200) {
          this.notifications = res.data.data
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.startLoading = false))
  }

  goToLike() {
    if (this.$route.name === 'like') {
      this.$emit('hideDropdownLike')
    } else {
      this.$router.push({ name: 'like' })
    }
  }

  hideDropdownLike() {
    this.$emit('hideDropdownLike')
  }
}
