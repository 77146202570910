import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from '../services/AuthService'
import store from '@/store'
import router from '@/router'
import main from '@/main'

const axiosInstance = axios.create();

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    let token = store.state.auth.token

    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptor for API calls
axiosInstance.interceptors.response.use((response: AxiosResponse) => {
  return response
}, async (error) => {
  const originalRequest = error ? error.config : null;

  if (
    error.response.status === 401
    && !originalRequest._retry
    && error?.response?.data?.status_code === "TOKEN_EXPIRED"
  ) {
    router.push({ name: 'login' })
    sessionStorage.clear()
    localStorage.clear()
  }

  if (
    error.response.status === 401
    && (error?.response?.data?.status_code === "INVALID_USER" || error?.response?.data?.status_code === "INVALID_TOKEN")
  ) {
    router.push({ name: 'login' })
    sessionStorage.clear()
    localStorage.clear()
  }

  if (
    error.response.status === 401
    // && !error.response.data.is_request_join_group
    && error?.response?.data?.status_code === "USER_NOT_BE_LONG_TO_GROUP"
    && !['login', 'email-login', 'line-login', 'withdraw'].includes(String(main.$route.name))
  ) {
    router.push({ name: 'employee-without-group' })
  }

  if (error.response.status === 403 && error?.response?.data?.message === "This action is unauthorized.") {
    setTimeout(() => router.push({ name: 'home' }), 3000)
  }

  if (
    error.response.status === 404 &&
    (error?.response?.data?.message === "GROUP_NOT_FOUND" ||
      error?.response?.data?.message === "GROUP_IS_STOPPED")
  ) {
    main.$bvModal.show('modal-error-group-not-found-or-deleted') //This modal is define in GroupHeader.vue
  }

  if (
    error.response.status === 400 &&
    (error?.response?.data?.message === "EXCEED_COMPANY_STORAGE_CAPACITY")
  ) {
    main.$bvModal.show('modal-error-company-storage-exceed') //This modal is define in GroupHeader.vue
  }

  if (error.response.status === 429) {
    main.$bvModal.show('modal-api-exceed')
  }

  return Promise.reject(error);
});

export default axiosInstance;
