



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import NavMenuBar from '@/components/NavMenuBar.vue'
import { ITabModel } from '@/models'

@Component({ components: { NavMenuBar } })
export default class GroupSettingNav extends Vue {
  private tabs: ITabModel[] = []

  created() {
    this.initTab()
  }

  @Watch('$route.params.groupId')
  @Watch('$store.state.userInfo.user.group_role')
  initTab() {
    const roleAdmin: boolean = this.$store.state.userInfo.user.group_role
      .role_admin
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t('groups.setting.basic_info.title')),
        routerName: 'group-setting-information',
        routerLink: `/group/${this.$route.params.groupId}/setting/information`
      },
      {
        id: 1,
        displayName: String(this.$t('groups.setting.equipment.title')),
        routerName: 'group-setting-equipment',
        routerLink: `/group/${this.$route.params.groupId}/setting/equipment`,
        disable: !roleAdmin
      }
    ]
  }
}
