var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('nav',{staticClass:"nav-menu bg-primary position-xl-initial z-1040",class:_vm.noneFixed ? '' : 'start-0 end-0 bottom-0 position-fixed',style:(_vm.nav_menu_background),on:{"click":_vm.handleShowPickMenu}},[_c('div',{staticClass:"d-block ps-xl-3 pe-xl-10"},[_c('div',{staticClass:"d-flex justify-content-xl-between align-items-center"},[_c('ul',{staticClass:"list-unstyled d-flex justify-content-between justify-content-xl-start mb-0 w-100 w-xl-auto"},[_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('router-link',{staticClass:"flex-center flex-column flex-xl-row text-light h--17 h-xl--14",attrs:{"to":"/"}},[_c('i',{staticClass:"fs-24 fs-xl-36 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.name === 'home'
                    ? 'fas fa-home-lg-alt'
                    : 'fal fa-home',style:(_vm.titleColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-19",style:(_vm.titleColor)},[_vm._v(_vm._s(_vm.$t('nav_menu.home')))])])],1),_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('div',{staticClass:"btn-pick-up flex-center flex-column flex-xl-row text-light h--17 h-xl--14 cursor-pointer",on:{"click":[function($event){$event.stopPropagation();},_vm.handleShowGroupMenu]}},[_c('i',{staticClass:"fs-24 fs-xl-36 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.path.includes('/group')
                    ? 'fas fa-users'
                    : 'fal fa-users',style:(_vm.titleColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-19",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t('nav_menu.group'))+" ")])])]),_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('router-link',{staticClass:"flex-center flex-column flex-xl-row text-light h--17 h-xl--14",attrs:{"to":"/calendar"}},[_c('i',{staticClass:"fs-24 fs-xl-36 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.path.includes('/calendar')
                    ? 'fas fa-calendar-alt'
                    : 'fal fa-calendar-alt',style:(_vm.titleColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-19",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t('nav_menu.calendar'))+" ")])])],1),_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('router-link',{staticClass:"flex-center flex-column flex-xl-row text-light h--17 h-xl--14",attrs:{"to":"/chat"}},[_c('i',{staticClass:"fs-24 fs-xl-36 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.path.includes('/chat')
                    ? 'fas fa-comments'
                    : 'fal fa-comments',style:(_vm.titleColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-19",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t('nav_menu.chat'))+" ")])])],1),_c('li',{staticClass:"flex-1 flex-xl-none px-xl-7 hover-linear-gradient"},[_c('div',{staticClass:"btn-pick-up flex-center flex-column flex-xl-row text-light h--17 h-xl--14 cursor-pointer",on:{"click":[function($event){$event.stopPropagation();},_vm.handleShowSettingMenu]}},[_c('i',{staticClass:"fs-24 fs-xl-36 mb-2 mb-xl-0 me-xl-3",class:_vm.$route.path.includes('/setting')
                    ? 'fas fa-cog'
                    : 'fal fa-cog',style:(_vm.titleColor)}),_c('span',{staticClass:"fw-xl-b fs-8 fs-xl-19",style:(_vm.titleColor)},[_vm._v(" "+_vm._s(_vm.$t('nav_menu.setting'))+" ")])])])]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-search-header",modifiers:{"modal-search-header":true}}],staticClass:"d-none d-xl-flex justify-content-end align-items-center border rounded-2 w--60 h--10 bg-light px-4 cursor-pointer outline-0"},[_c('i',{staticClass:"fal fa-search fs-26"})])])])]),_c('div',{staticClass:"h-100 w-100 z-1030 top-0 start-0 transition",attrs:{"id":"backdrop-pick-menu"}}),_c('GroupMenu'),_c('PickSettingMenu')],1)}
var staticRenderFns = []

export { render, staticRenderFns }