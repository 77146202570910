import Vue from 'vue'

import Title from '@/components/Title.vue'
import ModalInfo from '@/components/Modal/ModalInfo.vue'
import ModalError from '@/components/Modal/ModalError.vue'
import ModalSuccess from '@/components/Modal/ModalSuccess.vue'
import ModalConfirm from '@/components/Modal/ModalConfirm.vue'
import ModalApiStatus from '@/components/Modal/ModalApiStatus.vue'
import ModalApiExceed from '@/components/Modal/ModalApiExceed.vue'
import LoadingProgress from '@/components/Loading/LoadingProgress.vue'
import LoadingNotification from '@/components/Loading/LoadingNotification.vue'
import LoadingCommonTopic from '@/components/Loading/LoadingCommonTopic.vue'


Vue.component('Title', Title)
Vue.component('ModalInfo', ModalInfo)
Vue.component('ModalError', ModalError)
Vue.component('ModalSuccess', ModalSuccess)
Vue.component('ModalConfirm', ModalConfirm)
Vue.component('ModalApiStatus', ModalApiStatus)
Vue.component('ModalApiExceed', ModalApiExceed)
Vue.component('LoadingProgress', LoadingProgress)
Vue.component('LoadingNotification', LoadingNotification)
Vue.component('LoadingCommonTopic', LoadingCommonTopic)
