var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block"},[_c('div',{staticClass:"position-relative h--15 h-xl--25 w-100 transition"},[(_vm.groupInfo.header_image)?_c('img',{staticClass:"position-absolute img-cover z-1",attrs:{"src":_vm.groupInfo.header_image}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('div',{staticClass:"cursor-pointer flex-fixed position-relative h--13 w--15 h-xl--20 w-xl--22 ms-3 z-2",on:{"click":_vm.toTopPage}},[_c('img',{staticClass:"rounded-pill img-cover h--13 w--13 h-xl--20 w-xl--20 ms-3",attrs:{"src":_vm.groupInfo.icon_image_small_path
              ? _vm.groupInfo.icon_image_small_path
              : _vm.noGroupAvatar}}),_c('i',{staticClass:"fas fs-25 fs-xl-30 position-absolute start-0 bottom-0",class:{
            'text-light text-shadow-dark-012':
              _vm.groupInfo.letter_color === _vm.eNameColor.WHITE,
            'text-dark text-shadow-light-012':
              _vm.groupInfo.letter_color === _vm.eNameColor.BLACK,
            'fa-cog': _vm.userRole.role_admin,
            'fa-user-cog':
              Object.values(_vm.userRole).includes(true) && !_vm.userRole.role_admin
          }})]),_c('div',{staticClass:"flex-center mt-6 pt-xl-12 cursor-pointer"},[_c('span',{staticClass:"fw-bold fs-20 fs-xl-25 pb-1 pb-xl-5 px-2 text-line-clamp-1 z-2",class:{
            'text-light text-shadow-dark-012':
              _vm.groupInfo.letter_color === _vm.eNameColor.WHITE,
            'text-dark text-shadow-light-012':
              _vm.groupInfo.letter_color === _vm.eNameColor.BLACK
          },domProps:{"innerHTML":_vm._s(
            ("<span v-b-tooltip.hover title='" + (_vm.groupInfo.name) + "'>" + (_vm.groupInfo.name) + "</span>")
          )},on:{"click":_vm.toTopPage}})])])]),_vm._t("default"),_c('ModalError',{attrs:{"id":'modal-error-group-not-found-or-deleted',"errorMess":_vm.$t('common.message.group_not_found_or_deleted')},on:{"close":_vm.jumpToHome}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }