





















































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import CalendarService from '@/services/CalendarService'
import GroupService from '@/services/GroupService'
import moment from 'moment'
import FormatDate from '@/helpers/FormatDate'
import { EIconType, EPlanType } from '@/models'
import CalendarColor from '@/helpers/CalendarColor'
import { GROUP_LIMIT } from '@/constants'

interface Calendar {
  date: string
  events: Event[]
  week_day: number
}

interface Event {
  event_color: string
  event_end_date: string
  event_end_time: string
  event_start_date: string
  event_start_time: string
  id: number
  is_event_continue: number
  plan_type: number
  icon_type: EIconType[]
  title: string
}

enum EnumEventCountinue {
  EEventNotCountinue = 0,
  EEventCountinue = 1
}

@Component
export default class TableCalendar extends Vue {
  private calendarColor: CalendarColor = new CalendarColor()
  private calendars: Calendar[] = []
  private eventContinue: any[] = []
  private groupsUserJoin: any[] = []
  private targetsTypeCalendar: any[] = []
  private targetsTypeCalendarId: number | null = null
  private targetsTypeCalendarType: number = 1
  private targetsTypeCalendarName: string = this.$t(
    'calendar.display.all_calendar'
  ) as string
  private today: Date = new Date()
  private selectDay: Date = this.today
  private optionsTypeCalendar: any = [
    {
      id: -2,
      name: this.$t('calendar.display.all_calendar'),
      type: 1
    },
    {
      id: -1,
      name: this.$t('calendar.display.my_calendar'),
      type: 2
    }
  ]
  private week_start_date: number = Number(
    this.$store.state.setting.setting.setting_display.first_day_of_week
  )
  private eEvent: any = EnumEventCountinue
  private collapse: any = {
    prevWeek: false,
    prevDay: false,
    today: true,
    nextDay: false,
    nextWeek: false
  }
  private startLoading: boolean = true
  private eIconType: any = EIconType
  private user_setting: any = this.$store.state.setting.setting.setting_language
  private page: number = 1
  private isLastPage: boolean = false
  private isLoadingGroups: boolean = false

  created() {
    this.getAllGroupUserJoin()
    this.getCalendarToday()
    // this.getCalendar(this.week_start_date)
  }

  onDropdownShown() {
    const dropdownMenu = (this.$refs.listGroupRef as any).$refs.menu
    dropdownMenu.addEventListener('scroll', this.handleScroll)
  }

  onDropdownHidden() {
    const dropdownMenu = (this.$refs.listGroupRef as any).$refs.menu
    dropdownMenu.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const dropdownMenu = (this.$refs.listGroupRef as any).$refs.menu
    const hasScroll = dropdownMenu.scrollHeight > dropdownMenu.clientHeight
    const isBottom =
      dropdownMenu.scrollTop + dropdownMenu.clientHeight >=
      dropdownMenu.scrollHeight
    if (hasScroll && isBottom && !this.isLastPage && !this.isLoadingGroups) {
      this.page++
      this.getAllGroupUserJoin()
    }
  }

  /**
   * Call API get group user joined
   */
  async getAllGroupUserJoin() {
    this.isLoadingGroups = true
    await GroupService.getAllGroupCompact(this.page, GROUP_LIMIT)
      .then(res => {
        if (res.status === 200) {
          const newList = res.data.data.map((item: any) =>
            Object.assign(item, { type: 3 })
          )
          this.groupsUserJoin = [...this.groupsUserJoin, ...newList]
          this.isLastPage = this.page >= res.data.last_page
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.isLoadingGroups = false)
    this.targetsTypeCalendar = this.optionsTypeCalendar.concat(
      this.groupsUserJoin
    )
    this.targetsTypeCalendarName = this.targetsTypeCalendar[0].name
    this.targetsTypeCalendarId = this.targetsTypeCalendar[0].id
  }

  selectTypeCalendarFilter(index: number) {
    let selectedTargetCalendarFilter: any = this.targetsTypeCalendar[index]
    this.targetsTypeCalendarName = selectedTargetCalendarFilter.name
    this.targetsTypeCalendarId = selectedTargetCalendarFilter.id
    this.targetsTypeCalendarType = selectedTargetCalendarFilter.type

    this.getCalendar(this.week_start_date)
  }

  /**
   * Call API get calendar by week
   */
  getCalendar(week_start_date?: number) {
    let date = moment(this.selectDay).format('YYYY-MM-DD')
    let type = this.targetsTypeCalendarType
    let group_id = this.targetsTypeCalendarId

    CalendarService.getCalendarByWeek(date, type, group_id) //, week_start_date
      .then(res => {
        if (res.status === 200) {
          let data = res.data.data

          //hanlde end date for DURRATION
          data.forEach((item: any) => {
            item.events = item.events.filter((event: any) => {
              if (event.plan_type === EPlanType.DURRATION) {
                const endDate = moment(event.event_end_date).subtract(1, 'd')
                return moment(item.date).isSameOrBefore(endDate)
              }
              if (
                event.plan_type === EPlanType.NORMAL &&
                item.date === event.event_end_date
              ) {
                return event.event_end_time !== '00:00'
              }
              return true
            })
          })
          data.forEach((item: any) => {
            item.events = item.events.filter(
              (i: any) => !this.checkEventAllDay(i)
            )
          })
          this.calendars = data

          // handle continues filter plan nornal + start_date vs end_date is at the same day
          this.eventContinue = Object.values(res.data.event_continue).filter(
            (item: any) => {
              const start = moment(item.event_start_date)
              const end = moment(item.event_end_date)
              const endAtNight = item.event_end_time === '00:00'
              return (
                !moment(start).isSame(end, 'day') &&
                !(moment(start.add(1, 'day')).isSame(end, 'day') && endAtNight)
              )
            }
          )

          // if event_end_time === '00:00' replace last 1 by 0
          // if last day in table >= event_end_date -> dont replace anymore
          this.eventContinue.forEach((item: any) => {
            if (
              item.event_end_time === '00:00' &&
              moment(item.event_end_date).isBefore(
                moment(this.selectDay).add(6, 'days')
              )
            ) {
              let change = true
              item.continue = item.continue.reverse().map((i: number) => {
                if (i === 1 && change) {
                  change = false
                  return 0
                }
                return i
              })
              item.continue.reverse()
            }
          })
          // this.eventContinue = Object.values(res.data.event_continue)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        Object.keys(this.collapse).forEach(k => {
          k === 'today' ? (this.collapse[k] = true) : (this.collapse[k] = false)
        })
        this.startLoading = false
      })
  }

  /**
   * jump to calendar create page
   */
  createEvent(date_start: any) {
    return {
      name: 'calendar-create',
      params: {
        event_start_date: date_start,
        event_end_date: this.moment(date_start).add(1, 'd')
      }
    }
  }

  /**
   * jump to calendar detail page
   */
  goToDetailCalendar(event: any, continues?: any) {
    if (!event.id || !continues) return
    const chooseDate = this.moment(event.event_start_date).format('YYYY/MM/DD')
    return {
      name: 'calendar-preview',
      params: { id: `${event.id}`, chooseDate: chooseDate }
    }
  }

  /**
   * handle event title
   */
  handleEventTitle(event: any) {
    if (event.id) return event.title
    return this.$store.state.language.language.code === 'ja'
      ? event.title_ja
      : event.title_en
  }

  /**
   * get caledar today
   */
  getCalendarToday() {
    this.collapse.today = false
    this.selectDay = this.today
    this.getCalendar()
  }

  /**
   * get calendar next day
   */
  getCalendarNextDay() {
    this.collapse.nextDay = true
    this.selectDay = moment(this.selectDay)
      .add(1, 'day')
      .toDate()
    this.getCalendar()
  }

  /**
   * get calendar previous day
   */
  getCalendarPrevDay() {
    this.collapse.prevDay = true
    this.selectDay = moment(this.selectDay)
      .subtract(1, 'day')
      .toDate()
    this.getCalendar()
  }

  /**
   * get calendar next week
   */
  getCalendarNextWeek() {
    this.collapse.nextWeek = true
    this.selectDay = moment(this.selectDay)
      .add(7, 'day')
      .toDate()
    this.getCalendar()
  }

  /**
   * get calendar previous week
   */
  getCalendarPrevWeek() {
    this.collapse.prevWeek = true
    this.selectDay = moment(this.selectDay)
      .subtract(7, 'day')
      .toDate()
    this.getCalendar()
  }

  /**
   * get day of week
   */
  getDayOfWeek(day: number) {
    return FormatDate.getDayOfWeek(day)
  }

  /**
   * Check is event all day
   */
  checkEventAllDay(event: any) {
    if (event.plan_type === EPlanType.NORMAL) return
    const start = moment(event.event_start_date)
    let end = moment(event.event_end_date)
    if (event.event_end_time === '00:00') {
      end = end.subtract(1)
    }
    return !moment(start).isSame(end, 'day')
  }

  handleEventTime(event: any, calendar: any) {
    if (
      (event.event_start_time === '00:00' &&
        event.event_end_time === '00:00') ||
      event.is_holiday
    ) {
      if (event.is_holiday) {
        if (this.user_setting) {
          if (this.user_setting.code == 'ja') {
            return event.title_ja
          } else {
            return event.title_en
          }
        }
      }

      return this.$t('calendar.display.allday_content')
    }
    if (
      event.plan_type !== EPlanType.NORMAL ||
      event.event_end_date === event.event_start_date
    )
      return `${event.event_start_time} - ${event.event_end_time}`
    if (
      event.event_start_date === calendar.date &&
      event.event_start_time !== '00:00'
    )
      return `${event.event_start_time} - 00:00`
    if (
      event.event_end_date === calendar.date &&
      event.event_end_date !== '00:00'
    )
      return `00:00 - ${event.event_end_time}`

    return this.$t('calendar.display.allday_content')
  }

  /**
   * Check is japanese holiday
   */
  isJapanHoliday(calendar: any) {
    if (!calendar.events) return false
    return calendar.events.map((item: any) => item.id).includes(null)
  }

  /**
   * handle countinues class
   */
  handleContinuesClass(event: any, eventCountinue: any, index: number) {
    let cls = ['minw--39 minw-xl--60 h--5 col-12-7']

    if (eventCountinue === EnumEventCountinue.EEventCountinue) {
      cls.push('event-continue')
    } else {
      cls.push('event-not-continue')
    }

    if (
      eventCountinue === EnumEventCountinue.EEventCountinue &&
      event.continue[index - 1] === EnumEventCountinue.EEventNotCountinue
    ) {
      cls.push('rounded-start')
    }

    if (
      eventCountinue === EnumEventCountinue.EEventCountinue &&
      event.continue[index + 1] === EnumEventCountinue.EEventNotCountinue
    ) {
      cls.push('rounded-end')
    }

    if (
      this.calendarColor.getTextColor(event.event_color) ===
      this.calendarColor.white
    ) {
      cls.push('text-light')
    }

    return cls.join(' ')
  }

  /**
   * handle class for white event
   */
  handleBorderWhite(event: any, eventCountinue: any, index: number) {
    let cls = ['continues-class d-flex p-0']

    if (eventCountinue === EnumEventCountinue.EEventCountinue) {
      if (event.event_color === this.calendarColor.white) {
        cls.push('border-top border-bottom border-secondary')
      }
    }

    if (
      eventCountinue === EnumEventCountinue.EEventCountinue &&
      event.continue[index - 1] === EnumEventCountinue.EEventNotCountinue
    ) {
      if (event.event_color === this.calendarColor.white) {
        cls.push('border-start rounded-start')
      }
    }

    if (
      eventCountinue === EnumEventCountinue.EEventCountinue &&
      event.continue[index + 1] === EnumEventCountinue.EEventNotCountinue
    ) {
      if (event.event_color === this.calendarColor.white) {
        cls.push('border-end rounded-end')
      }
    }

    if (
      this.calendarColor.getTextColor(event.event_color) ===
      this.calendarColor.white
    ) {
      cls.push('text-light')
    }

    return cls.join(' ')
  }
}
