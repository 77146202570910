


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import FormatDate from '@/helpers/FormatDate'
import { EUserStatus, UserModel } from '@/models'
import PreviewText from '@/helpers/PreviewText'

@Component
export default class LikeNotification extends Vue {
  @Prop() private notifications!: any[]
  private currentUser: UserModel = new UserModel()
  private avatarCommonTopic = require('@/assets/images/logo-cs.png')
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private eLikeType: any = ELikeType

  get userLogin() {
    return this.$store.state.userInfo.user
  }

  private setUserLogin(user: any) {
    this.$set(this.currentUser, 'userId', user.info.id)
    this.$set(this.currentUser, 'userName', user.info.name)
    this.$set(this.currentUser, 'avatar', user.profile.avatar)
  }

  /**
   * format date
   */
  formatDate(date: string) {
    return FormatDate.formatCompact(date)
  }

  getLikeNotificationAvatar(
    like_type: ELikeType,
    imgArray: string[],
    index: number
  ) {
    const img_path = imgArray[index]
    let likeNotificationGroup = [
      ELikeType.GROUP_TOPIC,
      ELikeType.GROUP_TOPIC_COMMENT
    ]
    let likeNotificationCommonTopic = [
      ELikeType.COMMON_TOPIC,
      ELikeType.COMMON_TOPIC_COMMENT
    ]
    let likeNotificationChat = [ELikeType.CHAT]

    if (
      likeNotificationGroup.includes(like_type) ||
      likeNotificationChat.includes(like_type)
    ) {
      return img_path ? img_path : this.avatarGroupDefault
    } else if (likeNotificationCommonTopic.includes(like_type)) {
      return this.avatarCommonTopic
    }
  }

  hideDropdownLike(event: any) {
    if(!event.ctrlKey) {
      this.$emit('hideDropdownLike')
    }
  }

  goToDetail(name: string, params: any, query: any) {
    // if (this.$route.name === name) {
    //   this.$router
    //     .replace({ name: name, params: params, query: query })
    //     .catch(() => {})
    // } else {
    //   }
    return { name: name, params: params, query: query }
  }

  goToNotificationDetail(index: number) {
    let notification = this.notifications[index]
    let like_type = notification.like_type
    let target_id = notification.like_target_id
    let group_id = notification.group_id
    let commet_id = notification.comment_id

    switch (like_type) {
      case ELikeType.GROUP_TOPIC:
      case ELikeType.GROUP_TOPIC_COMMENT:
        return this.goToDetail(
          'group-new-feed-topic-detail',
          {
            groupId: `${group_id}`,
            topicId: `${target_id}`,
            from: this.$route.path
          },
          commet_id ? { commentSearchId: commet_id } : {}
        )
      case ELikeType.COMMON_TOPIC:
      case ELikeType.COMMON_TOPIC_COMMENT:
        return this.goToDetail(
          'common-topic-new-feed-topic-detail',
          {
            topicId: `${target_id}`,
            from: this.$route.path
          },
          commet_id ? { commentSearchId: commet_id } : {}
        )
      case ELikeType.CHAT:
        return this.goToDetail(
          'chat-detail',
          {
            groupChatId: `${target_id}`,
            from: this.$route.path
          },
          commet_id ? { message: commet_id } : {}
        )
      default:
        return {}
    }
  }

  handleGetUserNameLike(notification: any) {
    let userLikeName =
      notification.allow_like_anonymous === true
        ? (notification.update_by_user as any[])
            .map(() => this.$t('common.suffix.anonymous') as string)
            .join(this.$t('common.suffix.comma') as string)
        : (notification.update_by_user.map((item: any) => {
            if (item.user_active_status) {
              return `<span class='text-secondary-tint-less'>${this.hanldeUserStatus(
                item.name,
                item.user_active_status
              )}</span>`
            }
            return this.hanldeUserStatus(item.name, item.user_active_status)
          }) as any[])
            .map((item: any) => item + (this.$t('common.suffix.san') as string))
            .join(this.$t('common.suffix.comma') as string)

    return userLikeName
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  goToLike() {
    if (this.$route.name === 'like') {
      this.$emit('hideDropdownLike')
    } else {
      this.$router.push({ name: 'like' })
    }
  }

  handleLikeDescription(notification: any) {
    const cmtText = PreviewText.covertToPreviewText(
      notification.comment_content,
      50
    ).text

    return this.$t(`like_notification.like_type_${notification.like_type}`, {
      user_name: this.handleGetUserNameLike(notification),
      like_count: `${
        notification.like_count > 2
          ? String(this.$t('common.suffix.other')) +
            (notification.like_count - 2) +
            String(this.$t('common.suffix.name'))
          : ''
      }`,
      like_target: `${
        notification.like_target
          ? PreviewText.covertToPreviewText(notification.like_target, 50).text
          : notification.like_type === ELikeType.GROUP_TOPIC_COMMENT ||
            notification.like_type === ELikeType.COMMON_TOPIC_COMMENT
          ? this.$t('like_notification.comment')
          : ''
      }`,
      like_char: notification.like_character_change
        ? notification.like_character_change
        : this.$t('like_notification.like_text'),
      comment_content: cmtText ? `${cmtText}` : ''
    })
  }
}

enum ELikeType {
  GROUP_TOPIC = 1,
  GROUP_TOPIC_COMMENT = 2,
  COMMON_TOPIC = 3,
  COMMON_TOPIC_COMMENT = 4,
  CHAT = 5
}
