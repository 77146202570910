var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.favorites.length || _vm.startLoading || _vm.favoriteFrom !== _vm.eFrom.HOME)?_c('div',{staticClass:"favorite-notification mb-xl-5 bg-light",class:_vm.favoriteFrom === _vm.eFrom.HOME ? 'bg-light-o95' : 'bg-light'},[(_vm.favoriteFrom !== _vm.eFrom.NAVBAR)?_c('Title',[_vm._v(" "+_vm._s(_vm.$t('home.arrival_favourite'))+" ")]):_vm._e(),_c('div',{class:{ 'border-top border-xl': _vm.favoriteFrom !== _vm.eFrom.NAVBAR }},[_c('div',{staticClass:"fs-12",class:{ 'fs-xl-15': _vm.favoriteFrom !== _vm.eFrom.NAVBAR }},[(_vm.startLoading)?_c('div',[_c('LoadingCommonTopic'),_c('LoadingCommonTopic'),_c('LoadingCommonTopic')],1):_vm._e(),_vm._l((_vm.favorites),function(favorite,index){return _c('div',{key:index,staticClass:"hover-bg-secondary-deep-less mx-xl-4",class:{ 'bg-yellow-tint': !favorite.is_read }},[_c('div',{staticClass:"d-flex align-items-center",class:{
            'px-xl-3': _vm.favoriteFrom !== _vm.eFrom.NAVBAR
          },on:{"click":_vm.hideDropdownFavourite}},[_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-center px-2 flex-fixed",class:{
              'pe-8 pe-xl-9':
                favorite.favorite_target_type === _vm.eFavoriteType.CHAT
            }},[(
                favorite.favorite_target_type !== _vm.eFavoriteType.CHAT &&
                  favorite.favorite_target_type !== _vm.eFavoriteType.CALENDAR
              )?_c('div',{staticClass:"flex-center"},[_c('div',{staticClass:"flex-fixed position-relative"},[(
                    favorite.favorite_target_type ===
                      _vm.eFavoriteType.GROUP_TOPIC ||
                      favorite.favorite_target_type ===
                        _vm.eFavoriteType.GROUP_FILE
                  )?_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--9 w--9 me-1",attrs:{"src":favorite.group_avatar[0]
                      ? favorite.group_avatar[0]
                      : _vm.avatarGroupDefault}}):_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--9 w--9 me-1",attrs:{"src":_vm.avatarCommonTopic}}),(favorite.emergency_contact)?_c('i',{staticClass:"fas fa-exclamation-circle fs-12 fs-xl-15 position-absolute end-0 bottom-0 text-danger bg-light rounded-pill"}):_vm._e()]),(
                  favorite.favorite_target_type ===
                    _vm.eFavoriteType.GROUP_TOPIC ||
                    favorite.favorite_target_type ===
                      _vm.eFavoriteType.COMMON_TOPIC
                )?_c('div',[(favorite.is_topic_comment)?_c('i',{staticClass:"far fa-comment text-primary fs-20 fs-xl-25 ps-1"}):_c('i',{staticClass:"far fa-clipboard-list-check text-primary fs-20 fs-xl-25 px-1"})]):_c('i',{staticClass:"fas fa-folder text-primary fs-20 fs-xl-25 px-1"})]):_vm._e(),(favorite.favorite_target_type === _vm.eFavoriteType.CHAT)?_c('div',{staticClass:"d-flex flex-fixed"},[_c('div',{staticClass:"d-block"},[(favorite.group_avatar.length)?_c('ul',{staticClass:"avatar-group h--9 w--9 mb-0"},_vm._l((favorite.group_avatar),function(avatar,index){return _c('li',{key:index,staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":avatar ? avatar : _vm.avatarDefault}})])}),0):_c('ul',{staticClass:"avatar-group h--9 w--9 mb-0"},[_c('li',{staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":_vm.avatarDefault}})])])])]):_vm._e(),(favorite.favorite_target_type === _vm.eFavoriteType.CALENDAR)?_c('div',{staticClass:"flex-center"},[(favorite.is_my_calendar)?_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--9 w--9 me-1",attrs:{"src":favorite.avatar ? favorite.avatar : _vm.avatarDefault}}):_c('img',{staticClass:"rounded-pill img-cover flex-fixed h--9 w--9 me-1",attrs:{"src":favorite.group_avatar[0]
                    ? favorite.group_avatar[0]
                    : _vm.avatarCommonTopic}}),_c('i',{staticClass:"far fa-clock text-primary fs-20 fs-xl-25 ps-1"})]):_vm._e()]),_c('div',{staticClass:"pe-3 py-2 w-100 cursor-pointer",class:{
              'd-xl-flex justify-content-xl-between py-xl-4':
                _vm.favoriteFrom !== _vm.eFrom.NAVBAR
            }},[_c('router-link',{staticClass:"content pre-wrap-all pe-13 mb-1",class:{
                'w-xl-50': _vm.favoriteFrom !== _vm.eFrom.NAVBAR
              },attrs:{"to":_vm.goToNotificationDetail(favorite)}},[_c('div',{staticClass:"text-line-clamp-1 fwb"},[(favorite.favorite_target_type === _vm.eFavoriteType.CHAT)?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleChatTitle(favorite)),expression:"handleChatTitle(favorite)",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(_vm.handleChatTitle(favorite)))+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(favorite.title),expression:"favorite.title",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(_vm.handleLongText(favorite.title))+" ")])]),(
                  favorite.favorite_target_type ===
                    _vm.eFavoriteType.GROUP_TOPIC ||
                    favorite.favorite_target_type ===
                      _vm.eFavoriteType.COMMON_TOPIC ||
                    favorite.favorite_target_type === _vm.eFavoriteType.CHAT
                )?_c('div',{staticClass:"overflow-hidden h--8",class:{
                  'text-secondary-tint-less':
                    !favorite.content ||
                    favorite.content.includes(_vm.ATTACH_FILE) ||
                    favorite.content.includes(_vm.MESSAGE_DELETED) ||
                    favorite.content.includes(_vm.LEAVE_GROUP_CHAT) ||
                    favorite.content.includes(_vm.LEAVE_SYSTEM),
                  'all-child-reset chat':
                    favorite.favorite_target_type === _vm.eFavoriteType.CHAT
                }},[_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset",domProps:{"innerHTML":_vm._s(_vm.handleContentTextNull(favorite))}})]):_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 overflow-hidden h--8"},[(favorite.content)?_c('span',{staticClass:"text-line-clamp-1",domProps:{"innerHTML":_vm._s(favorite.content)}}):_c('span',{staticClass:"text-secondary-tint-less"},[_vm._v(" "+_vm._s(_vm.handleContentTextNull(favorite))+" ")])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[_c('router-link',{staticClass:"d-flex align-items-center flex-1 h-100",class:{
                  'justify-content-xl-between': _vm.favoriteFrom !== _vm.eFrom.NAVBAR
                },attrs:{"to":_vm.goToNotificationDetail(favorite)}},[(favorite.allow_anonymous_comment)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('groups.new_feed.anonymous'))+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleUpdater(favorite).name),expression:"handleUpdater(favorite).name",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1",class:{
                    'text-secondary-tint-less':
                      favorite.user_active_status &&
                      !favorite.content.includes(_vm.ATTACH_FILE) &&
                      !favorite.content.includes(_vm.MESSAGE_DELETED) &&
                      !favorite.content.includes(_vm.LEAVE_GROUP_CHAT) &&
                      !favorite.content.includes(_vm.LEAVE_SYSTEM)
                  }},[_vm._v(" "+_vm._s(_vm.handleUpdater(favorite).text)+" ")]),_c('span',{staticClass:"flex-fixed px-3"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(favorite.updated_date))+" ")])]),_c('div',{staticClass:"minw--30"},[(!favorite.is_read)?_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(favorite, index)}]}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")]):_vm._e()])],1)],1)]),(
            index !== _vm.favorites.length - 1 && _vm.favoriteFrom !== _vm.eFrom.NAVBAR
          )?_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary"}):_vm._e()])}),(_vm.favoriteFrom !== _vm.eFrom.NAVBAR && _vm.page < _vm.maxPage)?_c('div',{staticClass:"text-center py-4 border-top-xl border-secondary mx-xl-4"},[_c('button',{staticClass:"btn btn-outline-secondary-deep",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])]):_vm._e(),(!_vm.favorites.length && !_vm.startLoading)?_c('div',{staticClass:"text-secondary text-center fs-12 fs-xl-15 py-8"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_favorite'))+" ")]):_vm._e()],2)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }