








import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Banner extends Vue {
  private banner_header_background: Object = {}
  private showOff: boolean = false

  @Watch(
    '$store.state.setting.setting.setting_themes.theme.banner_header_bg_color'
  )
  watchBannerColorChange() {
    this.setTheme()
  }

  @Watch(
    '$store.state.setting.setting.setting_themes.theme.banner_header_bg_img'
  )
  watchBannerImgChange() {
    this.setTheme()
  }

  created() {
    this.setTheme()
  }

  public setTheme() {
    this.showOff =
      this.$store.state.setting.setting.setting_themes.theme
        .banner_header_bg_color ||
      this.$store.state.setting.setting.setting_themes.theme
        .banner_header_bg_img

    this.banner_header_background = this.$store.state.setting.setting
      .setting_themes.theme.banner_header_bg_color
      ? {
          backgroundColor:
            this.$store.state.setting.setting.setting_themes.theme
              .banner_header_bg_color + ' !important'
        }
      : {
          backgroundImage:
            'url(' +
            this.$store.state.setting.setting.setting_themes.theme
              .banner_header_bg_img +
            ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
  }
}
