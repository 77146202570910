



























import { Component, Vue, Watch } from 'vue-property-decorator'
import { ITabModel } from '@/models'

@Component
export default class GroupMainNav extends Vue {
  private tabs: ITabModel[] = []

  created() {
    this.initTab()
  }

  @Watch('$route.params.groupId')
  initTab() {
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t('groups.new_feed.title')),
        routerName: 'group-new-feed',
        routerLink: `/group/${this.$route.params.groupId}/new-feed`
      },
      {
        id: 1,
        displayName: String(this.$t('groups.shared_folder.title')),
        routerName: 'group-shared-folder',
        routerLink: `/group/${this.$route.params.groupId}/shared-folder`
      },
      {
        id: 2,
        displayName: String(this.$t('groups.member_list.title')),
        routerName: 'group-members',
        routerLink: `/group/${this.$route.params.groupId}/members`
      },
      {
        id: 3,
        displayName: String(this.$t('groups.setting.title')),
        routerName: 'group-setting',
        routerLink: `/group/${this.$route.params.groupId}/setting`
      }
    ]
  }
}
