export enum EFavoriteType {
  COMMON_TOPIC = 1,
  COMMON_FILE = 2,
  CALENDAR = 3,
  CHAT = 4,
  GROUP_TOPIC = 5,
  GROUP_FILE = 6,
}

export enum EFrom {
  HOME,
  NAVBAR,
  LIST
}

export enum EUnreadType {
  GROUP_TOPIC = 1,
  GROUP_TOPIC_COMMENT = 2,
  GROUP_SHARED_FOLDER = 3,
  // GROUP_JOIN_REQUEST = 4,
  // GROUP_INVITED = 5,
  COMMON_TOPIC = 6,
  COMMON_TOPIC_COMMENT = 7,
  COMMON_SHARED_FOLDER = 8,
  CHAT_CONTENT = 9
}
