
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { EFrom, IUnreadNotification, UnreadNoti } from '@/models/Notification'
import NotificationService from '@/services/NotificationService'
import UnreadNotificationCommon from '@/components/UnreadNotification.vue'
import {
  getUnreadCount,
  getFavouriteCount
} from '@/helpers/GetNotificationCount'
import { unreadNotificationRef } from '@/firebase'

@Component({
  components: {
    UnreadNotificationCommon
  }
})
export default class UnreadNotification extends Vue {
  @Prop() private unreadFrom!: EFrom
  @Prop() private toggleUnread!: boolean
  @Prop() private triggerUnread!: boolean
  private startLoading: boolean = true
  private unreadNotifications: IUnreadNotification[] = []
  private page: number = 1
  private unsubscribe: any

  // @Watch('$store.state.notification.isChangeNotificationUnread')
  // watchChangeNotificationUnread() {
  //   this.getNotificationUnread()
  // }

  @Watch('toggleUnread')
  watchChangeNotificationUnread() {
    if (this.triggerUnread) this.getNotificationUnread()
  }

  created() {
    // this.getNotificationUnread()

    // firebase get change unread notification
    this.handleRealtime()
  }

  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = unreadNotificationRef
      .where('userIds', 'array-contains', userId)
      .where('createdAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          // this.getNotificationUnread()
          getUnreadCount()
        }
      })
  }

  getNotificationUnread() {
    let limit = this.$store.state.setting.setting.setting_display
      .number_item_lists

    NotificationService.getNotificationUnread(limit, this.page)
      .then(res => {
        if (res.status === 200) {
          this.unreadNotifications = res.data.data.map(
            (item: any) => new UnreadNoti(item)
          )
          if (this.unreadNotifications.length) {
            this.$emit('diableSeeMoreUnreadNavHeader', false)
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.startLoading = false))
  }

  marUnreadNotificationIds(notificationIds: any) {
    this.$blockui.show()
    NotificationService.markReadedNotification(notificationIds)
      .then(res => {
        if (res.status === 200) {
          getUnreadCount()
          getFavouriteCount()
          notificationIds.notifications.forEach(
            (element: any) =>
              (this.unreadNotifications = this.unreadNotifications.filter(
                item => item.id !== element
              ))
          )
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => this.$blockui.hide())
  }

  hideDropdownUnread() {
    this.$emit('hideDropdownUnread')
  }

  goToUnread() {
    if (this.$route.name === 'unread') {
      this.$emit('hideDropdownUnread')
    } else {
      this.$router.push({ name: 'unread' })
    }
  }
}
