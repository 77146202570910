import Const from '../constants'
import axiosApiInstance from '@/interceptors'
import { EFolderType, MoveToUnclsFrom, TopicCommentGetParams, TopicGetParams, TopicsToFolderParams } from '@/models'

class GroupTopicService {

  getAllFolder(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/folder/get-all-folder/${EFolderType.TOPIC}`)
  }

  getAllTopicNotDelete(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/topics/get-not-delete-topics?unread_only=${params.unread_only}&orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getAllDraftTopic(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/topics/get-feature-topics?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getAllTopicUnclassified(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/topics/get-unclassification-topics?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getAllTopicDeleted(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/topics/get-deleted-topics?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getAllTopicByFolder(params: TopicGetParams) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${params.group_id}/topics/get-topics-by-folder/${params.folder_id}?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&limit=${params.limit}&page=${params.page}`)
  }

  getTopicById(groupId: string, topicId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/topics/get-topic-detail/${topicId}`)
  }

  getAllCommentByTopicId(params: TopicCommentGetParams) {
    let url = `${Const.api_url}members/me/groups/${params.group_id}/topics/get-all-comment-by-topic-id/${params.topic_id}?orderBy=${params.order_by}&sortedBy=${params.sorted_by}&is_commenter_hidden=${params.is_commenter_hidden}&limit=${params.limit_comment}`
    url = params.comment_id ? url + `&find_page_by_comment_id=${params.comment_id}` : url + `&page=${params.page}`
    return axiosApiInstance.get(url)
  }

  getCommentById(groupId: string, commentId: number) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/topics/comment-detail/${commentId}`)
  }

  getTopicFolderItemCount(groupId: string) {
    return axiosApiInstance.get(`${Const.api_url}members/me/groups/${groupId}/topics/count-topic`)
  }

  createNewTopic(groupId: string, formData: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/new-topic`, formData)
  }

  updateGroupTopic(groupId: string, topicId: string, formData: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/update-topic/${topicId}`, formData)
  }

  markAsRead(groupId: string, topicId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/set-topic-is-seen/${topicId}`)
  }

  markAsFavorite(groupId: string, topicId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/new-topic-mark/${topicId}`)
  }

  markAsLike(groupId: string, topicId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/like-or-dislike-topic/${topicId}`)
  }

  markAsLikeComment(groupId: string, topicId: string, commentId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/${topicId}/like-or-dislike-comment/${commentId}`)
  }

  addNewFolder(groupId: string, folderName: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/folder/create-new-folder`, { type: EFolderType.TOPIC, folder_name: folderName })
  }

  moveTopicsToUnclassified(groupId: string, topicIds: number[], from: MoveToUnclsFrom) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/move-topics-to-unclassified`, { topic_ids: topicIds, from: from })
  }

  moveTopicsToFolder(params: TopicsToFolderParams) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${params.group_id}/topics/move-topics-to-selected-folder`, params)
  }

  moveTopicsToBin(groupId: string, topicIds: number[]) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/move-topics-to-bin`, { topic_ids: topicIds })
  }

  deleteTopicsInBin(groupId: string, topicIds: number[]) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/delete-topics-in-bin`, { topic_ids: topicIds })
  }

  emptyBin(groupId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/empty-topics-bin`, { group_id: groupId })
  }

  addNewComment(groupId: string, params: FormData) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/add-new-comment`, params)
  }

  deleteComment(groupId: string, commentId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/delete-comment-topic/${commentId}`)
  }

  deleteTopic(groupId: string, topicId: number) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/delete-topic/${topicId}`)
  }

  checkTopicLockByOther(groupId: string, topicId: string) {
    return axiosApiInstance.post(`${Const.api_url}members/me/groups/${groupId}/topics/check-lock-topic/${topicId}`)
  }

  unlockEditTopic(groupId: string, topicId: string) {
    return axiosApiInstance.put(`${Const.api_url}members/me/groups/${groupId}/topics/unlock-topic/${topicId}`)
  }
}

export default new GroupTopicService
