

















import { Prop, Component, Vue } from 'vue-property-decorator'
import PickMenuList from '@/components/PickMenu/PickMenuList.vue'
import { PickMenuItem } from '@/models/Common'

import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import PickMenuModule from '@/store/modules/PickMenu'

const CommonModule = getModule(PickMenuModule, store)

@Component({ components: { PickMenuList } })
export default class PickMenu extends Vue {
  @Prop() private listMenu!: PickMenuItem[]
  @Prop() private maxHeightClass?: string

  closeMenu() {
    CommonModule.CLOSE_ALL()
  }
}
