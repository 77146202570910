
















import { Component, Vue, Watch } from 'vue-property-decorator'
import CheckDevice from '@/helpers/CheckDevice'
import BlockUI from '@/components/BlockUI.vue'
import AuthService from './services/AuthService'
import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Auth from '@/store/modules/Auth'
import i18n from '@/lang'
import { firebaseFCM } from '@/firebase'
import router from './router'
const AuthModule = getModule(Auth, store)
const PUSH_NOTIFICATION_NOT_IS_SEEN = 0;

@Component({ components: { BlockUI } })
export default class App extends Vue {
  public isMobile = false

  beforeRouteEnter(to: any, from: any, next: any) {
    this.manageLoginSession()
  }

  created() {
    this.$root.$on('bv::modal::show', (bvEvent: any, modalId: string) => {
      const modalOpenCount: number = Number(
        document.body.getAttribute('data-modal-open-count')
      )
      // ModalError need `error` on them id
      if (modalOpenCount !== 0 && modalId.includes('error')) {
        bvEvent.preventDefault()
      }
    })
    this.manageLoginSession()

    // Register service work
    if ('serviceWorker' in navigator) {
      const registerSW = async () => {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js')
        console.log(registration);
        
        return registration
      }
      registerSW()
    }
    console.log('Notification.permission', Notification.permission);
    const messaging = firebaseFCM.messaging()
    messaging.onMessage((payload: any) => {
      console.log('message', payload);
      if (payload.data.isRead == PUSH_NOTIFICATION_NOT_IS_SEEN) {
        const notificationPush = new Notification(payload.data.title, {
          body: payload.data.body,
          icon: require('@/assets/images/logo-notification.png')
        })
        notificationPush.addEventListener('click', function (event) {
          router.push(payload.data.redirect_to)
        })
      }          
    })
  }

  @Watch('$route.name')
  watchChange() {
    if (this.$store.state.auth.token) {
      const messaging = firebaseFCM.messaging()
      if (Notification.permission === 'granted') {
        messaging.getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VALID_KEY }).then((token: any) => {
          if (token) {
            // Send the token to your server and update the UI if necessary
            if (token != sessionStorage.getItem('device_token')) {
              var params = {
                token: token
              }
              AuthService.updateDeviceToken(params)
                .then(response => {
                  sessionStorage.setItem('device_token', token)
                  localStorage.setItem('device_token', token)
                  localStorage.setItem('user_logged_id', this.$store.state.userInfo.user.info.id)
                  console.log('token updated', response)
                })
                .catch((error) => {
                  console.error(`Error! ${error}`);
                })
            }
          }
        })
      }
    }
  }

  async logout() {
    var params = {
      token: sessionStorage.getItem('device_token')
    }
    if (params.token) {
      setTimeout(() => {
        AuthService.resetTotalNotification(params)
          .then(async (response) => {
            if (response.status === 200) {
              console.log('response.message: ', response.data.message);
              
              await AuthService.logout(params)
                .then(res => {
                  AuthModule.SET_TOKEN_NULL()
                  i18n.locale = 'ja'
                  if (res.status === 200) {
                    this.$router.push({
                      name:
                        this.$store.state.auth.loginFromLoginByEmail === true
                          ? 'email-login'
                          : 'login'
                    })
                    sessionStorage.clear()
                    localStorage.clear()
                  }
                })
                .catch(err => {
                  if (err) {
                    this.$router.push({ name: 'home' })
                  }
                })
            }
        })
      }, 3000)
    } else {
      await AuthService.logout(params)
        .then(res => {
          AuthModule.SET_TOKEN_NULL()
          i18n.locale = 'ja'
          if (res.status === 200) {
            this.$router.push({
              name:
                this.$store.state.auth.loginFromLoginByEmail === true
                  ? 'email-login'
                  : 'login'
            })
            sessionStorage.clear()
            localStorage.clear()
          }
        })
        .catch(err => {
          if (err) {
            this.$router.push({ name: 'home' })
          }
        })
    }
    // await AuthService.logout(params)
    //   .then(res => {
    //     if (res.status === 200) {
    //       AuthModule.SET_TOKEN_NULL()
    //       i18n.locale = 'ja'
    //       this.$router.push({
    //         name: this.$store.state.auth.loginFromLoginByEmail
    //           ? 'email-login'
    //           : 'login'
    //       })
    //     }
    //   })
    //   .catch(err => {
    //     if (err) {
    //       this.$router.push({ name: 'home' })
    //     }
    //   })
    // sessionStorage.clear()
    // localStorage.clear()
  }

  updated() {
    let appHeight = (this.$refs.app as any).clientHeight
    let windowHeight = window.innerHeight
    this.isMobile = CheckDevice.isMobile() === true ? true : false
    if (appHeight > windowHeight && !this.isMobile) {
      document.body.classList.add('body-scroll-pc')
    } else {
      document.body.classList.remove('body-scroll-pc')
    }
  }

  manageLoginSession() {
    // let totalTabs: number
    // totalTabs = Number(localStorage.getItem('count'))

    const greetCount = (e: any) => {
      var sessionStorage_transfer = (event: any) => {
        if (!event) {
          event = window.event
        } // ie suq
        if (!event.newValue) return // do nothing if no value to work with
        if (event.key == 'getSessionStorage') {
          // another tab asked for the sessionStorage -> send it
          localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
          // the other tab should now have it, so we're done with it.
          setTimeout(() => localStorage.removeItem('sessionStorage'), 100) // <- could do short timeout as well.
        } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
          // another tab sent data <- get it
          var data = JSON.parse(event.newValue)
          for (var key in data) {
            sessionStorage.setItem(key, data[key])
          }
        }
      }

      // listen for changes to localStorage
      window.addEventListener('storage', sessionStorage_transfer, false)

      // Ask other tabs for session storage (this is ONLY to trigger event)
      if (!sessionStorage.length) {
        localStorage.setItem('getSessionStorage', 'foobar')
        localStorage.removeItem('getSessionStorage')
      }

      // //if more than 1 tab - add vuex to sessionStorage
      // if (totalTabs) {
      //   let vuex = localStorage.getItem('vuex')
      //   if (vuex !== null) {
      //     sessionStorage.setItem('vuex', vuex)
      //   }
      // }
      // //handle count
      // if (localStorage.getItem('count') === null) {
      //   totalTabs = 0
      // } else {
      //   totalTabs = Number(localStorage.getItem('count'))
      // }
      // totalTabs++
      // localStorage.setItem('count', '' + totalTabs)
    }

    // const disCount = (e: any) => {
    //   //handle count
    //   if (localStorage.getItem('count') === null) {
    //     totalTabs = 1
    //   } else {
    //     totalTabs = Number(localStorage.getItem('count'))
    //   }
    //   totalTabs--
    //   localStorage.setItem('count', '' + totalTabs)

    //   //if 1 tab left - remove localStorage
    //   if (totalTabs < 1) {
    //     let vuex = localStorage.getItem('vuex')
    //     if (vuex !== null) {
    //       sessionStorage.setItem('vuex', vuex)
    //     }
    //     localStorage.removeItem('vuex')
    //   }
    // }

    if (CheckDevice.isMobile()) {
      window.onpageshow = greetCount
      // window.onpagehide = disCount
    } else {
      window.onload = greetCount
      // window.onbeforeunload = disCount
    }
  }
}
