
import i18n from '@/lang';
import { EUserStatus, IdName } from '.';
import { ETopicType, EFolderType } from './Enum'
//@ts-ignore
import _ from 'lodash'
export class TopicFolder {
  id!: number;
  name: string = '';

  constructor(init?: Partial<TopicFolder>) {
    Object.assign(this, init)
  }
}

export class TopicCompact {
  id!: number;
  update_topic: boolean = false;
  updater_avatar: string = '';
  title: string = '';
  content: string = '';
  updater: any = '';
  update_at: string = '';
  delivery_date: string = '';
  is_read: boolean = false;
  group_avatar: string = '';
  allow_anonymous_comment: boolean = false
  emergency_contact: boolean = false
  user_active_status: EUserStatus = EUserStatus.NORMAL

  constructor(init?: Partial<TopicCompact>) {
    Object.assign(this, init)
    if (this.content) {
      this.content = '<div><span>' + this.content + '</span></div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')
      let el: any = wrapper.firstChild
      if (el) {
        el.innerText = _.truncate(el.innerText, {
          'length': 50,
          'separator': ' '
        });
        el?.classList ? el?.classList.add("text-line-clamp-1") : ''
        this.content = keepFirstElementOnly(wrapper, el, wrapper.childElementCount > 1)
      }
    }
  }
}

export class Topic {
  id!: number;
  creater_name: string = '';
  creater_active_status: EUserStatus = EUserStatus.NORMAL
  creater_id: string = '';
  created_at: string = '1970/12/31';
  updater_name: string = '';
  updater_active_status: EUserStatus = EUserStatus.NORMAL
  updated_at: string = '1970/12/31';
  folder_id!: number;
  folder_name: string = '';
  move_to_bin: boolean = false
  date_remove: string = '';
  liker: TopicLiker[] = [];
  liker_count: number = 0;
  topic_detail: TopicDetails = new TopicDetails();
  is_like: boolean = false;
  is_favor: boolean = false;
  is_in_bin: boolean = false;

  constructor(init?: Partial<Topic>) {
    Object.assign(this, init)
    this.topic_detail = new TopicDetails(init?.topic_detail)
  }
}

export class TopicDetails {
  topic_type: ETopicType = ETopicType.GROUP_TOPIC;
  topic_title: string = '';
  content: string = '';
  attach_file: TopicAttachFile[] = [];
  is_multiple_edit: boolean = false;
  emergency_contact: number = 0;
  how_nice: string[] = []
  hide_liker: boolean = false;
  enable_like: boolean = false;
  like_character_change: string = '';
  comment_options: string[] = ['1']
  enable_comment: boolean = true;
  allow_anonymous_comment: boolean = true;
  delivery_date: string | Date = '';
  selected_group: IdName[] = [];
  hide_to_selected_group_tag: boolean = false;
  total_attach_file: number = 0
  deleted_attach_file: number = 0
  deleted_tiny_src: string[] = []
  last_seen_comment_id!: number

  constructor(init?: Partial<TopicDetails>) {
    Object.assign(this, init)
    this.enable_like = this.how_nice.includes('1')
    this.hide_liker = this.how_nice.includes('2')
    this.enable_comment = this.comment_options.includes('1')
    this.allow_anonymous_comment = this.comment_options.includes('2')
    this.delivery_date = new Date(this.delivery_date)

    //handle content
    if (this.content) {
      this.content = '<div>' + this.content + '</div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')

      this.deleted_tiny_src?.forEach(src => {
        let noAttachFile = document.createElement('div')
        noAttachFile.innerHTML = `<div class='text-secondary-tint-less py-2'>${i18n.t('groups.new_feed.no_attach_file')}</div>`

        const el = wrapper.querySelector(`[src="${src}"]`)
        if (el) el.replaceWith(noAttachFile)
      })
      this.content = wrapper.innerHTML
    }

    //handle new attach_file
    this.attach_file = this.attach_file.filter(item => !this.content?.includes(item.path))
  }
}

export class TopicLiker {
  id!: number;
  liker_name!: string;
  avatar!: string;
  user_active_status!: EUserStatus

  constructor(init?: Partial<TopicLiker>) {
    Object.assign(this, init)
  }
}

export class TopicComment {
  id!: number;
  no: number = 1
  comment_id?: number;
  comment_parent_no?: number;
  comment_parent_delete: boolean = true
  comment_date: string = '';
  content: string = '';
  attach_file: TopicAttachFile[] = [];
  liker: TopicLiker[] = [];
  comments: TopicComment[] = [];
  is_like: boolean = false;
  total_attach_file: number = 0
  deleted_attach_file: number = 0
  deleted_tiny_src: string[] = []

  //commenter
  commenter_id!: number;
  commenter_name: string = '';
  commenter_avatar: string = '';
  user_active_status: EUserStatus = EUserStatus.NORMAL;
  is_deleted: boolean = false;

  constructor(init?: Partial<TopicComment>) {
    Object.assign(this, init)

    //handle content
    if (this.content) {
      this.content = '<div>' + this.content + '</div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')

      this.deleted_tiny_src?.forEach(src => {
        let noAttachFile = document.createElement('div')
        noAttachFile.innerHTML = `<div class='text-secondary-tint-less py-2'>${i18n.t('groups.new_feed.no_attach_file')}</div>`

        const el = wrapper.querySelector(`[src="${src}"]`)
        if (el) el.replaceWith(noAttachFile)
      })
      this.content = wrapper.innerHTML
    }

    //handle new attach_file
    this.attach_file = this.attach_file.filter(item => !this.content?.includes(item.path))
  }
}
export interface TopicAttachFile {
  id: number
  name: string
  extension: string
  path: string
  small_path: string
  size: string
}

export class TopicCommentParams {
  group_id!: string
  topic_id!: number
  comment_id?: number
  content_comment: string = ''
  content_comment_text: string = ''
  attach_file: File[] = []
  tiny_file_ids: number[] = []

  constructor(init?: Partial<TopicCommentParams>) {
    Object.assign(this, init)
  }
}
export interface TopicGetParams {
  group_id?: string,
  order_by: string,
  sorted_by: string,
  page: number,
  limit: number,
  folder_id?: number,
  unread_only?: string
}

export interface TopicCommentGetParams {
  group_id?: string,
  topic_id: string,
  order_by: string,
  sorted_by: string,
  is_commenter_hidden: number,
  page: number,
  limit_comment: number
  comment_id?: string
}

export interface TopicsToFolderParams {
  group_id: string,
  topic_ids: number[],
  folder_id: number
}

export interface RenameFolderParams {
  group_id: string,
  id: number,
  folder_name: string
}

export interface DeleteFolderParams {
  group_id: string,
  folder_id: number,
  type: EFolderType
}

export const keepFirstElementOnly = (wrapper: any, el: any, isLong: boolean) => {
  isLong ? wrapper.firstChild?.appendChild(document.createTextNode('...')) : ''
  let tmp = document.createElement('div')
  el ? tmp.appendChild(el) : ''

  //handle if fisrt el have iframe
  const iframes = el?.querySelectorAll ? el?.querySelectorAll('iframe') : []
  iframes?.forEach((iFrame: any) => {
    iFrame.parentElement.classList.value = 'm-0'
    iFrame.style.width = '40px'
    iFrame.style.height = '30px'
  })

  //handle if el is table
  if (el && el.localName === 'table') {
    el.style.width = '100%'
    el.style.height = '30px'
  }

  return tmp.innerHTML
}
