







import { Component, Vue } from 'vue-property-decorator'
import GroupMemberNav from '@/components/Group/GroupMemberNav.vue'

@Component({ components: { GroupMemberNav } })
export default class LayoutGroupMember extends Vue {}
