import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'theme' })
class Theme extends VuexModule {
  theme_default = {
    theme_name: 'theme default',
    nav_menu_bg_color: '#1971be',
    nav_menu_bg_img: null,
    banner_header_bg_color: null,
    banner_header_bg_img: '',
    main_bg_color: '#FFF',
    main_bg_img: null,
  }
  theme: any = this.theme_default

  @Mutation
  changeTheme(new_theme: any) {
    this.theme.nav_menu_bg_img = new_theme.nav_menu_bg_img
    this.theme.nav_menu_bg_color = new_theme.nav_menu_bg_color
  }

  @Mutation
  changeTitleColor(title_color: any) {
    this.theme.head_block_color = title_color
  }

  @Action({ rawError: true })
  CHANGE_THEME(new_theme: any) {
    this.context.commit('changeTheme', new_theme)
  }

  @Action({ rawError: true })
  CHANGE_TITLE_COLOR(title_color: any) {
    this.context.commit('changeTitleColor', title_color)
  }
}

export default Theme