import { getRoleEnum } from '@/helpers/UpdateGroupUserRole';
import { EGroupAuth } from './Enum';

export class Member {
  id!: number;
  user_permissions: any[] = [];
  user_profile?: UserProfile;
  isAdmin: boolean = false;
  role: EGroupAuth[] = [];

  constructor(init?: Partial<Member>) {
    Object.assign(this, init)
    const roleEnum = this.user_permissions.map(item => getRoleEnum(item.name) as EGroupAuth).filter(item => item !== undefined)
    if (roleEnum.includes(EGroupAuth.ADMIN)) {
      this.role = [EGroupAuth.ADMIN]
      this.isAdmin = true
    } else {
      this.role = roleEnum
    }
  }
}
export class MemberName {
  id!: number;
  name: string = ''
  constructor(init?: Partial<MemberName>) {
    Object.assign(this, init)
  }
}
export class InvitedMember {
  id!: number;
  user_id!: number;
  token: string = '';
  updated_at: string = '';
  expired_date: string = '';
  email?: string;
  user_profile?: UserProfile;

  constructor(init?: Partial<InvitedMember>) {
    Object.assign(this, init)
  }
}
export class JoinMember {
  id!: number;
  user_id!: number;
  updated_at: string = '';
  user_profile!: UserProfile;

  constructor(init?: Partial<JoinMember>) {
    Object.assign(this, init)
  }
}
export class AdminRegisterMember {
  group_id!: string
  employee_number: string = ''
  password: string = ''
  first_name: string = ''
  last_name: string = ''
  first_name_kana: string = ''
  last_name_kana: string = ''

  constructor(init?: Partial<AdminRegisterMember>) {
    Object.assign(this, init)
  }
}
export class RegisterMember {
  token!: string
  type!: number
  password: string = ''
  first_name: string = ''
  last_name: string = ''
  first_name_kana: string = ''
  last_name_kana: string = ''

  constructor(init?: Partial<RegisterMember>) {
    Object.assign(this, init)
  }
}
export class GroupPermission {
  id!: number
  role: string = ''
  name: string = ''

  constructor(init?: Partial<GroupPermission>) {
    Object.assign(this, init)
  }
}
export interface UserProfile {
  user_id: number,
  first_name: string,
  last_name: string,
  avatar: string
  avatar_small: string
}
export interface InviteParams {
  group_id: number,
  mails?: string[],
  ids?: number[],
  title: string,
  content: string,
  invite_link: string
}
export interface IUpdateRoleParams {
  group_id: number,
  member_roles: IMemberRole[]
}
export interface IMemberRole {
  user_id: number,
  permission_ids: number[]
}
export interface RequestJoinParams {
  user_id: number,
  group_link: string,
  not_check_belong_group: boolean
}
export interface RequestToJoinParams {
  id?: number,
  group_link: string
}
export interface RequestJoinGetParams {
  group_link: string
  page: number,
  limit: number
}
export interface TempUserParams {
  email: string,
  group_link: string
  link_url: string
}

export interface MemberNameByGroupParams {
  group_id: string,
  group_search_id: number,
  page: number,
  limit: number
}
export interface MemberNameBySearchParams {
  group_id: string,
  name: string,
  page: number,
  limit: number
}
