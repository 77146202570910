
import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class GroupChatService {
  reloadLstGroupChat(limit: number, page: number) {
    return axiosApiInstance.get(`${Const.api_url}group-chat/group-chat-user?orderBy=updated_at&sortedBy=desc&limit=${limit}&page=${page}&isGetUnRead=1`)
  }

  getGroupChatById(userId: number) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/show/' + userId)
  }

  createNewGroupChat(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/create', data)
  }

  getGroupChatExist(data: any) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/check-group-chat-exist?user_ids=' + data)
  }

  updateCheckUserUnread(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/update-check-user-unread', data)
  }

  toggleFavouriteGroupChat(group_chat_id: number) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/mark-favorite/' + group_chat_id)
  }

  removeUserIdInGroupChat(groupChatId: number, data: any) {
    return axiosApiInstance.put(Const.api_url + 'group-chat/update' + groupChatId, data)
  }

  inviteGroupChat(groupChatId: number, data: any) {
    return axiosApiInstance.put(Const.api_url + 'group-chat/update' + groupChatId, data)
  }

  deleteGroupChat(groupChatId: number, data: any) {
    return axiosApiInstance.put(Const.api_url + 'group-chat/destroy' + groupChatId, data)
  }

  getMemberHasGroupTagSameUserLogin(data: any) {
    let url = Const.api_url + 'calendar-events/get-all-member?filter=id;name&user_ids='
      + data.userIds
      + '&search=name:' + data.userName + ';name_kana:' + data.userName
      + '&limit=' + data.limit
      + '&page=' + data.page

    if (data.group_chat_id != undefined) {
      url += '&group_chat_id=' + data.group_chat_id
    }

    return axiosApiInstance.get(url)
  }

  getMemberByGroupId(data: any) {
    let url = `${Const.api_url}members/me/group/${data.groupId}/group-members?user_ids=`
      + data.userIds
      + '&search=name:' + data.userName + ';name_kana:' + data.userName
      + '&limit=' + data.limit
      + '&page=' + data.page

    if (data.group_chat_id != undefined) {
      url += '&group_chat_id=' + data.group_chat_id
    }

    return axiosApiInstance.get(url)
  }

  getUserChated(page: number, limited: number) {
    return axiosApiInstance.post(Const.api_url + 'chat/user-chated?page=' + page + '&limited=' + limited)
  }

  leaveGroupChat(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/leave-group-chat', data)
  }

  revertOriginMember(group_id: number) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/revert-origin-member/' + group_id)
  }

  getGroupChatPrivate(page: number, limit: number) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/group-chat-private?page=' + page + '&limit=' + limit)
  }

  getGalleryByGroup(group_chat_id: number, page: number, limit: number) {
    return axiosApiInstance.get(Const.api_url + 'group-chat-gallery/' + group_chat_id + '?page=' + page + '&limit=' + limit)
  }

  deleteGalleryByGroup(group_chat_id: number, msg_id: number) {
    return axiosApiInstance.delete(Const.api_url + 'group-chat-gallery/' + group_chat_id + '/delete-msg-has-file-upload/' + msg_id)
  }

  uploadFile(group_chat_id: number, data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat-gallery/' + group_chat_id + '/upload-file', data)
  }

  uploadFileByTiny(file: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat-gallery/upload-file-by-tiny', file)
  }

  downloadGallery(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat-gallery/download-files', data, { responseType: 'arraybuffer' })
  }

  getChattedUsers(page: number, limit: number) {
    return axiosApiInstance.get(Const.api_url + 'group-chat/list-chatted-user?page=' + page + '&limit=' + limit)
  }

  inviteMemberToGroupChat(data: any) {
    return axiosApiInstance.post(Const.api_url + 'group-chat/invite-user-to-group-chat', data)
  }

  updateGroupChatName(groupChatId: string, name: string) {
    return axiosApiInstance.post(`${Const.api_url}group-chat/update-group-chat-name/${groupChatId}`, { name: name })
  }
}

export default new GroupChatService
