
export class ShareFolderButtonState {
  moveFolder: State = State.ACTIVE
  addFile: State = State.ACTIVE
  dropDown: State = State.ACTIVE
  throwAway: State = State.ACTIVE
  download: State = State.ACTIVE
  addFolder: State = State.ACTIVE

  constructor(init?: Partial<ShareFolderButtonState>) {
    Object.assign(this, init)
  }
}

export class ShareFolderFileButtonState {
  fileOverwrite: State = State.ACTIVE
  updateInfo: State = State.ACTIVE
  moveFolder: State = State.ACTIVE
  moveToTrash: State = State.ACTIVE

  constructor(init?: Partial<ShareFolderFileButtonState>) {
    Object.assign(this, init)
  }
}

export class ShareFolderAdminButtonState {
  addFile: State = State.ACTIVE
  dropDown: State = State.ACTIVE
  moveFolder: State = State.ACTIVE
  addFolder: State = State.ACTIVE
  displayChangeOrder: State = State.ACTIVE
  moveToTrash: State = State.ACTIVE

  constructor(init?: Partial<ShareFolderAdminButtonState>) {
    Object.assign(this, init)
  }
}

export enum State {
  ACTIVE,
  DISABLE,
  HIDDEN
}