
import Const from '../constants'
import axiosApiInstance from '@/interceptors'

class SettingService {
  getInformation() {
    return axiosApiInstance.get(Const.api_url + 'informations')
  }

  markReadInformation(information_ids: number[]) {
    return axiosApiInstance.post(Const.api_url + 'members/me/informations/read', { information_ids: information_ids })
  }
}

export default new SettingService
