import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ namespaced: true, name: 'calendar' })
class Calendar extends VuexModule {
  calendar_event_unread_count: number = 0

  @Mutation
  setNewCalendarEventCount(count: number) {
    this.calendar_event_unread_count = count
  }

  @Action
  SET_NEW_CALENDAR_EVENT_COUNT(count: number) {
    this.context.commit('setNewCalendarEventCount', count)
  }
}

export default Calendar
