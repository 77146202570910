import { getModule } from 'vuex-module-decorators'
import NotificationService from '@/services/NotificationService'
import CommonTopicService from '@/services/CommonTopicService'
import Notification from '@/store/modules/Notification'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'

const NotificationModule = getModule(Notification, store)
const CommonTopicModule = getModule(CommonTopic, store)

const getUnreadCount = async () => {
  return NotificationService.getNotificationCount(1)
    .then(res => {
      if (res.status === 200) {
        let unreadCount = res.data
        console.log('UPDATE_UNREAD_COUNT', res)
        NotificationModule.UPDATE_UNREAD_COUNT(unreadCount)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const getUnreadCountByGroupId = async (groupId: number) => {
  return NotificationService.getNotificationCountByGroup(groupId)
    .then(res => {
      if (res.status === 200) {
        let unreadCount = res.data
        console.log('CountByGroup', res)
        NotificationModule.UPDATE_UNREAD_COUNT(unreadCount)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const getLikeCount = async () => {
  return NotificationService.getNotificationCount(2)
    .then(res => {
      if (res.status === 200) {
        let likeCount = res.data
        console.log('UPDATE_LIKE_COUNT', res)
        NotificationModule.UPDATE_LIKE_COUNT(likeCount)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const getFavouriteCount = async () => {
  return NotificationService.getFavoriteCount()
    .then(res => {
      if (res.status === 200) {
        let favouriteCount = res.data.count
        console.log('UPDATE_FAVOURITE_COUNT', res)
        NotificationModule.UPDATE_FAVOURITE_COUNT(favouriteCount)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

const triggerFavorite = async () => {
  NotificationModule.TRIGGER_FAVORITE()
}

const getCommonTopicCount = async () => {
  return CommonTopicService.getCommonTopicUnreadCount()
    .then(res => {
      if (res.status === 200) {
        let commomTopicUnreadCount = res.data
        console.log('SET_NEW_COMMON_TOPIC_COUNT', res)
        CommonTopicModule.SET_NEW_COMMON_TOPIC_COUNT(commomTopicUnreadCount)
      }
    })
    .catch(err => {
      console.log(err)
    })
}

export {
  getUnreadCount,
  getLikeCount,
  getFavouriteCount,
  getCommonTopicCount,
  getUnreadCountByGroupId,
  triggerFavorite
}
