




























































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import DatePickerLang from '@/helpers/DatePickerLang'
import moment from 'moment'
import {ETargetData, ETargetSeach, ETimeRange, SearchParams} from '@/models'
import GroupFolderService from '@/services/GroupFolderService'

@Component
export default class ModalSearchHeader extends Vue {
  @Prop({ default: 'modal-search-header' }) private id!: string
  private datePickProp: DatePickerLang = new DatePickerLang(this)
  private targetSeach: any[] = [
    {
      id: 1,
      name: this.$t('search.target_search.topic'),
      value: ETargetSeach.TOPIC
    },
    {
      id: 2,
      name: this.$t('search.target_search.calendar'),
      value: ETargetSeach.CALENDAR
    },
    {
      id: 3,
      name: this.$t('search.target_search.chat'),
      value: ETargetSeach.CHAT
    },
    {
      id: 4,
      name: this.$t('search.target_search.shared_folder'),
      value: ETargetSeach.SHARED_FOLDER
    }
  ]
  private dataSearch: any[] = [
    {
      id: 1,
      name: this.$t('search.target_data.title'),
      value: ETargetData.TITLE,
      disable: false
    },
    {
      id: 2,
      name: this.$t('search.target_data.content'),
      value: ETargetData.CONTENT,
      disable: false
    },
    {
      id: 3,
      name: this.$t('search.target_data.comment'),
      value: ETargetData.COMMENT,
      disable: false
    },
    {
      id: 4,
      name: this.$t('search.target_data.uploader'),
      value: ETargetData.UPLOADER,
      disable: false
    },
    {
      id: 5,
      name: this.$t('search.target_data.sen_to'),
      value: ETargetData.SENT_TO,
      disable: false
    }
  ]
  private timeSearch: any[] = [
    {
      id: 1,
      name: this.$t('search.time_search_range.one_week'),
      value: ETimeRange._1_WEEK
    },
    {
      id: 2,
      name: this.$t('search.time_search_range.one_month'),
      value: ETimeRange._1_MONTH
    },
    {
      id: 3,
      name: this.$t('search.time_search_range.three_month'),
      value: ETimeRange._3_MONTH
    },
    {
      id: 4,
      name: this.$t('search.time_search_range.six_month'),
      value: ETimeRange._6_MONTH
    },
    {
      id: 5,
      name: this.$t('search.time_search_range.one_year'),
      value: ETimeRange._1_YEAR
    },
    {
      id: 6,
      name: this.$t('search.time_search_range.select_ranger'),
      value: ETimeRange.CUSTOM
    }
  ]

  private format_date = 'YYYY/MM/DD'
  private groupsUserJoin: any[] = []
  public timeSearchRangeStart: Date | string = ''
  public timeSearchRangeEnd: Date | string = ''
  private timeRange: ETimeRange = ETimeRange._1_WEEK
  private eTimeRange = ETimeRange
  private searchData: SearchParams = {
    target_search: '',
    target_data: [],
    target_data_string: '',
    target_group: '',
    target_group_name: this.$t('groups.common_topic') as string,
    keyword_search: '',
    time_range: ETimeRange._1_WEEK,
    time_start: moment()
        .subtract(1, 'week')
        .format(this.format_date),
    time_end: moment().format(this.format_date),
    modal_change: String(Math.random())
  }
  private targetGroupName: string = this.$t('groups.common_topic') as string
  private disableGroupSelect: boolean = false

  @Watch('searchData.target_search')
  watchTargetSearch() {
    this.disableGroupSelect =
      Number(this.searchData.target_search) === ETargetSeach.CHAT ||
      Number(this.searchData.target_search) === ETargetSeach.CALENDAR
    this.checkDisableTitle()
  }

  @Watch('searchData.target_data')
  watchTargetData() {
    this.searchData.target_data_string = this.searchData.target_data.length
        ? this.searchData.target_data.toString()
        : this.dataSearch
            .filter(item => !item.disable)
            .map(item => item.value)
            .toString()
  }

  @Watch('timeRange')
  watchTimeRange() {
    this.timeSearchRangeStart = ''
    this.timeSearchRangeEnd = ''
    this.searchData.time_range = this.timeRange
    if (this.timeRange != ETimeRange.CUSTOM) {
      this.handleTimeRange()
    }
  }

  @Watch('timeSearchRangeStart')
  watchTimeRangeStart() {
    if (this.timeRange == ETimeRange.CUSTOM) {
      this.searchData.time_start = moment(this.timeSearchRangeStart).format(
          this.format_date
      )
    }
  }

  @Watch('timeSearchRangeEnd')
  watchTimeRangeEnd() {
    if (this.timeRange == ETimeRange.CUSTOM) {
      this.searchData.time_end = moment(this.timeSearchRangeEnd).format(
          this.format_date
      )
    }
  }

  @Watch('$route.query')
  watchRouterQuery() {
    // if (!this.$route.query) return
    const routerQuery = this.$route.query as any
    this.searchData = {
      target_search: routerQuery.target_search || this.searchData.target_search,
      target_data: routerQuery.target_data_string
        ? routerQuery.target_data_string.split(',')
        : this.searchData.target_data,
      target_data_string: routerQuery.target_data_string || this.searchData.target_data_string,
      target_group: routerQuery.target_group || this.searchData.target_group,
      target_group_name: routerQuery.target_group_name || this.searchData.target_group_name,
      keyword_search: routerQuery.keyword_search || this.searchData.keyword_search,
      time_range: routerQuery.time_range || this.searchData.time_range,
      time_start: routerQuery.time_start || this.searchData.time_start,
      time_end: routerQuery.time_end || this.searchData.time_end,
      modal_change: String(Math.random())
    }
    this.targetGroupName = routerQuery.target_group_name
      ? routerQuery.target_group_name
      : (this.$t('groups.common_topic') as string)
    //handle time
    this.timeRange = routerQuery.time_range
      ? routerQuery.time_range
      : ETimeRange._1_WEEK
    if (routerQuery.time_range === ETimeRange.CUSTOM) {
      this.timeSearchRangeStart = moment(routerQuery.time_start).toDate()
      this.timeSearchRangeEnd = moment(routerQuery.time_end).toDate()
    }
  }

  created() {
    this.getAllGroupUserJoin()
    this.watchRouterQuery()
  }

  checkDisableTitle() {
    const targetTitle = this.dataSearch.find(
      (item: any) => item.value === ETargetData.TITLE
    )
    const targetComment = this.dataSearch.find(
      (item: any) => item.value === ETargetData.COMMENT
    )
    const targetContent = this.dataSearch.find(
      (item: any) => item.value === ETargetData.CONTENT
    )

    // disable title
    if (Number(this.searchData.target_search) === ETargetSeach.CHAT) {
      targetTitle.disable = true
      this.searchData.target_data = this.searchData.target_data.filter(
        (item: string) => item != targetTitle.value
      )
    } else {
      targetTitle.disable = false
    }

    // disable comment
    if (
      Number(this.searchData.target_search) !== ETargetSeach.TOPIC &&
      Number(this.searchData.target_search) !== ETargetSeach.SHARED_FOLDER
    ) {
      targetComment.disable = true
      this.searchData.target_data = this.searchData.target_data.filter(
        (item: any) => item != targetComment.value
      )
    } else {
      targetComment.disable = false
    }

    // disable content
    if (Number(this.searchData.target_search) === ETargetSeach.SHARED_FOLDER) {
      targetContent.disable = true
      this.searchData.target_data = this.searchData.target_data.filter(
        (item: any) => item != targetContent.value
      )
    } else {
      targetContent.disable = false
    }
  }

  getAllGroupUserJoin() {
    GroupFolderService.getGroupByUderSorted()
      .then(res => {
        if (res.status === 200) {
          const defaultValue = { id: '', name: this.$t('groups.common_topic') }
          this.groupsUserJoin = [defaultValue, ...res.data]
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleSelectGroup(index: number) {
    const selectedGroup = this.groupsUserJoin[index]
    this.searchData.target_group = selectedGroup.id
    this.searchData.target_group_name = selectedGroup.name
    this.targetGroupName = selectedGroup.name
  }

  /**
   * Validation
   *
   * Validate min date
   */
  dateFormat(format?: string) {
    return format ? moment().format(format) : moment().format('YYYY-MM-DD')
  }

  stringToDate(date: Date | string, addDate: number = 0) {
    return date
      ? moment(date)
          .add(addDate, 'day')
          .format('YYYY-MM-DD')
      : moment()
          .add(addDate, 'day')
          .format('YYYY-MM-DD')
  }

  jumptoSearchResultPage() {
    this.handleTimeRange()
    this.searchData.keyword_search = this.searchData.keyword_search.trim()
    this.searchData.target_data_string = this.searchData.target_data.length
      ? this.searchData.target_data.toString()
      : this.dataSearch
          .filter(item => !item.disable)
          .map(item => item.value)
          .toString()

    if (this.$route.name === 'search-result') {
      this.$router
        .push({
          name: 'search-result',
          query: this.searchData as any,
          params: { research: String(Math.random()) }
        })
        .then(() => {
          this.$bvModal.hide(this.id)
        })
        .catch(() => this.$bvModal.hide(this.id))
    } else {
      this.$router
        .push({
          name: 'search-result',
          query: this.searchData as any
        })
        .then(() => {
          this.$bvModal.hide(this.id)
        })
        .catch(() => this.$bvModal.hide(this.id))
    }
  }

  handleTimeRange() {
    const format = 'YYYY/MM/DD'
    this.searchData.time_range = Number(this.timeRange)
    switch (this.searchData.time_range) {
      case ETimeRange._1_WEEK:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment()
          .subtract(1, 'week')
          .format(format)
        break
      case ETimeRange._1_MONTH:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment()
          .subtract(1, 'month')
          .format(format)
        break
      case ETimeRange._3_MONTH:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment()
          .subtract(3, 'month')
          .format(format)
        break
      case ETimeRange._6_MONTH:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment()
          .subtract(6, 'month')
          .format(format)
        break
      case ETimeRange._1_YEAR:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment()
          .subtract(1, 'year')
          .format(format)
        break
      case ETimeRange.CUSTOM:
        this.searchData.time_end = moment(this.timeSearchRangeEnd).format(
          format
        )
        this.searchData.time_start = moment(this.timeSearchRangeStart).format(
          format
        )
        break
      default:
        this.searchData.time_end = moment().format(format)
        this.searchData.time_start = moment().format(format)
    }
  }
}
