import { EUserStatus } from '..'

export class EventCalendarModel {
  id?: number
  plan_type: EPlanType = EPlanType.NORMAL
  repeat_type: ERepeatType = ERepeatType.DAILY
  every_week_day: any = undefined
  every_month_day: any = undefined
  deadline_type: EDeadLineType = EDeadLineType.INDEFINITE
  end_deadline: any = ''
  event_start_date: any = ''
  event_end_date: any = ''
  title: string = ''
  memo: string = ''
  event_color: string = '#FFFFFF'
  share_type: EShareType = EShareType.GROUP_TAG
  created_by?: number
  accept_member_change_plan: boolean = false
  user_creator: IdNameStatus = new IdNameStatus()
  user_updater: IdNameStatus = new IdNameStatus()

  constructor(init?: Partial<EventCalendarModel>) {
    Object.assign(this, init)
  }
}


export class IdNameStatus {
  id!: number
  name: string = ''
  user_active_status: EUserStatus = EUserStatus.NORMAL

  constructor(init?: Partial<IdNameStatus>) {
    Object.assign(this, init)
  }
}


export enum EPlanType {
  NORMAL = 1,
  DURRATION = 2,
  REPEAT = 3
}

export enum ERepeatType {
  DAILY = 1,
  DAILY_NO_WEEKEND = 2,
  WEEKLY = 3,
  MONTHLY = 4
}

export enum EDeadLineType {
  INDEFINITE = 1,
  TIME_LIMIT = 2
}

export enum EShareType {
  GROUP_TAG = 1,
  MEMBER_CHOOSE = 2
}

export enum EIconType {
  WARNING = 1,
  DURATION = 2,
  REPEAT = 3
}
