







import { Component, Vue } from 'vue-property-decorator'
import GroupSettingNav from '@/components/Group/GroupSettingNav.vue'

@Component({ components: { GroupSettingNav } })
export default class SettingPage extends Vue {}
