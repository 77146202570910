










import { Component, Vue } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'

@Component({
  components: {
    HeaderCompact,
    NavMenuBar
  }
})
export default class LayoutHelpCenter extends Vue {}
