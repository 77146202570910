




















import { Component, Vue } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
import SettingMenu from '@/components/SettingMenu.vue'
import Footer from '@/components/Footer.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import MainContent from '@/components/MainContent.vue'

@Component({
  components: {
    HeaderCompact,
    SettingMenu,
    Footer,
    NavMenuBar,
    MainContent
  }
})
export default class LayoutSetting extends Vue {}
