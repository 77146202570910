



















































import { Component, Vue, Watch } from 'vue-property-decorator'
import PickMenu from '@/components/PickMenu/index.vue'
//@ts-ignore
import { QrcodeStream } from 'vue-qrcode-reader'
//@ts-ignore
import _, { DebouncedFunc } from 'lodash'
//@ts-ignore
import { toHiragana, toKatakana } from 'wanakana'
import { PickMenuItem, PickMenuItemChild, GroupOrder } from '@/models'
import CheckDevice from '@/helpers/CheckDevice'
import GroupService from '@/services/GroupService'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import { EventBus } from '@/components/PickMenu/PickMenuList.vue'
import { DEBOUNCE_INPUT, GROUP_LIMIT } from '@/constants'

const CommonTopicModule = getModule(CommonTopic, store)

@Component({ components: { PickMenu, QrcodeStream } })
export default class GroupMenu extends Vue {
  private groups: PickMenuItem[] = []
  private displayGroups: PickMenuItem[] = []
  private camera: string = 'auto'
  private result: string = ''
  private lastResult: string = ''
  private infoMess: string = ''
  private filterGroup: string = ''
  private page: number = 1
  private groupData: GroupOrder[] = []
  private isLastPage: boolean = false
  private debouncedUpdateSearch: DebouncedFunc<(event: any) => void>

  created() {
    this.getGroups()
    EventBus.$on('fetchMoreGroups', this.fetchMoreGroups)
    this.debouncedUpdateSearch = _.debounce(this.onUpdateSearch, DEBOUNCE_INPUT)
  }

  beforeDestroy() {
    EventBus.$off('fetchMoreGroups', this.fetchMoreGroups)
    this.debouncedUpdateSearch.cancel()
  }

  onUpdateSearch(event: any) {
    this.filterGroup = event.target.value
    this.page = 1
    this.groupData = []
    this.getGroups()
  }

  fetchMoreGroups() {
    if (!this.isLastPage) {
      this.page++
      this.getGroups()
    }
  }

  /**
   * Get all group compact
   */
  // @Watch('$store.state.commonTopic.reloadUnread')
  getGroups() {
    EventBus.$emit('loadingGroups', true)
    GroupService.getGroupOrder(this.page, GROUP_LIMIT, this.filterGroup)
      .then(res => {
        if (res.status === 200) {
          if (this.filterGroup) {
            this.groupData = res.data.data
          } else {
            this.groupData = [...this.groupData, ...res.data.data]
          }
          this.isLastPage = this.page >= res.data.last_page
          const pickMenuItems = [
            new PickMenuItem({
              id: 'groupMenu',
              name: String(this.$t('groups.group')),
              alwaysCollapseVisible: true,
              childs: this.groupData.map((group: GroupOrder) => {
                let count =
                  group.unread_count < 100 ? group.unread_count : '+99'
                let childItem = new PickMenuItemChild({
                  id: String(group.id),
                  name: group.name,
                  link: `/group/${group.id}`
                })
                if (group.unread_count > 0 || !group.can_notify) {
                  const badge = group.can_notify
                    ? `<div class="badge rounded-circle bg-danger flex-center h--6 w--6">${count}</div>`
                    : `<div class="badge rounded-circle bg-secondary flex-center h--6 w--6 text-secondary">_</div>`
                  childItem.nameHtml = `
                    <div class="d-flex align-items-center justify-content-between w-100 h-100">
                      <div v-b-tooltip.hover title="${group.name}" class="text-line-clamp-1">${group.name}</div>
                      <div class="flex-fixed ps-3">${badge}</div>
                    </div>`
                }
                return childItem
              }),
              isLastPage: this.isLastPage
            })
          ]
          this.groups = _.cloneDeep(pickMenuItems)
          this.displayGroups = pickMenuItems
          if (CheckDevice.isMobile()) {
            this.groups.push(
              new PickMenuItem({
                id: 'QRScanMenu',
                name: String(this.$t('groups.qr_scan')),
                action: () => this.$bvModal.show('qr-scan-modal'),
                lastItemSticky: true
              })
            )
            this.displayGroups.push(
              new PickMenuItem({
                id: 'QRScanMenu',
                name: String(this.$t('groups.qr_scan')),
                action: () => this.$bvModal.show('qr-scan-modal'),
                lastItemSticky: true
              })
            )
          }

          //handle rebind unred count
          CommonTopicModule.SET_RELOAD_UNREAD(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => EventBus.$emit('loadingGroups', false))
  }

  /**
   * Init QR scan
   */
  async onInit(promise: any) {
    try {
      await promise
    } catch (err) {
      const error = err as any
      if (error.name === 'NotAllowedError') {
        this.openInfoModal(this.$t('common.message.not_allowed_error'))
      } else if (error.name === 'NotFoundError') {
        this.openInfoModal(this.$t('common.message.not_found_error'))
      } else if (error.name === 'NotSupportedError') {
        this.openInfoModal(this.$t('common.message.not_supported_error'))
      } else if (error.name === 'NotReadableError') {
        this.openInfoModal(this.$t('common.message.not_readable_error'))
      } else if (error.name === 'OverconstrainedError') {
        this.openInfoModal(this.$t('common.message.overconstrained_error'))
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.openInfoModal(
          this.$t('common.message.stream_api_not_supported_error')
        )
      }
    }
  }

  /**
   * Open info modal
   */
  openInfoModal(mess: any) {
    this.infoMess = mess as string
    this.$bvModal.show('camera-info-mess')
  }

  goToResult() {
    this.$bvModal.hide('qr-scan-modal')
    window.location.replace(this.lastResult)
  }

  /**
   * Hanlde decode QR
   */
  async onDecode(content: string) {
    this.result = content
    this.lastResult = content
  }

  /**
   * Paint outline for QR
   */
  paintOutline(detectedCodes: any, ctx: CanvasRenderingContext2D) {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

      ctx.lineWidth = 3
      ctx.strokeStyle = '#ffda54'

      ctx.beginPath()
      ctx.moveTo(firstPoint.x, firstPoint.y)
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y)
      }
      ctx.lineTo(firstPoint.x, firstPoint.y)
      ctx.closePath()
      ctx.stroke()
    }
  }

  hideModalQr() {
    this.result = ''
  }
}
