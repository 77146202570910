











import { Component, Vue } from 'vue-property-decorator'
import NewsComponent from '@/components/NewsComponent.vue'

@Component({ components: { NewsComponent } })
export default class News extends Vue {
  private noNews: boolean = false

  handleNoNews(noNews: boolean) {
    this.noNews = noNews
  }
}
