var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GroupHeader',[_c('GroupMainNav'),_c('div',{staticClass:"d-flex flex-column justify-content-between"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:" d-flex flex-column flex-xl-row"},[(
            _vm.$route.name
              ? !(
                  _vm.$route.name.includes('group-members') ||
                  _vm.$route.name.includes('group-setting') ||
                  _vm.$route.name === 'group-new-feed' ||
                  _vm.$route.name === 'group-shared-folder'
                )
              : false
          )?_c('div',{staticClass:"my-xl-5 me-xl-5 flex-fixed"},[_c('NavMenuBar',{staticClass:"d-none d-xl-block"})],1):_vm._e(),_c('router-view',{staticClass:"flex-1"})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }