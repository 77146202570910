






























import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NavCommonTopicCount extends Vue {
  created() {}
}
