var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-screen d-flex flex-column pb-17 pb-xl-0"},[_c('Header'),_c('Banner'),_c('NavMenu'),_c('MainContent',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex flex-column flex-xl-row"},[_c('div',{staticClass:"flex-fixed w-xl--65"},[_c('div',{staticClass:"d-flex flex-column me-xl-5"},[_c('ul',{staticClass:"tabs list-unstyled d-flex bg-secondary-less mb-0 flex-xl-column bg-xl-light p-xl-6 mb-xl-5 border-xl"},[_c('div',{staticClass:"d-none d-xl-flex fs-17 fwb"},[_c('i',{staticClass:"far fa-calendar-alt fs-25 me-2 mb-2"}),_vm._v(" "+_vm._s(_vm.$t('calendar.display.title'))+" ")]),_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.id,staticClass:"flex-1 border-start border-light flex-xl-none",class:{ 'border-start-0': index === 0 }},[_c('router-link',{staticClass:"flex-center h-100 py-3 justify-content-xl-start bg-xl-none hover-bg-xl-none",class:{
                    'bg-secondary-less transition-fast bg-xl-light fw-xl-b': _vm.$route.name
                      ? _vm.$route.name.includes(tab.routerName)
                      : false,
                    'bg-secondary-deep text-light hover-bg-secondary-less-2': _vm.$route.name
                      ? !_vm.$route.name.includes(tab.routerName)
                      : true
                  },attrs:{"to":tab.routerLink}},[(_vm.$route.name && _vm.$route.name.includes(tab.routerName))?_c('div',{staticClass:"text-xl-dark"},[_vm._v(" "+_vm._s(tab.displayName)+" ")]):_c('div',{staticClass:"text-xl-primary hover-o-75"},[_vm._v(" "+_vm._s(tab.displayName)+" ")])])],1)})],2),_c('NavMenuBar',{staticClass:"d-none d-xl-block"})],1)]),_c('div',{staticClass:"flex-1 pt-3 pt-xl-0"},[_c('router-view')],1),_c('NavMenuBar',{staticClass:"d-block d-xl-none"})],1)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }