




























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { GroupDetail, ENameColor, IUserGroupRole } from '@/models'
import GroupService from '@/services/GroupService'
import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'

const UserModule = getModule(UserInfo, store)

@Component
export default class GroupHeader extends Vue {
  private groupInfo: GroupDetail = new GroupDetail()
  private userRole!: IUserGroupRole
  private eNameColor: any = ENameColor
  private noGroupAvatar =
    window.location.origin + require('@/assets/images/group/avatar_default.png')

  created() {
    this.handleCreatedPage()
  }

  @Watch('$route.params.groupId')
  // @Watch('$store.state.userInfo.user.group_role')
  handleCreatedPage() {
    const groupRole: IUserGroupRole = this.$store.state.userInfo.user.group_role
    this.userRole = groupRole
    this.getGroupDetail()
  }

  @Watch('$route.params.updateGroupInfo')
  getGroupDetail() {
    GroupService.getGroupById(this.$route.params.groupId)
      .then(res => {
        if (res.status === 200) {
          this.groupInfo = res.data
          UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(
            res.data.max_img_size_per_attachment
          )
        } else {
          UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(25)
        }
      })
      .catch(err => {
        if (
          err.response.status === 404 &&
          (err.response.data.message === 'GROUP_NOT_FOUND' ||
            err.response.data.message === 'GROUP_IS_STOPPED')
        ) {
          this.$bvModal.show('modal-error-group-not-found-or-deleted') //DO NOT change this modal id. This modal is use in interceptors/idnex.ts too
        } else {
          console.log(err)
        }
        UserModule.UPDATE_CURRENT_GROUP_MAX_FILE_SIZE(25)
      })
  }

  toTopPage() {
    this.$router.push({ name: 'group-toppage' }).catch(err => {})
  }

  jumpToHome() {
    this.$router.push({ name: 'home' }).catch(() => {})
  }
}
