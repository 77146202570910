import i18n from '@/lang/index';
import { ISettingMenu } from '@/models/Setting/Interface';


export const settingData: ISettingMenu[] = [
  {
    id: String(100 * Math.random()),
    link: '/setting/account-info',
    name: i18n.t('setting.setting_menu.account_info')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/setting-language',
    name: i18n.t('setting.setting_menu.setting_language')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/setting-order',
    name: i18n.t('setting.setting_menu.display_order_groups')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/setting-display',
    name: i18n.t('setting.setting_menu.setting_display')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/setting-notify-news',
    name: i18n.t('setting.setting_menu.setting_notify_news')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/setting-theme',
    name: i18n.t('setting.setting_menu.setting_theme')
  },
  {
    id: String(100 * Math.random()),
    link: '/setting/change-info',
    name: i18n.t('setting.setting_menu.change_profile')
  },
]
