



















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import GroupService from '@/services/GroupService'
import NavCommonTopicCount from '@/components/NavCommonTopicCount.vue'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'
import { GroupOrder } from '@/models'
//@ts-ignore
import _, { DebouncedFunc } from 'lodash'
import { DEBOUNCE_INPUT, GROUP_LIMIT } from '@/constants'

const CommonTopicModule = getModule(CommonTopic, store)

@Component({
  components: {
    NavCommonTopicCount
  }
})
export default class NavGroupOrder extends Vue {
  private groupsOrder: GroupOrder[] = []
  private page: number = 1
  private isLastPage: boolean = false
  private filterGroup: string = ''
  private isLoadingGroups: boolean = false
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')

  private debouncedUpdateSearch: DebouncedFunc<(event: any) => void>

  created() {
    this.getGroupOrder()
    this.debouncedUpdateSearch = _.debounce(this.onUpdateSearch, DEBOUNCE_INPUT)
  }

  // Cleanup the debounced function when component is destroyed
  beforeDestroy() {
    this.debouncedUpdateSearch.cancel()
  }

  onUpdateSearch(event: any) {
    this.filterGroup = event.target.value
    this.page = 1
    this.groupsOrder = []
    this.getGroupOrder()
  }

  fetchMoreGroups() {
    if (!this.isLastPage && !this.isLoadingGroups) {
      this.page++
      this.getGroupOrder()
    }
  }

  // @Watch('$store.state.commonTopic.reloadUnread')
  getGroupOrder() {
    this.isLoadingGroups = true
    GroupService.getGroupOrder(this.page, GROUP_LIMIT, this.filterGroup)
      .then(res => {
        if (res.status === 200) {
          if (this.filterGroup) {
            this.groupsOrder = res.data.data
          } else {
            this.groupsOrder = [...this.groupsOrder, ...res.data.data]
          }
          this.isLastPage = this.page >= res.data.last_page
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => (this.isLoadingGroups = false))

    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(false)
  }
}
