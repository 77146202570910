export default {
  lang: {
    en: "英語",
    ja: "日本語",
    other: "その他",
    choose_your_language: "言語を選択してください",
  },
  search: {
    title: "検索条件",
    target_group: "対象グループ",
    keyword_search: "検索キーワード",
    target_search: {
      label: "検索対象",
      topic: "掲示板",
      calendar: "マイカレンダー",
      chat: "チャット",
      shared_folder: "共有フォルダ"
    },
    target_data: {
      label: "対象データ（複数選択可能）",
      title: "タイトル",
      content: "本文",
      comment: "コメント",
      uploader: "投稿者",
      sen_to: "宛先",
    },
    time_search_range: {
      label: "検索期間",
      one_week: "１週間以内",
      one_month: "１ヶ月以内",
      three_month: "３か月以内",
      six_month: "６ヶ月以内",
      one_year: "１年以内",
      select_ranger: '期間指定'
    },
  },
  groups: {
    deleted_comment: "コメントは削除されました",
    common_topic: "共通トピック",
    group: "グループ",
    qr_scan: "グループへの参加QRコードを読み込む",
    un_read_count: "未読{count}件",
    bin: "ゴミ箱",
    attach_file: "添付ファイル",
    draft: "配信前",
    edit_mode: "編集モード",
    mode_text: "テキスト",
    mode_html: "書式編集",
    comment: "コメント",
    unclassified: "未分類",
    delete: "削除",
    all: "すべて",
    preview_remind: "アカウントを持っている方は ログインしてから、QRを 読み込んでください。",
    nav_button: {
      be_member: "メンバーになる",
      comment: "コメントする",
      reply: "返信する",
      reply_to: "に返信する",
      move_to_bin: "ゴミ箱に入れる",
      move_to_trash: "ゴミ箱に移動",
      free_up_bin: "ゴミ箱を空にする",
      like: "いいね！",
      file_overwrite: "ファイル上書き",
      update_information: "情報の変更",
      download: "ダウンロード",
      set_post: "配信設定する",
      create_topic: "トピック作成",
      summary_display: "まとめ表示",
      move_folder: "フォルダに移動",
      add_folder: "フォルダ追加",
      add_file: "ファイル追加",
      update_folder_name: "フォルダ名変更",
      change_display_order: "表示順変更",
      return_unclassified: "未分類にもどす",
    },
    register: {
      welcome: "ようこそ！SMILE LINEへ！",
      remind: "以下の項目を入力し、送信ボタンを押してください。",
      mail_no_reply: '迷惑メール対策をしている場合は、\n"no-reply@smile-line.net"のメールを受信できるよう設定変更してください。',
      remind_2: "パスワードは半角英数字8文字以上、大文字・小文字・数字・記号のうち２種類以上を使用してください",
      remind_qr: "QRコードでの登録は１回のみです。",
      remind_qr_2: "受信できなかった場合は、迷惑メール設定を確認の上、\nグループ管理者にメールアドレス招待をしてもらってください。",
      remind_admin_register: "社員番号で登録したユーザーは、パスワードの変更ができないです。忘れないようにご注意ください。\n万一、パスワードを忘れた場合は、システム管理者にご連絡ください。",
    },
    search_result: {
      title: "「<span class='text-danger'>{keyWords}</span>」での検索結果",
      result_count: "{count}　件がヒットしました。",
      re_search: "再検索"
    },
    top_page: {
      new_feed: "掲示板",
      to_member: "メンバーへ",
      sort_by_new: "新着順に表示",
      sort_by_cls: "分類ごとに表示",
      un_read: "未読",
      update_info: "更新情報",
      shared_folder: "共有フォルダ"
    },
    new_feed: {
      title: "掲示板",
      create_topic_title: "掲示板  - トピック作成",
      edit_topic_title: "掲示板 - トピック変更",
      move_to_folder_msg: "選択したファイルの移動先フォルダを選択してください。",
      topic_title: "掲示板  - {title}",
      modal_title: "添付しないファイルがあればチェックを外してください。",
      list_liker: "ユーザー一覧",
      emergency_contact: "メール通知",
      is_multiple_edit: "共同編集",
      emergency_send_mail: '緊急メール通知',
      not_send_mail: 'メール通知しない',
      periodic_send_mail: '定期メール通知',
      like: "いいね",
      hide_liked_member: "いいねメンバー名を非表示にする",
      able_like: "いいねができるようにする",
      change_like_char: "いいねの文字変更",
      allow_comment: "コメントができるようにする。",
      allow_anonymous_comment: "匿名でコメントできるようにする",
      post_date_time: "配信日時",
      instant_post: "即時配信",
      select_site: "宛先選択",
      select_group: 'グループ選択',
      multi_group_tag: '複数のグループタグ',
      hide_to_selected_site: "宛先グループを非表示にする",
      like_count: "{count}件",
      subscriber: "登録: {dateTime}",
      updater: "更新: {dateTime}",
      sort_new_to_old: "新しい順に並び替え",
      sort_old_to_new: "古い順に並び替え",
      anonymous: "匿名さん",
      no_attach_file: "該当するファイルがありません。",
      some_attach_file_delete: "削除されたファイルが{count}個あります。",
    },
    shared_folder: {
      title: "共有フォルダ",
      throw_away: "捨てる",
      file_create: "ファイル追加",
      file_detail: "ファイルの詳細",
      file_detail_overwrite: "ファイルの上書き",
      file_detail_overwrite_msg: "ファイルサイズの上限は{max}MBです。更新したファイルは3世代まで復元できます",
      overwrite_error_msg: "最大{max}MBのファイルがアップ可能です。",
      file_detail_update_information: "ファイル情報の変更",
      file_no_title: "未登録時ファイル名",
      file_name: "ファイル名：{fileName}",
      file_size: "ファイルサイズ：{fileSize}",
      save_folder: "格納フォルダ：{folder}",
      store_folder: "格納フォルダ",
      attach_source: "添付元：",
      memo: "メモ",
      history_log_remind: "更新履歴に記録されます。",
      notify_this_update: "この更新を通知する",
      update_history: "変更履歴",
      subscriber: "登録者: {dateTime} {name}",
      updater: "更新者: {dateTime} {name}",
      tb_ver: "Ver.",
      tb_date_time: "日時",
      tb_updater: "更新者",
      tb_file_name: "ファイル名",
      tb_restore: "復元",
      tb_delete: "削除"
    },
    member_list: {
      title: "メンバー名簿",
      name_suffix: "さん",
      role_admin: "管理者",
      role_member: "メンバー",
      role_member_plus: "メンバー＋",
      admin: "（管理者）",
      guest_count: "{count}名",
      memberCount: "メンバー数 : {count} 名",
      member: {
        title: "メンバー",
        joined_member: "参加メンバー",
        leave_group: "グループから退出"
      },
      qr_code: {
        title: "QR・招待",
        message: "既存ユーザーは、ログインしてからスキャンしてください。",
        invite_by_link: "つながりから招待",
        invite_by_mail: "メールで招待",
        title_full: "QRコード・招待",
        link_label: "招待リンク",
        qr_remind: "既存ユーザーは、ログインしてからスキャンしてください。",
        qr_remind_2: "再発行した場合には前のQRコードは無効になります。",
        qr_remind_3: "メールアドレスがない方を招待するには",
        qr_remind_4: "【非推奨】",
        copy_url: "URLコピー",
        re_render_qr: "QR・URL再発行",
        register_new_member: "代わりにユーザー登録",
        grant_invite_auth: "招待権限の付与",
        grant_together: "まとめて付与",
        invite_auth: "招待権限",
        invite_auth_transfer: "権限付与・委譲",
        invite_by_link_remind: "グループに招待するメンバーを入力・選択してください。",
        invite_by_mail_remind: "グループに招待する知人を入力・選択してください。\n複数のメールアドレスを入力するときは、改行して一行1メールアドレスで入力してください。",
        invite_by_mail_no_reply: '迷惑メール対策をしている場合は、"no-reply@smile-line.net"のメールを受信できるよう設定変更してください。',
        invite_confirm_remind: "選択した知人を招待します。有効期限は7日間です。",
        group_join: "参加グループ",
        invite_member: "招待するメンバー",
        member_of_select_group: "選択グループのメンバー",
        member_of_select_group_remind: "グループに招待するメンバーを選択してください。\n複数選択も可能です",
        remind_qr: "QRコードでの登録は１回のみです。",
        remind_qr_2: "受信できなかった場合は、迷惑メール設定を確認の上、\nグループ管理者がメールアドレス招待をしてください。"
      },
      invited: {
        title: "招待中",
        expired: "期限切れ",
        date_invite: "{date}招待",
        cancel: "中止",
        delete: "削除",
        re_invite: "再招待",
        no_invited_member: "招待がありません。"
      },
      join_request: {
        title: "承認待ち",
        date_request: "{date}申請",
        approval: "承認",
        reject: "拒否",
        approval_all: "一括承認",
        no_request_join_member: "新しい参加申請がありません。"
      },
      role: {
        title: "権限",
        title_full: "権限付与・委譲",
        set_admin_role: "管理者に設定する",
        grant_auth: "権限を付与",
        grant_auth_selected_mem: "招待権限を付与する",
      },
      auth: {
        admin: "グループ管理者",
        invite: "招待権限",
        edit_shared_folder: "共有フォルダ編集",
        edit_topic: "掲示板の編集・削除",
      }
    },
    setting: {
      title: "設定",
      basic_info: {
        title: "基本情報",
        modified_date: "作成日",
        group_tags: "グループタグ",
        group_storage: "グループの保存容量",
        move_all_to_bin: "全てのゴミ箱を空にする",
        notice: "メンバーへのお知らせ",
        quotes: "グループ情報の変更は、参加メンバーに通知されません。",
        group_name: "グループ名",
        group_icon: "グループアイコン",
        up_file: "ファイルをアップする",
        select_default: "標準アイコンから選択する",
        delete_icon: "グループアイコンを削除する",
        group_cover: "グループヘッダ画像",
        text_color: "文字色",
        auto_clear_bin: "ゴミ箱自動削除",
        destination: "宛先"
      },
      equipment: {
        title: "設備設定",
        equip_register: "設備登録",
        edit: "編集",
        delete: "削除",
        remind: "  グループ情報の変更は、参加メンバーに通知されません",
      }
    }
  },
  chat: {
    comma: '、',
    chat_group_name_with: '他{other_user_count}名',
    chat_with: 'とのチャット',
    scroll_to_bottom: '新着コメント',
    list: {
      title: "チャット",
      chat_title: "チャットタイトル",
      chat_title_2: "タイトル欄",
      placeholder_input_chat: "メッセージを入力",
      icon_like: "いいね！",
      icon_favorite: "お気に入り",
      reply: "返信",
      gallery: "ギャラリー",
      dropdown_btn: {
        edit: "編集",
        reply: "返信",
        copy: "リンクをコピーする",
        delete: "削除"
      },
      msg: {
        no_group_chat: "グループチャットがありません。",
        no_message: "メッセージがありません。",
        msg_deleted: "（メッセージが削除されました。）",
        message_deleted: "メッセージが削除されました。",
        file_sent: 'ファイルが送信されました。',
        msg_special: "新しいメッセージを送る",
        index: "No.",
        unread_msg: "未読メッセージ",
        message_link: "固定リンク",
        leave_group_chat: "{name}さんがグループチャットから退出しました。",
        leave_system: "{name}さんがシステムから退会しました。",
        users_not_in_general_group: "誰と誰はグループが違うためグループチャットはできません",
      },
      detail: {
        chat_with: "名とのチャッ",
        other_people: "他"
      }
    },
    text_editor: {
      title: "テキスト編集"
    },
    manage_member: {
      placeholder_input_search: "メンバー名で検索",
      no_user_found: "該当するユーザーがいません。",
    },
    gallery: {
      title: "ギャラリー",
      dropdown_btn: {
        download: "ダウンロード",
        delete: "削除"
      }
    }
  },
  calendar: {
    display: {
      title: "マイカレンダー",
      btn_add_appointment: "予定を登録する",
      no_event_to_display: "表示するイベントはありません",
      allday_content: "終日",
      tab: {
        list_display: "リスト",
        day_display: "日",
        week_display: "週",
        month_display: "月"
      },
      dropdown: {
        all: "すべて",
        my_calendar: "マイカレンダー"
      },
      pre_week: "＜＜先週",
      pre_day: "＜前日",
      next_day: "翌日＞",
      now_day: "本日",
      next_week: "翌週＞＞",
      now_week: "今週",
      next_month: "翌月＞＞",
      pre_month: "＜＜先月",
      all_calendar: "全て",
      my_calendar: "マイカレンダー",
      schedule: 'スケジュール'
    },
    create: {
      error_date_msg: "正しい期間を指定してください。",
      error_end_deadline: "締め切り日は現在の日付よりも大きくする必要があります",
      subject: "予定を登録・変更する",
      title: "タイトル",
      note: "メモ",
      color: "予定の色",
      datetime_start: "開始日時",
      date_start: "開始日",
      time_start: "開始時間",
      datetime_end: "終了日時",
      date_end: "終了日",
      time_end: "終了時間",
      hour: "（時）",
      minute: "（分）",
      setting_adv: "詳細設定",
      scheduled_normal: "通常予定",
      calendar: "終日・期間予定",
      event_not_exist: "このカレンダーは存在しません。",
      cannot_find_member_msg: "該当するメンバーがいません。",
      cannot_find_device_msg: "該当する設備がありません。",
      scheduled_repeat: "繰り返し予定",
      setting_member_share: "共有者選択",
      setting_device: "設備設定",
      share_with_member_with_fix_tag: "指定したグループのメンバーに共有",
      choose_member: "メンバーを選択して共有",
      btn_add_group_tag: "更に追加する",
      placeholder_choose_group_tag: "グループを選択",
      placeholder_choose_member: "メンバー名で検索",
      placeholder_choose_setting_device: "グループ名で検索",
      condition_repeat: {
        title: "繰り返し条件",
        every_day: "毎日",
        every_day_without_weekend: "毎日（土日を除く）",
        every_week_day: "毎週",
        every_month_day: "毎月"
      },
      deadline: "期限",
      indefinite: "期限なし",
      util: "まで",
      day_of_week: {
        monday: "月曜日",
        tuesday: "火曜日",
        wednesday: "水曜日",
        thursday: "木曜日",
        friday: "金曜日",
        saturday: "土曜日",
        sunday: "日曜日"
      },
      day: "日",
      accept_member_change_plan: "登録者以外に予定の変更を許可する",
      device_retweet: "こちらの設備は設定できません。別のスケジュールで指定されているようです。"
    },
    status: {
      register: '登録',
      update: '変更',
      delete: '削除'
    },
    preview: {
      edit: "編集",
      delete: "削除",
      repeat_condiction: "繰り返し条件を記述。",
      plan_to_repeat: "繰り返し予定の場合",
      sharer: "共有者",
      setting_device: "設備",
      regular_event: "マイカレンダー 詳細 - 通常予定",
      period_event: "マイカレンダー 詳細 - 終日・期間予定",
      repeat_event: "マイカレンダー 詳細 - 繰り返し予定",
      repeat_detete_today: "この予定",
      repeat_detete_future: "これ以降のすべての予定",
      repeat_detete_all: "すべての予定",
      deadline: "期限",
      condiction: "繰り返し条件",
      repeat_detail: "繰り返し予定の詳細表示",
    }
  },
  common: {
    suffix: {
      san: "さん",
      other: '他',
      name: '名',
      case: '件',
      anonymous: "匿名さん",
      comma: "、",
      leave_group: "（退会）",
      leave_system: "（サービス退会者）",
      system: "システム",
    },
    btn: {
      btn_create_new: "新規作成",
      btn_create: "作成する",
      btn_register: "登録",
      btn_update: "変更する",
      btn_edit: "編集する",
      btn_delete: "削除する",
      btn_cancel: "キャンセルする",
      btn_back: "もどる",
      btn_see_more: "もっとみる",
      btn_login: "ログイン",
      btn_line_login: "LINEアカウントでログイン",
      btn_send_mail: "送信",
      btn_send_to_email: "メールを送信する",
      btn_back_login: "ログイン画面へ戻る",
      btn_to_login: "ログイン画面へ",
      btn_back_home: "ホーム画面へ戻る",
      btn_smile_line: "SMILE LINEへ",
      btn_next: "次へ",
      btn_move: "移動する",
      btn_add: "追加する",
      btn_choose_file: "ファイルを選択",
      btn_mark_as_read: "既読にする",
      btn_send: "送信",
      btn_confirm: "招待する",
      btn_move_to_folder: "フォルダに移動",
      btn_add_folder: "フォルダ追加",
      btn_change_order: "表示順変更",
      btn_overwrite: "上書きする",
      btn_search: "検索",
      btn_yes: "はい",
      btn_no: "いいえ",
      btn_withdraw: "退会する",
      btn_manage_member: "メンバー管理",
      btn_filter: "絞り込む",
      btn_add_member_group_chat: "新規チャット作成",
      btn_delete_member: "退出",
      btn_chat: "チャット",
      btn_favorite: "お気に入り",
      btn_not_favorite: "お気に入りを解除",
      btn_registers: "登録する",
      btn_invite_member: "メンバー編集",
      btn_change: "変更",
      btn_choose: "選択する",
      btn_leave: "退出する",
      btn_download: "ダウンロード",
      btn_delivery: "予約配信",
      btn_ok: "OK"
    },
    message: {
      copied: "コピーしました。",
      deleted: "削除しました。",
      updated: "変更しました。",
      registered: "登録しました。",
      created: "作成しました。",
      invited: "招待しました。",
      del_stoped: "削除・中止しました。",
      cleaned: "空にしました。",
      moved_to_bin: "ゴミ箱に入れました。",
      deleted_attach_file: "{from}で添付ファイル削除しました。",
      update_fail: "変更できませんでした。再度お試しいただいても失敗する場合は管理者にお問い合わせください。",
      register_fail: "登録できませんでした。再度お試しいただいても失敗する場合は管理者にお問い合わせください。",
      create_fail: "作成できませんでした。再度お試しいただいても失敗する場合は管理者にお問い合わせください。",
      invite_fail: "招待できませんでした。再度お試しいただいても失敗する場合はシステム管理者にお問い合わせください。",
      delete_fail: "削除できませんでした。システム管理者にお問い合わせください。",
      delete_fail_attach_file: "{from}で添付ファイル削除できませんでした。システム管理者にお問い合わせください。",
      del_stop_fail: "削除・中止できませんでした。システム管理者にお問い合わせください。",
      clean_fail: "空にできませんでした。管理者にお問い合わせください。",
      move_to_bin_fail: "ゴミ箱に入れできませんでした。システム管理者にお問い合わせください。",
      unsave_edit: "編集している内容は保存されません。このページを離れますか？",
      unsave_delete: "編集している内容は保存されません。このページを離れますか？",
      leave_group: "グループから退出しますか？",
      del_stop_invite: "削除・中止しますか？",
      clean_bin: "ゴミ箱を空にしますか？",
      move_to_bin: "ゴミ箱に入れますか？",
      delete: "削除しますか？",
      delete_all: "全てを削除しますか？",
      cant_leave_one_mem: "メンバーが1人の場合退出できません。管理者にお問い合わせください",
      set_admin: "グループ管理者が不在となるので、メンバーから指名してください。",
      choose_file: "ファイルを選択してください。",
      mail_empty: "メールアドレスを入力してください。",
      mail_wrong: "メールアドレスは有効なメールアドレスではありません。",
      mail_oversize: "メールアドレス入力文字数は最大半角100文字まで。",
      cant_find_user: "ユーザーが見つかりません。",
      mail_duplicate: "メールアドレスが重複です。",
      mail_exist: "招待済みメールアドレスか、すでにグループ登録済メンバーのようです。メールアドレスをご確認ください。",
      already_in_group: "すでにグループに参加しています。",
      sent_request_join: "参加申請に成功しました。申請承認までお待ちください。",
      unread_post: "未読の投稿が{count}件あります。",
      something_went_wrong: "何かがうまくいかなかった。",
      error_type_and_size: "最大25MBのjpeg,png,gifのファイルを選択してください。",
      error_dimension_file: "幅500px以上、高さ100px以上のサイズのファイルを選択してください。",
      reset_password_expired: "パスワードのリセットページの有効期限が切れました。 もう一度やり直してください。",
      error_confirm_password: "パスワードが一致しません。再度お試しください。",
      CURRENT_PASSWORD_AND_NEW_PASSWORD_CAN_NOT_SAME: "新しいパスワードと現在のパスワードを同じにすることはできません。",
      CURRENT_PASSWORD_NOT_MATCH: "現在のパスワードが正しくありません。",
      password_confirm_not_match: "パスワードの確認がパスワードと一致しません。",
      check_email: "参加申請を承りました。メールを確認してください。",
      not_allowed_error: "カメラへのアクセスを求めています。",
      not_found_error: "このデバイスでカメラが見つかりません。",
      not_supported_error: "このサイトはカメラの使用をサポートしていません。",
      not_readable_error: "カメラは別のところで使用されています。",
      overconstrained_error: "カメラは適切ではありません。",
      stream_api_not_supported_error: "Stream APIはこのブラウザをサポートしていません",
      no_data: "データがありません。",
      no_user: "該当するユーザーがいません。",
      no_unread: "未読はありません。",
      no_like: "いいねはありません。",
      no_favorite: "お気に入りはありません。",
      group_tag_duplicate: "グループが重複しています。",
      total_group_chat_unread: "未読の投稿が{totalGroupChat}件あります。",
      error_birthday_msg: "正しい生年月日を入力してください。",
      mail_already_used: "このメールアドレスは既に使用されています。",
      eid_already_used: "登録済社員番号のようです。ご確認ください。",
      user_already_actived: "メールアドレスが既に存在します。QRコード読み取り及びリンク入力の前に、ログインしてください。",
      not_have_email_to_login: "あなたのLineアカウントはメールが登録されていません。",
      not_found_email_in_system: "メールアドレスがシステムに存在しません。管理者に連絡してください。",
      wait_for_approval: "参加申請済です。管理者に承認されるとグループの情報を閲覧可能です。管理者に承認されるとメールが届きます。",
      invalid_member_in_invite_list: "招待一覧に無効なユーザーが含まれています。再度お試しいただいても失敗する場合はシステム管理者にお問い合わせください。",
      update_from_invite_link_fail: "ユーザーのステータスが無効なため、情報を登録できません。管理者に連絡してください。",
      invalid_member_status_invite: "ユーザーのステータスが無効なため、会員を招待できません。管理者に連絡してください。",
      group_not_found_or_deleted: "このグループが削除または非アクティブ化されました。\nしばらくしてからもう一度やり直してください。",
      expired_token: "リンクは無効です。管理者に連絡してください。",
      over_500_mb: "ダウンロードするファイルの合計サイズが500MBを超えています。数回に分けてダウンロードしてください。",
      topic_lock: "{name}さんが編集中です",
      topic_edit_sesson_end: "セッションが切れました。{name}さんが編集中です。",
      topic_edited_by_other: "他のユーザーが編集中です。後でもう一度試してください。",
      topic_not_exist: "このトピックは存在しません。",
      comment_not_exist: "このコメントは存在しません。",
      folder_not_exist: "フォルダが存在しません。",
      file_not_exist: "ファイルが存在しません。",
      file_history_not_exist: "履歴が存在しません。",
      calendar_not_exist: "スケジュールが存在しません。",
      group_over_size: "グループ使用容量はグループの最大容量超えることができません。",
      upload_over_size: "アップロードする容量は{max}MB以下でなければなりません。",
      over_30_file: "最大30ファイルがアップ可能です。",
      not_found_404: "404ページが見つかりません。",
      group_qr_expried: "こちらのQRコード・URLは、現在使用できません。\nグループ管理者へお問い合わせください。",
      update_permission_denied: "このカレンダーイベントを変更する権限がありません。",
      delete_permission_denied: "このカレンダーイベントを削除する権限がありません。",
      access_permission_denied: "このカレンダーイベントにアクセスする権限がありません。",
      uncatch_error: "エラーがあります。管理者に連絡してください。",
      error_user_max_chat_size: "の保存容量の空きが無くなりました。必要のないファイルを削除してください。",
      group_chat_not_found: "このグループチャットが見つかりません。",
      can_not_access_group_chat: "このグループチャットにアクセスする権限がありません。",
      confirm_leave_chat: "退出しますか?",
      leave_chat_success: "退出しました。",
      topic_no_content: "本文テキストなしのトピックです。",
      topic_comment_no_content: "コメントテキストなしの返事です。",
      chat_no_content: "テキストなしの返信です。",
      file_no_content: "メモテキストなしのファイルです。",
      member_not_in_group: "メンバーはグループに含まれていません。",
      no_comment_parent: "リンクは無効",
      upload_image_only: "jpeg,png,gifのファイルがアップ可能です。",
      change_mail_success: "メールアドレスの変更に成功しました。\n 新しいメールアドレスでログインしてください。",
      change_mail_fail: "メールアドレスの変更に失敗しました。",
      group_chat_change_group: "違うグループに参加しているユーザーを含めたグループチャットはできません。\n違うグループを選ぶと選択したユーザーは削除されます。",
      attach_folder_dont_move: "「添付ファイル」のファイルを他のフォルダに移動できません。",
      company_storage_exceed: "企業使用容量はグループの最大容量超えることができません。",
      message_not_found: 'メッセージは存在しません。'
    },
    dropdown_sort: {
      sort_date_time: "更新日時",
      sort_user_name: "ユーザー 名",
      sort_title_name: "タイトル名",
      desc: "降順",
      asc: "昇順"
    },
    form: {
      require: "（必須）",
      require_one: "（一つ必須）",
      title: "タイトル",
      folder: "フォルダ",
      folder_name: "フォルダ名",
      employee_id: "社員番号",
      mail_address: "メールアドレス",
      password: "パスワード",
      temp_password: "仮パスワード",
      password_confirm: "パスワード確認",
      full_name: "氏名",
      first_name: "姓",
      last_name: "名",
      document: "本文",
      subject: "件名",
      full_name_alphabet: "氏名ふりがな",
      first_name_alphabet: "せい",
      last_name_alphabet: "めい",
      please_select: "選択してください",
      file_modified: "最大25MBのjpeg,png,gifのファイルがアップ可能です。",
      file_modified_all: "1つの最大ファイルサイズは{max}MBです。最大30ファイルです。",
      img_modified_with_max: "最大{max}MBのjpeg,png,gifのファイルがアップ可能です。",
      file_modified_with_max: "最大{max}MBのファイルがアップ可能です",
      file_recommend: "幅500px以上、高さ100px以上のサイズのファイルを選択して下さい。（推奨サイズ：幅000px、高さ000px）",
      not_selected: "選択されていません",
      multi_selected: "{count}ファイルを選択しました",
      needed_item: '{name}は必須項目です。',
      max_length: "{name}入力文字数は最大半角{length}文字まで。",
      max_length_2: "{name}に入力できる文字数は{length}文字までです。",
      no_script_tag: "{name}は無効な文字が含まれています。",
      some_file_over_size: "選択したファイルには{max}MBを超えるファイルがあります。\nそのファイルはリストから削除されます。",
      no_emoji: "{name}は絵文字を使用しないでください。",

    },
    title: {
      common_topic: "共通トピック",
      create_common_topic: "共通トピック作成",
      list_participating_group: "参加グループ一覧"
    },
    menu: {
      home: "HOME",
      group: "グループ",
      calendar: "マイカレンダー",
      chat: "チャット",
      setting: "設定",
      help_center: "ヘルプセンター",
      terms_of_use: "利用規約",
      privacy_policy: "プライバシーポリシー",
      logout: "ログアウト"
    },
    date_pick: {
      next_month: "翌月",
      prev_month: "前月",
      set_time: "時間",
      mon: "月",
      tue: "火",
      wed: "水",
      thu: "木",
      fri: "金",
      sat: "土",
      sun: "日",
      day: "日",
      january: "1月",
      february: "2月",
      march: "3月",
      april: "4月",
      may: "5月",
      june: "6月",
      july: "7月",
      august: "8月",
      september: "9月",
      october: "10月",
      november: "11月",
      december: "12月",
    },
    date_format: {
      dd_mm_yyyy: 'DD日MM月YYYY年',
      yyyy_mm_dd: 'YYYY年MM月DD日',
      yyyy_mm: 'YYYY年MM月',
      mm_dd: 'MM月DD日',
      mm_dd_compact: 'MM/DD'
    },
    select: {
      private: "非公開",
      public: "公開",
      notify: "通知",
      no_notify: "非通知",
      not_set: "未設定"
    }
  },
  auth: {
    login: {
      company_id: "企業ID",
      user_id: "ログインID",
      user_password: "パスワード",
      login_with_email: "メールアドレスでログイン",
      failed_login_msg: "ログインに失敗しました。 もう一度やり直してください",
      invalid_user_status: "アカウント情報が確認できません。ログインIDとパスワードを正しく入力してください。",
      login_authorized_email: "メールアドレスとパスワードが一致しません。再度お試しください。",
      login_authorized_employee_id: "企業IDとログインIDとパスワードが一致しません。再度お試しください。",
      login_with_employeeID: "社員番号でログイン",
      password_message: "パスワードは半角英数字8文字以上・大文字・小文字・数字・記号のうち2種類以上を使用してください。",
      forgot_password: "パスワードお忘れですか？",
      notify_title: "お知らせ",
      recommend_browser: "推奨ブラウザ: Google chrome, Edge Chronium版",
      user_has_been_locked: "10回パスワード入力を確認できなかった為アカウントロックがかかりました。<br/><a href='/forgot-password' target='_self' class='text-primary'>こちら</a>よりパスワード再設定を行ってください。",
      user_must_reset_password: "システムにログインするにはパスワードをリセットしてください。",
      exceed_api_limit: '現在アクセスが集中している為繋がりにくくなっています。少し時間をおいて再度アクセスしてみて下さい。'
    },
    forgot_password: {
      email: "メールアドレス",
      error_email_msg: "入力されたメールアドレスは登録されていません。正しく入力してください。",
      message: "ログインIDとなるメールアドレスに対し、<br/> パスワード再設定用のURLを送ります。<br/> メールアドレスを入力し、送信ボタンを押してください。",
      send_to_mail: "入力されたメールアドレスにパスワード再設定URLメールをお送りしました。ので確認してください。"
    },
    reset_password: {
      screen_title: "パスワード再設定画面",
      message: "パスワードを入力し、送信ボタンを押してください。<br/> 半角英数字8文字以上、大文字・小文字・数字・記号のうち２種類以上を使用してください。",
      new_password: "新しいパスワード",
      confirm_new_password: "新しいパスワード（確認)",
      confirm_new_password_placeholer: "パスワード（確認）",
    },
    completed_reset_password: {
      message: "パスワードを再設定いたしました。<br/> ログイン画面にもどり、ログインしてください。"
    },
    employee_forgot_password: {
      message: "グループ管理者へパスワード変更を依頼して、<br/> 教えてもらってください。<br/> 再ログイン後、パスワード変更を行ってください。",
    },
    employee_forgot_password_line: {
      message: "LINEアカウントでログインしました。",
    },
    employee_without_group: {
      only_for_employee: 'SMILE LINEは株式会社チェッカーサポートの従業員専用システムです。',
      not_allow_login: "グループに所属していないため、ログインできません",
      contact_to_admin: "所属したいグループ管理者にご連絡ください。",
      un_subscribe: "退会する方はこちら",
      failed_un_subcribe_msg: "削除できませんでした。再度お試しいただいても失敗する場合はシステム管理者にお問い合わせください。",
      checker_support_company: "株式会社チェッカーサポート",
      regicum_website: "「レジカム」レジのパート・アルバイト専門求人サイト"
    },
    login_line_fail: "SMILE LINEのご利用には事前にグループ管理者による登録が必要です。\n\nご登録メールアドレスが確認できませんでした。\nグループ管理者へお問い合わせください。"
  },
  setting: {
    help_center: "ヘルプセンター",
    terms_of_use: "利用規約",
    privacy_policy: "プライバシーポリシー",
    log_out: "ログアウト",
    email: "メールアドレス",
    setting_menu: {
      account_info: "アカウント情報変更",
      setting_language: "言語設定",
      display_order_groups: "参加グループの表示順設定",
      setting_display: "表示編集画面",
      setting_notify_news: "新着メール通知設定",
      setting_theme: "テーマデザイン設定",
      change_profile: "プロフィール変更",
    },
    account_info: {
      title: "アカウント情報",
      password: "パスワード",
      btn_change_email: "メールアドレスを変更する",
      btn_change_password: "パスワードを変更する",
    },
    change_email: {
      title: "アカウント情報 メールアドレス変更",
      current_email: "現メールアドレス",
      notify_msg: "上記のメールアドレスから変更したい場合は、変更後のメール アドレスを以下に入力してください。メールが届きましたら本 文にある承認URLをクリックしてください。",
      new_email: "新メールアドレス",
      exist_email: "登録済「{email}」のようです。ご確認ください。",
      send_to_mail: "メールを送信しました。メールのURLをクリックして完了してください。",
      mail_duplicate: "新しいメールアドレスと既存のメールアドレスを同じにすることはできません。",
      change_email_successfully: "変更しました。もう一度ログインしてください。"
    },
    change_password: {
      title: "アカウント情報 パスワード変更",
      message: "パスワードを入力し、変更するボタンを押してください。半角英数字8文字以上、大文字・小文字・数字・記号のうち２種類以上を使用してください。",
      current_password: "現在のパスワード",
      new_password: '新しいパスワード',
      confirm_new_password: "新しいパスワード確認",
    },
    setting_language: {
      title: "言語設定",
      current_language: "現在の言語",
      choose_language: "設定する言語",
    },
    display_order_groups: {
      title: "参加グループの表示順設定",
      non_folder: "フォルダなし"
    },
    setting_display: {
      title: "表示設定",
      number_items_on_list: "一覧で表示する件数",
      number_items_on_comment_list: "コメントの一覧で表示する件数",
      cases: "件",
      display_images_and_videos: "画像・動画の表示",
      display: "本文内にサムネイル表示する",
      no_display: "本文内にサムネイル表示しない",
      first_day_of_week: "週の開始曜日",
      monday: "月曜日",
      tuesday: "火曜日",
      wednesday: "水曜日",
      thursday: "木曜日",
      friday: "金曜日",
      saturday: "土曜日",
      sunday: "日曜日"
    },
    setting_notify_news: {
      title: "新着メール通知",
      general: "共通",
      stop_notify_time: "メール通知停止時間帯",
      regular_email_notify: "定期メール通知",
      notify_email_address: "通知先メールアドレス",
      notify_interval: "通知間隔",
      email_notify_time: "メール通知時間",
      emergency_email_notify: "緊急メール通知",
      schedule_email_notify: "予定メール通知",
      notify: "通知",
      no_notify: "非通知",
      minutes: "分",
      hours: "時間"
    },
    setting_theme: {
      title: "テーマデザイン設定",
      no_theme: "テーマデザインなし",
      theme_custom: "カスタムテーマ",
      upload_theme: "ヘッダ画像をアップする",
      title_background: "タイトル背景色",
      text_color: "文字の色",
      choose_theme: "規定のテーマデザインを選択する"
    },
    change_profile: {
      title: "さんのプロフィール",
      profile_avatar: "プロフィール画像",
      radio_upload_file: "ファイルをアップする",
      radio_delete_image: "画像を削除する",
      last_name: "名",
      first_name: "姓",
      furigana_lastname: "めい",
      furigana_firstname: "せい",
      self_introduction: "自己紹介",
      company_info: "勤務先情報",
      show_company_info: "勤務先情報表示",
      company: "会社/所属",
      department: "部署名",
      position: "役職名",
      street_address: "住所",
      btn_postcode_search: "郵便番号検索",
      prefectures: "都道府県",
      district: "市区町村",
      address: "その他住所",
      building_name: "建物名",
      phone_number: "電話番号",
      add_phone: "＋電話番号追加",
      add_url: "＋URL追加",
      personal_info: "パーソナル情報",
      gender: "性別",
      male: "男性",
      female: "女性",
      birthday: "生年月日",
      nearest_station: "最寄り駅",
      url: "URL"
    }
  },
  with_draw: {
    contact_option_msg: "退会後、再度ご連絡させていただくことがございます。もし連絡しないでほしい場合、以下のチェックボックスにチェックを入れてください。",
    do_not_contact: "連絡しないでほしい。",
    confirm_delete_msg: " チェックボックスにチェックを入れると、退会すると全てのデータが削除され、 SMILELINEを再度利用することができません。 退会しますか?",
    btn_back_without_withdraw: "退会せずSMILE LINEにもどる",
    confirmed_withdraw: " 退会されました。<br/> 今までのご利用ありがとうございました。"
  },
  modal_show_profile: {
    online: "オンライン",
    japanese_name: "ふかだ　きょうこ",
    chinese_name: "深田　恭子",
    non_public_email_message: "(メールアドレス非公開)",
    last_login: "最終ログイン",
    last_logout: "最後のログアウト",
    hours_ago: "{hours}時間前",
    more_than_seven: "7日以上前",
    less_than_seven: "{day}日前",
    not_login: "ログイン時間を判別できません",
    logged_out: "１時間以内",
    start_chat: "チャットを開始する",
    company_info: "勤務先情報",
    personal_info: "バーソナル情報",
    date_of_birth: "生年月日",
    company: "会社名",
    company_name: "株式会社チェッカーサポート",
    department: "部署",
    department_name: "システム部",
    position: "役職",
    street_address: "住所",
    street_address_name: "東京都江東区富岡2-9-11",
    building_name: "京福ビル 4F",
    url: "URL",
    phone: "TEL",
    can_not_withdraw: "退会すると、グループ管理者が居なくなるグループがあります。\n別のメンバーにグループ管理者権限を付与してから退会してください。"
  },
  modal_add_folder: {
    add_folder: "フォルダ追加",
    message_setting: "フォルダ名を入力してください。\nグループフォルダは他のメンバーには表示されません。",
    message_group: "フォルダ名を入力してください。\nグループの共有フォルダは他のメンバーにも同様に表示されます。",
    folder_name: "フォルダ名"
  },
  modal_move_to_folder: {
    move_to_folder: "フォルダ移動",
    message: "選択したグループの移動先フォルダを選択してください。",
    select_file_message: "選択したファイルの移動先フォルダを選択してください。",
    destination_folder: "移動先フォルダ",
  },
  modal_edit_folder: {
    current_folder: "フォルダ編集",
    current_folder_name: "東日本統括部",
    edit_message: "フォルダ名を編集します。",
    new_folder: "フォルダ名変更",
    change_display_order: "フォルダ表示順の変更は",
    here: "こちら",
    delete_message_setting: "削除すると、配下のフォルダにあるグループも含め、フォルダ 分類なしになります（グループ削除はされません）",
    delete_message_not_setting: "削除すると、フォルダ内にあるファイルは未分類になります。"
  },
  modal_change_order: {
    title: "表示順変更",
    message: "順番を変更したいフォルダ名・グループ名を選択し、ボタンを押して順番を変更してください。",
    unsave_edit: "変更した内容は保存されません。もどりますか？"
  },
  modal_multi_select: {
    title_1: "選択グループのメンバー",
    message_1: "グループに招待するメンバーを選択してください。\n複数選択も可能です。",
    title_2: "選択グループ",
    message_2: "グループを選択してください。\n複数選択も可能です。"
  },
  modal_register_equipment: {
    name: "設備名",
    memo: "メモ"
  },
  nav_header: {
    not_seen: "未読:",
    like: "いいね:",
    favorite: "お気に入り:",
    item: "件"
  },
  nav_menu: {
    home: "HOME",
    group: "グループ",
    calendar: "マイカレンダー",
    chat: "チャット",
    setting: "設定"
  },
  home: {
    news: {
      infomation: "連絡事項",
      new: "new"
    },
    arrival_favourite: "お気に入り一覧",
    new_arrival: {
      new_arrival_list: "新着一覧",
      common_topic: "共通トピック",
      updater_name: "更新者名",
    }
  },
  notification: {
    unread: '未読通知一覧',
    favourite: 'お気に入り一覧',
    like: 'いいね一覧',
    all_group: '全てのグループ',
    just_liked: 'にいいね！をつけました。'
  },
  route_title: {
    home: 'Home',
    todo: 'Todo',
    unread_notification: '未読通知',
    like_notification: '通知のように',
    favourite_notification: 'お気に入りの通知',
    help_center: 'Help Center',
    help_center_detail: 'Help Center Detail',
    page_not_found: 'Page Not Found',
    login: "ログインページ",
    forgot_password: "パスワードをお忘れですか",
    reset_passsword: "リセットパスワード",
    employee_without_group: "グループのない従業員",
    group_not_found: "グループは存在しません",
    employee_forgot_password: "忘れた従業員のパスワード",
    employee_forgot_password_line: "忘れた従業員のパスワードLINE",
    line_login: "LINEでログイン",
    account_info: "アカウント情報",
    withdraw: " 退会する",
    change_email: "メールアドレスを変更",
    change_password: 'パスワードを変更する',
    setting_language: "言語設定",
    change_profile: "プロフィール変更",
    setting_display: "表示編集画面",
    setting_notify_news: "新着メール通知設定",
    display_order_groups: '参加グループの表示順設定',
    setting_theme: "テーマデザイン設定",
    search_result: "検索結果",
    group_preview: "グループプレビュー",
    register: "登録",
    group_top: "グループTOP",
    new_feed_top: "掲示板TOP",
    topic_create: "トピック作成",
    topic_update: "トピック編集",
    topic_detail: "トピック詳細",
    shared_folder_top: "共有フォルダTOP",
    file_create: "ファイル登録",
    file_update: "ファイル編集",
    file_detail: "ファイル詳細",
    member_list: "メンバー一覧",
    qr_code_invite: "QRコード招待リンク",
    qr_code_invite_auth: "QRコード招待リンク・招待権限の付与",
    invited: "グループ招待中",
    join_request: "グループ承認待ち",
    group_role: "グループ権限付与・委譲",
    group_infomation: "グループ基本情報",
    group_infomation_edit: "グループ基本情報編集",
    group_equipment: "グループ設備設定",
    calendar: "マイカレンダ",
    create_event: "予定を登録",
    edit_event: "変更する",
    event_preview: "イベントプレビュ",
    token_expired: "リンクは無効",
    chat_list: "チャットリスト",
    chat_detail: "チャットの詳細",
    policy: "プライバシーポリシー",
    terms: "利用規約",
    verify: "確認",
  },
  permission: {
    full: {
      permissions: "グループ管理者",
    },
    shared: {
      folder: "共有フォルダ編集",
    },
    bulletin: {
      board: "掲示板の編集・削除",
    },
    invite: "招待権限"
  },
  information_msg: {
    msg_1: "{group_name}のグループ管理者の権限を付与されました。",
    msg_2: "{group_name}の{permission_name}の権限が付与されました。",
    msg_3: "{group_name}の{permission_name}の権限がはく奪されました。",
    msg_4: "{group_name}から{user_name}さんが退会しました。",
    msg_5: "{group_name}へ{user_name}さんが参加しました。",
    msg_6: "{group_name}から招待されています (e-mail招待されているので確認してください)。",
    msg_7: "{user_name}さんが{group_name}への参加を申請しています。(QRコード招待)",
    msg_8: "{name_tenant}の保存容量の空きが残り少ないです (使用率80パーセント以上)。",
    msg_9: "{name_tenant}の保存容量の空きが無くなりました。必要のないファイルを削除してください。",
    msg_10: "{group_name}の保存容量の空きが残り少ないです (使用率80パーセント以上)。",
    msg_11: "{group_name}の保存容量の空きが無くなりました。必要のないファイルを削除してください。",
    msg_12: "[共通トピック領域の]保存容量の空きが残り少ないです (使用率80パーセント以上)。",
    msg_13: "[共通トピック領域の]保存容量の空きが無くなりました。必要のないファイルを削除してください。",
    msg_14: "チャット領域の空きが残り少ないです (使用率80パーセント以上)。",
    msg_15: "チャット領域の空きが無くなりました。必要のないファイルを削除してください。",
    msg_16: "{group_name}でファイル{file_name}が上書きされました。",
    msg_17: "カレンダーに{calendar_title}が登録されました。",
    msg_18: "カレンダーから{calendar_title}が削除されました。",
    msg_22: "{group_name}でファイル{file_name}が上書きされました。",
  },
  favourite_notification: {
    favourite_type_1: "{topic_title}",
    favourite_type_2: "{file_name}",
    favourite_type_3: "{common_topic_name}",
    favourite_type_4: "{file_name}",
    favourite_type_5: "{calendar_title}",
    favourite_type_6: "{chat_name}",
  },
  like_notification: {
    like_type_1: "{user_name}{like_count}が<span class='text-primary'>「{like_target}」</span>に{like_char}をつけました。",
    like_type_2: "{user_name}{like_count}が<span class='text-primary'>「{comment_content}」</span>に{like_char}をつけました。",
    like_type_3: "{user_name}{like_count}が<span class='text-primary'>「{like_target}」</span>に{like_char}をつけました。",
    like_type_4: "{user_name}{like_count}が<span class='text-primary'>「{comment_content}」</span>に{like_char}をつけました。",
    like_type_5: "{user_name}{like_count}が<span class='text-primary'>「 {comment_content}」</span>にいいね！をつけました。",
    comment: "あなたのコメント",
    like_text: "いいね！",
  },
  table_calendar: {
    last_week: "先週",
    last_day: "前日",
    next_week: "翌週",
    next_day: "翌日",
    today: "当日"
  },
  setting_group: "参加グループの設定",
  nav_menu_bar: {
    help_center: "ヘルプセンター",
    terms_of_service: "利用規約",
    privacy_policy: "プライバシーポリシー",
    switch_to_pc_display: "PC表示に切替",
    switch_to_mobile_display: "Mobile表示に切替",
  }
}
