


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Title extends Vue {
  @Prop() customClass!: string
  private title_background: Object = {}
  private title_color: Object = {}

  @Watch('$store.state.setting.setting.setting_themes.theme.title_background')
  @Watch('$store.state.setting.setting.setting_themes.theme.title_color')
  watchTitleColorChange() {
    this.setTheme()
  }

  created() {
    this.setTheme()
  }

  public setTheme() {
    const bg = this.$store.state.setting.setting.setting_themes.theme
      .title_background

    this.title_background = {
      backgroundColor: bg ? bg : '#fff' + ' !important'
    }

    this.title_color = {
      color:
        this.$store.state.setting.setting.setting_themes.theme.title_color +
        ' !important'
    }
  }
}
