
























import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainContent from '@/components/MainContent.vue'
import News from '@/components/News.vue'
import NavGroupOrder from '@/components/NavGroupOrder.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'
import TableCalendar from '@/components/TableCalendar.vue'

@Component({
  components: {
    Header,
    News,
    NavGroupOrder,
    NavMenuBar,
    Banner,
    NavMenu,
    MainContent,
    TableCalendar,
    Footer
  }
})
export default class LayoutDefault extends Vue {
  private mainBg: any = {}

  @Watch('$store.state.setting.setting.setting_themes.theme.title_background')
  @Watch('$store.state.setting.setting.setting_themes.theme.title_color')
  @Watch('$store.state.setting.setting.setting_themes.theme.nav_menu_bg_img')
  @Watch('$store.state.setting.setting.setting_themes.theme.nav_menu_bg_color')
  watchNavBgColorChange() {
    this.setTheme()
  }

  created() {
    this.setTheme()
  }

  setTheme() {
    const theme = this.$store.state.setting.setting.setting_themes.theme
    this.mainBg = theme.main_bg_color
      ? {
          backgroundColor: theme.main_bg_color + ' !important'
        }
      : {
          backgroundImage: 'url(' + theme.main_bg_img + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }
  }
}
