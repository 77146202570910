import FormatDate from '@/helpers/FormatDate';
import { EFavoriteType } from '.';
import { EUserStatus, keepFirstElementOnly } from '..';
//@ts-ignore
import _ from 'lodash'
import { UserLeaveGroupChat } from '../Chat';
export class Favorite {
  id!: number
  group_id!: number
  favorite_target_type!: EFavoriteType;
  is_belong_to_group!: boolean
  is_my_calendar!: boolean
  is_topic_comment!: boolean // is this update is topic updatate or topic comment
  topic_comment_id!: number //for topic
  group_avatar: string[] = []   //group icon or group avatar of chat
  title: string = ''
  content: string = ''
  is_read!: boolean
  name: string = '' //file name or chatbox name
  only_user_chat: boolean = false // for chat
  path: string = '' //file path
  user_name: string = '' ///updater username
  avatar: string = '' //updater avatar
  updated_date: string = ''
  allow_anonymous_comment: boolean = false
  emergency_contact: boolean = false //is favorite topic is emergency contact
  event_start_date: string = ''//for schedule
  event_end_date: string = ''//for schedule
  user_active_status: EUserStatus = EUserStatus.NORMAL
  user_leave_chat!: UserLeaveGroupChat //for chat

  constructor(init?: Partial<Favorite>) {
    Object.assign(this, init)

    if (this.favorite_target_type === EFavoriteType.COMMON_TOPIC && this.is_belong_to_group) {
      this.favorite_target_type = EFavoriteType.GROUP_TOPIC
    }

    if (this.favorite_target_type === EFavoriteType.COMMON_FILE && this.is_belong_to_group) {
      this.favorite_target_type = EFavoriteType.GROUP_FILE
    }

    if (this.favorite_target_type === EFavoriteType.CALENDAR) {
      this.content = `${FormatDate.formatEnWitdJaDay(this.event_start_date)} ～ ${FormatDate.formatEnWitdJaDay(this.event_end_date)}`
    }

    if (this.content) {
      this.content = '<div><span>' + this.content + '</span></div>'
      let doc = new DOMParser().parseFromString(this.content, 'text/html')
      let wrapper: any = doc.querySelector('div')
      let el: any = wrapper.firstChild
      if (el) {
        el.innerText = _.truncate(el.innerText, {
          'length': 50,
          'separator': ' '
        });
        el?.classList ? el?.classList.add("text-line-clamp-1") : ''
        this.content = keepFirstElementOnly(wrapper, el, wrapper.childElementCount > 1)
      }
    }
  }
}

