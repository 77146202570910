












import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'

@Component({
  components: {
    Header,
    Banner,
    NavMenu,
    Footer
  }
})
export default class LayoutDefault extends Vue {}
