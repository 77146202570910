






















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainContent from '@/components/MainContent.vue'
import News from '@/components/News.vue'
import CommonTopic from '@/components/NavGroupOrder.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'
import TableCalendar from '@/components/TableCalendar.vue'

@Component({
  components: {
    Header,
    News,
    CommonTopic,
    NavMenuBar,
    Banner,
    NavMenu,
    MainContent,
    TableCalendar,
    Footer
  }
})
export default class LayoutNotification extends Vue {}
