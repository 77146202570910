
























































import { Component, Vue, Watch } from 'vue-property-decorator'
import HeaderCompact from '@/components/HeaderCompact.vue'
import { ITabModel } from '@/models'

@Component({ components: { HeaderCompact } })
export default class CommonTopicHeader extends Vue {
  private tabs: ITabModel[] = []
  private banner_header_background: Object = {}

  @Watch(
    '$store.state.setting.setting.setting_themes.theme.banner_header_bg_color'
  )
  watchBannerColorChange() {
    this.setTheme()
  }

  @Watch(
    '$store.state.setting.setting.setting_themes.theme.banner_header_bg_img'
  )
  watchBannerImgChange() {
    this.setTheme()
  }

  created() {
    this.initTabs()
    this.setTheme()
  }

  initTabs() {
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t('groups.new_feed.title')),
        routerName: 'common-topic-new-feed',
        routerLink: '/common-topic/topics'
      },
      {
        id: 1,
        displayName: String(this.$t('groups.shared_folder.title')),
        routerName: 'common-topic-shared-folder',
        routerLink: '/common-topic/shared-folder'
      }
    ]
  }

  setTheme() {
    this.banner_header_background =
      this.$store.state.setting.setting.setting_themes.theme
        .banner_header_bg_color !== null
        ? {
            backgroundColor:
              this.$store.state.setting.setting.setting_themes.theme
                .banner_header_bg_color + ' !important'
          }
        : {
            backgroundImage:
              'url(' +
              this.$store.state.setting.setting.setting_themes.theme
                .banner_header_bg_img +
              ')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }
  }
}
