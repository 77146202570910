import { getModule } from 'vuex-module-decorators'
import store from '@/store'
import UserInfo from '@/store/modules/UserInfo'
import GroupMemberService from '@/services/GroupMemberService'
import { EGroupAuth } from '@/models'
import i18n from '@/lang'
import CommonTopicService from '@/services/CommonTopicService'

const UserModule = getModule(UserInfo, store)

const updateGroupUserRole = async (groupId?: number) => {
  if (groupId) {
    return GroupMemberService.getLoginUserRole(groupId)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.map((item: any) => getRoleEnum(item.name))
          UserModule.UPDATE_USER_GROUP_ROLE({
            group_id: groupId,
            role_admin: data.includes(EGroupAuth.ADMIN),
            role_invite:
              data.includes(EGroupAuth.INVITE) ||
              data.includes(EGroupAuth.ADMIN),
            role_edit_shared_folder:
              data.includes(EGroupAuth.EDIT_SHARED_FOLDER) ||
              data.includes(EGroupAuth.ADMIN),
            role_edit_topic:
              data.includes(EGroupAuth.EDIT_TOPIC) ||
              data.includes(EGroupAuth.ADMIN)
          })
        }
      })
      .catch(() => {
        UserModule.UPDATE_USER_GROUP_ROLE({
          group_id: groupId,
          role_admin: false,
          role_invite: false,
          role_edit_shared_folder: false,
          role_edit_topic: false
        })
      })
  } else {
    return setToDefaultMember()
  }
}

export const updateCommonTopicUserRole = async () => {
  UserModule.UPDATE_USER_COMMON_TOPIC_ROLE(false)
  // CommonTopicService.checkRoleCreateCommonTopic().then(res => {
  //   if (res.status === 200) {
  //     UserModule.UPDATE_USER_COMMON_TOPIC_ROLE(res.data)
  //   } else {
  //     UserModule.UPDATE_USER_COMMON_TOPIC_ROLE(false)
  //   }
  // }).catch(() => UserModule.UPDATE_USER_COMMON_TOPIC_ROLE(false))
}

const setToDefaultMember = () => {
  UserModule.UPDATE_USER_GROUP_ROLE({
    group_id: 0,
    role_admin: false,
    role_invite: false,
    role_edit_shared_folder: false,
    role_edit_topic: false
  })
}

export const updateRoleInvite = (roleInvite: boolean) => {
  UserModule.UPDATE_USER_GROUP_ROLE_INVITE(roleInvite)
}

export const getRoleMutilangs = (name: string) => {
  switch (name) {
    case 'full.permissions':
      return i18n.t('groups.member_list.auth.admin') as string
    case 'invite':
      return i18n.t('groups.member_list.auth.invite') as string
    case 'shared.folder':
      return i18n.t('groups.member_list.auth.edit_shared_folder') as string
    case 'bulletin.board':
      return i18n.t('groups.member_list.auth.edit_topic') as string
    default:
      return
  }
}

export const getRoleEnum = (name: string) => {
  switch (name) {
    case 'full.permissions':
      return EGroupAuth.ADMIN
    case 'invite':
      return EGroupAuth.INVITE
    case 'shared.folder':
      return EGroupAuth.EDIT_SHARED_FOLDER
    case 'bulletin.board':
      return EGroupAuth.EDIT_TOPIC
    default:
      return
  }
}

export default updateGroupUserRole
