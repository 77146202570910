















import { EThemeType } from '@/models/Setting/Enum'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  private title_background: Object = {}
  private title_color: Object = {}

  @Watch('$store.state.setting.setting.setting_themes.theme.title_background')
  @Watch('$store.state.setting.setting.setting_themes.theme.title_color')
  watchTitleColorChange() {
    this.setTheme()
  }

  created() {
    this.setTheme()
  }

  public setTheme() {
    const themeType = this.$store.state.setting.setting.setting_themes
      .theme_type
    if (themeType !== EThemeType.NO_THEME) {
      this.title_background = {
        backgroundColor:
          this.$store.state.setting.setting.setting_themes.theme
            .title_background + ' !important'
      }

      this.title_color = {
        color:
          this.$store.state.setting.setting.setting_themes.theme.title_color +
          ' !important'
      }
    } else {
      this.title_background = {}
      this.title_color = {}
    }
  }
}
