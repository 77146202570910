





































import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainContent from '@/components/MainContent.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'
import NavMemberGroupChatRecent from '@/components/NavMemberGroupChatRecent.vue'

@Component({
  components: {
    Header,
    NavMenuBar,
    Banner,
    NavMenu,
    MainContent,
    Footer,
    NavMemberGroupChatRecent
  }
})
export default class LayoutChat extends Vue {
  private widthXl: boolean = false

  created() {
    this.checkContainXl()
    window.onresize = () => {
      this.checkContainXl()
    }
  }

  @Watch('$route.name')
  checkContainXl() {
    this.widthXl =
      (window.innerWidth > 1200 && this.$route.name === 'chat-detail') ||
      this.$route.name !== 'chat-detail'
  }
}
