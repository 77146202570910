





























































import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MainContent from '@/components/MainContent.vue'
import NavMenuBar from '@/components/NavMenuBar.vue'
import Banner from '@/components/Banner.vue'
import NavMenu from '@/components/NavMenu.vue'
import { ITabModel } from '@/models'

@Component({
  components: {
    MainContent,
    NavMenuBar,
    NavMenu,
    Banner,
    Header,
    Footer
  }
})
export default class LayoutCalendar extends Vue {
  private tabs: ITabModel[] = []

  created() {
    this.initTab()
  }

  initTab() {
    this.tabs = [
      {
        id: 0,
        displayName: String(this.$t('calendar.display.tab.list_display')),
        routerName: 'calendar-list',
        routerLink: { name: 'calendar-list' }
      },
      {
        id: 1,
        displayName: String(this.$t('calendar.display.tab.day_display')),
        routerName: 'calendar-day',
        routerLink: { name: 'calendar-day' }
      },
      {
        id: 2,
        displayName: String(this.$t('calendar.display.tab.week_display')),
        routerName: 'calendar-week',
        routerLink: { name: 'calendar-week' }
      },
      {
        id: 3,
        displayName: String(this.$t('calendar.display.tab.month_display')),
        routerName: 'calendar-month',
        routerLink: { name: 'calendar-month' }
      }
    ]
  }
}
